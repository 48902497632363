import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import symbol from './contentenhance-new-project.png';

function OrganizationOverview() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [organizations, setOrganizations] = useState([]);
	const [showMobileModal, setShowMobileModal] = useState(false);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const authResponse = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
                if (!authResponse.data.isAuthenticated) {
                    navigate('/login'); // Redirect to login if not authenticated
                } else {
                    // Fetch user organizations
                    const orgResponse = await axios.get('https://api.contentenhance.com/api/user/organizations', { withCredentials: true });
                    if (orgResponse.data && orgResponse.data.organizations) {
                        setOrganizations(orgResponse.data.organizations);
                    }
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                navigate('/login'); // Redirect to login on error
            }
        };

        checkAuthentication();
    }, [navigate]);
	
	useEffect(() => {
	    const handleResize = () => {
	        if (window.innerWidth < 768) { // Example threshold for mobile
	            setShowMobileModal(true);
	        }
	    };

	    handleResize(); // Check on component mount

	    window.addEventListener('resize', handleResize); // Add event listener

	    return () => window.removeEventListener('resize', handleResize); // Cleanup
	}, []);

    return (
        <div className="flex flex-col h-screen bg-darkBlue">
            <TopBar />
            <div className="flex h-screen bg-lighterDarkBlue text-white">
                <Sidebar />
                <div className="flex-1 bg-gray-850 p-10 overflow-auto">
                    <h1 className="text-white text-base mb-8">Workspaces</h1>
<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 h-64 mb-6">
                    
                        <Link to="/projects" className="rounded-lg p-4 flex flex-col bg-gray-900 items-center justify-center hover:shadow-lg transition">
                            <img src={symbol} alt="Workspace summary" className="w-10 h-10 mb-2" />
                            <span className="text-white text-sm">Personal Workspace</span>
                        </Link>
		
		{organizations.map((org) => (
		                            <Link key={org.organization_id} to={`/organization/projects/${org.organization_id}`} className="rounded-lg p-4 flex flex-col bg-gray-900 items-center justify-center hover:shadow-lg transition">
		                                <img src={org.logo_url || symbol} alt={`${org.organization_name} logo`} className="w-10 h-10 mb-2" />
		                                <span className="text-white text-sm">{org.organization_name} Workspace</span>
		                            </Link>
		                        ))}
                    </div>
                </div>
								{showMobileModal && (
								  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1300]">
								    <div className="w-full max-w-md p-4 space-y-2 rounded-lg bg-gray-900 shadow-2xl z-[1300]">
								      <button 
								        onClick={() => setShowMobileModal(false)} 
								        className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800 text-3xl p-2 z-[1300]"
								      >
								        &times;
								      </button>
								      <h2 className="text-center text-gray-200 text-lg z-[1300]">Better on Larger Screens</h2>
								      <p className="text-center text-gray-400 text-sm z-[1300]">
								        The experience is currently designed for larger screens to better display the data. Please switch if you can.
								      </p>
								      <div className="flex justify-center mt-4">
								        <button 
								          onClick={() => setShowMobileModal(false)} 
								          className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 text-sm z-[1300]"
								        >
								          Close
								        </button>
								      </div>
								    </div>
								  </div>
								)}
            </div>
        </div>
    );
}

export default OrganizationOverview;
