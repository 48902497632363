import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import TopBarMarketing from './TopBarMarketing';
import './HomePage.css'; // Ensure this is the correct CSS file
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CameraIcon from '@mui/icons-material/Camera';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Pricing = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [isEnterpriseSubmitted, setIsEnterpriseSubmitted] = useState(false);
  

  const handleCheckout = async (priceId) => {
    processCheckout(priceId);
  };

  const processCheckout = async (priceId, entityId = null, entityType = 'user') => {
    const stripe = await stripePromise;
    try {
      const sessionDetails = { priceId, entityId, entityType };
      const response = await axios.post('https://api.contentenhance.com/create-checkout-session', sessionDetails, {
        withCredentials: true
      });
      const session = response.data;
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });
      if (result.error) {
        console.error('Checkout error:', result.error.message);
      } else {
        console.log('Checkout successful.');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };
  
  // Add a new function to handle the form submission
  const handleContactSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://api.contentenhance.com/api/contact_us', { email });
      console.log('Response:', response.data.message);
      setIsEnterpriseSubmitted(true); // Only set this for the Enterprise plan
    } catch (error) {
      console.error('Error sending contact form:', error);
    }
  };
  
  // Optionally reset isSubmitted after a few seconds
  useEffect(() => {
    if (isEnterpriseSubmitted) {
      const timer = setTimeout(() => setIsEnterpriseSubmitted(false), 10000); // Reset after 10 seconds
      return () => clearTimeout(timer);
    }
  }, [isEnterpriseSubmitted]);
  

  const plans = [
    {
      icon: <CameraIcon className="pricing-icon icon-red" />,
      title: "Taster",
      price: "FREE",
      features: [
        "1 user",
        "1 project",
        "GA4 and GSC connection",
        "Import up to 250 URLs per day",
		"2 enhance plan URLs including AI recommendations",
		"Daily metric update & storage for 7 days",
		"Journey flow visualisation for each URL", 
        "Task manager",
        "Daily URL snapshots for 7 days",
        "Task completion annotations",
        "Automatic redirect detection",
		"Generate support for 5 recommendations - one off"
      ],
      buttonText: "Sign Up",
      buttonAction: () => navigate('/signup')
    },
    {
      icon: <PersonOutlineIcon className="pricing-icon icon-blue" />,
      title: "Individual",
      price: "$24.95 PER MONTH",
      features: [
        "1 user",
        "3 projects",
        "GA4 and GSC connection",
        "Import up to 500 URLs per day",
        "50 enhance plan URLs including AI recommendations",
		"Daily metric update & storage forever",
		"Journey flow visualisation for each URL", 
        "Task manager",
        "Daily URL snapshots forever",
        "Task completion annotations",
        "Automatic redirect detection",
        "5 URL substitutions per month",
        "5 URL recommendation refreshes per month",
        "Generate support for 50 recommendations per month"
      ],
      buttonText: "Subscribe",
      buttonAction: () => handleCheckout(process.env.REACT_APP_PRICE_ID_INDIVIDUAL)
    },
    {
      icon: <PeopleOutlineIcon className="pricing-icon icon-purple" />,
      title: "Team",
      price: "$124.95 PER MONTH",
      features: [
        "5 users",
        "10 projects",
        "GA4 and GSC connection",
        "Import up to 1000 URLs per day",
        "250 enhance plan URLs including AI recommendations",
		 "Daily metric update & storage forever",
		 "Journey flow visualisation for each URL", 
        "Task manager",
        "Daily URL snapshots forever",
        "Task completion annotations",
        "Automatic redirect detection",
        "25 URL substitutions per month",
        "25 URL recommendation refreshes per month",
        "Generate support for 250 recommendations per month"
      ],
      buttonText: "Subscribe",
      buttonAction: () => handleCheckout(process.env.REACT_APP_PRICE_ID_TEAM)
    },
	{
	  icon: <BusinessCenterIcon className="pricing-icon icon-orange" />,
	  title: "Enterprise: More Scale & Complexity",
	  features: [],
	  buttonText: isEnterpriseSubmitted ? 'Thanks!' : "Contact Us",
	  buttonAction: handleContactSubmit,
	  input: true
	}
  ];

  return (
  
      <section className="pricing-section flex flex-col justify-center items-center text-center text-black">
        <div className="container">
          <h2 className="text-4xl mb-6">Choose your plan</h2>
          <div className="pricing-plans flex flex-wrap justify-center gap-6">
            {plans.map((plan, index) => (
              <div key={index} className="pricing-plan text-black p-8 m-4 rounded-lg text-center flex-1 min-w-[250px] border border-gray-700">
                {plan.icon}
                <h2 className="text-xl mb-2">{plan.title}</h2>
                <div className="relative flex py-5 items-center">
                  <div className="flex-grow border-t border-gray-200"></div>
                  <span className="flex-shrink mx-4 text-darkBlue font-bold">{plan.price}</span>
                  <div className="flex-grow border-t border-gray-200"></div>
                </div>
                <ul className="list-none text-left ml-4 text-sm">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center">
                     <DoneOutlineIcon className="text-green-500 mr-2" style={{ fontSize: '1rem' }} />
                      {feature}
                    </li>
                  ))}
                </ul>
				  {plan.input && (
				    <input
				      type="email"
				      placeholder="Your email"
				      value={email}
				      onChange={e => setEmail(e.target.value)}
				      className="p-2 mb-4 rounded border border-gray-300 text-black text-sm"
				    />
				  )}
				  <button
				    onClick={plan.buttonAction}
				    disabled={isEnterpriseSubmitted && plan.title === "Enterprise: More Scale & Complexity"} // Only disable this specific button
				    className={`bg-blue-600 text-white px-4 py-2 text-sm rounded mt-4 ml-2 ${isEnterpriseSubmitted && plan.title === "Enterprise: More Scale & Complexity" ? 'bg-gray-500' : ''}`}
				  >
				    {plan.title === "Enterprise: More Scale & Complexity" ? (isEnterpriseSubmitted ? 'Thanks!' : 'Contact Us') : plan.buttonText}
				  </button>
                
              </div>
            ))}
          </div>
        </div>
      </section>
      
  );
};

export default Pricing;
