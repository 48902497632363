import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import logo from './contentEnhance-logo.png';
import './Sidebar.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

function TopBar() {
  const [avatarUrl, setAvatarUrl] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
        if (response.data.isAuthenticated) {
          setIsAuthenticated(true);
          const userResponse = await axios.get('https://api.contentenhance.com/api/user/avatar', { withCredentials: true });
          setAvatarUrl(userResponse.data.avatar_url);
          setEmail(userResponse.data.email);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Authentication check failed:', error);
        setIsAuthenticated(false);
      }
    };

    const cachedAuthStatus = localStorage.getItem('isAuthenticated');
    if (cachedAuthStatus === 'true') {
      setIsAuthenticated(true);
      axios.get('https://api.contentenhance.com/api/user/avatar', { withCredentials: true })
        .then(response => {
          setAvatarUrl(response.data.avatar_url);
          setEmail(response.data.email);
        })
        .catch(error => console.error('Error fetching avatar:', error));
    } else {
      checkAuthentication();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated !== null) {
      localStorage.setItem('isAuthenticated', isAuthenticated);
    }
  }, [isAuthenticated]);

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get('https://api.contentenhance.com/logout', { withCredentials: true });
      if (response.status === 200) {
        localStorage.clear();  // Clear all items from local storage
        window.location.href = 'https://www.contentenhance.com/login';  // Redirect to login page
      } else {
        throw new Error('Logout failed');
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const closeDropdown = (event) => {
      if (!event.target.closest('.dropdown-container')) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', closeDropdown);
    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, []);

  const sidebarLinkStyle = {
    display: 'flex',
    alignItems: 'center',
    color: '#b3b3b3',
    padding: '8px 16px',
    textDecoration: 'none',
    gap: '8px',
    fontSize: '.875rem',
  };

  const iconStyle = {
    color: '#b3b3b3',
    fontSize: '.875rem',
  };

  const arrowStyle = {
    ...iconStyle,
    marginLeft: 'auto',
  };

  return (
    <div className="bg-darkBlue text-white px-4 py-2 flex items-center justify-between">
      <div className="flex items-center">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-12" />
        </Link>
      </div>
      <div className="flex items-center relative">
        {isAuthenticated === null ? (
          <div className="h-8 w-8 rounded-full bg-gray-300 flex items-center justify-center text-sm mr-4">
            <span>Loading...</span>
          </div>
        ) : isAuthenticated ? (
          <div onClick={toggleDropdown} className="cursor-pointer">
            {avatarUrl ? (
              <img src={avatarUrl} alt="User Avatar" className="h-8 w-8 rounded-full object-cover mr-4" />
            ) : (
              <div className="h-8 w-8 rounded-full bg-gray-300 flex items-center justify-center text-sm mr-4">
                <span>User</span>
              </div>
            )}
          </div>
        ) : (
          <button className="bg-blue-600 text-sm text-white py-2 px-4 rounded hover:bg-blue-700" onClick={() => navigate('/login')}>
            Login
          </button>
        )}
        {isDropdownOpen && isAuthenticated && (
          <div className="dropdown-container absolute right-2 mt-48 bg-gray-900 text-sm text-gray-200 rounded shadow-lg z-50 w-56">
            <div className="email-display" title={email}>{email}</div>
            <div className="relative flex items-center">
              <div className="flex-grow border-t border-gray-700"></div>
            </div>
            <ul className="py-4">
              <li>
                <a href="/workspaces" className="sidebar-link" style={{...sidebarLinkStyle, width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                  <WorkspacesIcon  style={iconStyle} />Workspaces <ArrowForwardIosIcon style={arrowStyle} />
                </a>
              </li>
              <li>
                <a href="/user-settings" className="sidebar-link" style={{...sidebarLinkStyle, width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                  <SettingsIcon style={iconStyle} />User settings <ArrowForwardIosIcon style={arrowStyle} />
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" onClick={handleLogout} className="sidebar-link" style={{...sidebarLinkStyle, width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                  <WavingHandIcon style={iconStyle} /> Logout
                  <ArrowForwardIosIcon style={arrowStyle} />
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default TopBar;
