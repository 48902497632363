import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import SpinnerComponent from './SpinnerComponent'; // Ensure the path is correct

const AudienceBrief = () => {
    const [audienceInsight, setAudienceInsight] = useState('');
    const [websiteAddress, setWebsiteAddress] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [generationError, setGenerationError] = useState('');
    const [showHelp, setShowHelp] = useState(false);
    const { projectId } = useParams(); // Extract projectID from the route
    const [userRole, setUserRole] = useState('');
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(''); 

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
                if (!response.data.isAuthenticated) {
                    navigate('/login'); // Redirect to login if not authenticated
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                navigate('/login'); // Redirect to login on error
            }
        };

        checkAuthentication();
    }, [navigate]);

    useEffect(() => {
        // Fetch audience insight
        const fetchAudienceInsight = () => {
            axios.get(`https://api.contentenhance.com/api/audience-insight/${projectId}`, {
                withCredentials: true
            })
                .then(response => {
                    setAudienceInsight(response.data.audience_insight || '');
                })
                .catch(error => {
                    if (error.response) {
                        // Check for response status
                        if (error.response.status === 401) {
                            navigate('/login'); // Redirect to login
                        } else if (error.response.status === 403) {
                            setErrorMessage('You do not have permission to access this resource.');
                            // Optionally redirect to a different page
                        } else {
                            console.error("There was an error fetching the audience insights: ", error);
                        }
                    } else {
                        // Handle errors not related to a response
                        console.error("There was an error fetching the audience insights: ", error);
                    }
                });
        };

        // Fetch user role for the project
        const fetchUserRole = () => {
            axios.get(`https://api.contentenhance.com/api/projects/${projectId}/check_role`, { withCredentials: true })
                .then(response => {
                    setUserRole(response.data.role);
                })
                .catch(error => console.error("There was an error fetching the user role: ", error));
        };

        fetchAudienceInsight();
        fetchUserRole();
    }, [projectId]);

    const handleSave = () => {
        if (!['admin', 'editor'].includes(userRole.toLowerCase())) {
            alert("You don't have permission to save edits.");
            return;
        }
        // Send the updated audience insight to the backend
        axios.post(`https://api.contentenhance.com/api/audience-insight/${projectId}`, 
                   { audience_insight: audienceInsight },
                   { withCredentials: true } // Include this line to send cookies
        )
        .then(response => {
            alert('Audience Insight updated successfully');
        })
        .catch(error => {
            console.error("There was an error updating the audience insight: ", error);
        });
    };

    const handleGenerateAudienceStarter = async () => {
        if (!['admin', 'editor'].includes(userRole.toLowerCase())) {
            alert("You don't have permission to generate audience insights.");
            return;
        }

        setIsGenerating(true);
        setGenerationError('');
        try {
            const response = await axios.post('https://api.contentenhance.com/api/generate-audience', {
                website: websiteAddress // Use the actual data from the state
            }, {
                withCredentials: true // Ensure credentials (cookies) are sent with the request
            });
            setAudienceInsight(response.data.audienceStarter); // Assuming the response contains audienceStarter
        } catch (error) {
            console.error('Error generating audience starter:', error);
            setGenerationError('Failed to generate audience starter. Please try again.');
        } finally {
            setIsGenerating(false);
        }
    };

    const toggleHelp = () => {
        setShowHelp(!showHelp);
    };

    return (
        <div className="flex flex-col h-screen bg-darkBlue">
            <TopBar />
            <div className="flex h-screen bg-lighterDarkBlue text-white">
                <Sidebar />
                <div className="flex-1 bg-gray-850 p-10 overflow-auto">
<div className="flex mb-8">
                        <h1 className="text-white text-base mr-2">
                            Audience brief
                        </h1>
		<span onClick={toggleHelp} className="cursor-pointer text-blue-500 text-sm">(Help me with this!)</span>
                    </div>
                    {showHelp && (
                        <div className="mb-4 flex space-x-2">
                            <input
                                id="help-textbox"
                                placeholder="Add your website address to generate audience starter"
                                value={websiteAddress}
                                onChange={(e) => setWebsiteAddress(e.target.value)}
                                className="w-1/3 appearance-none px-3 py-2 border border-gray-600 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-700"
                            />
                            {isGenerating ? (
                                <div className="flex justify-center items-center w-1/12">
                                    <SpinnerComponent />
                                </div>
                            ) : (
							<button
							    className="w-full sm:w-auto bg-blue-600 text-sm text-white py-2 px-4 rounded hover:bg-blue-700 flex justify-center items-center"
							    onClick={handleGenerateAudienceStarter}
							>
							    Generate
							</button>
							
                                
                            )}
                        </div>
                    )}
                    {generationError && <p className="text-red-500 text-sm mt-2">{generationError}</p>}
                    <textarea
                        className="w-full bg-gray-900 text-gray-200 text-sm p-4 h-96 rounded-md"
                        value={audienceInsight}
                        onChange={(e) => setAudienceInsight(e.target.value)}
                    />
                    <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded text-sm" onClick={handleSave}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AudienceBrief;
