import React, { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage('');

        try {
            const response = await axios.post('https://api.contentenhance.com/api/forgot_password', {
    email,
    recaptchaToken,
}, {
    withCredentials: true  // This enables sending cookies or auth tokens cross-domain.
});
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.error || 'Something went wrong. Please try again.');
        }
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    return (
        <div className="flex items-center justify-center h-screen bg-darkBlue">
            <div className="w-full max-w-md p-8 space-y-6 rounded-lg bg-gray-900 shadow-2xl">
                <h1 className="text-lg text-center text-white">Forgotten password</h1>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <input
                            type="email"
                            id="email"
                            required
                            placeholder="Enter your email address"
                            className="appearance-none w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-white bg-gray-700 text-sm"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
				<div className="flex justify-center">
				<ReCAPTCHA
				            sitekey="6LfZvDgqAAAAAG9dPKI2ctGz7Eq76R_rDfHMOuSu"
					        theme="dark"
				            onChange={handleRecaptchaChange}
				          />
							</div>
                    <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300">
                        Submit
                    </button>
                </form>
                {message && <p className="text-center text-white mt-4 text-sm">{message}</p>}
            </div>
        </div>
    );
};

export default ForgotPassword;
