import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import axios from 'axios';

const RemoveUrl = () => {
	const { projectId, urlId } = useParams();
	const [userRole, setUserRole] = useState('');
	const navigate = useNavigate(); // Hook to programmatically navigate
	const [errorMessage, setErrorMessage] = useState(''); 
	const [numOfSwaps, setNumOfSwaps] = useState(0);
	
	useEffect(() => {
	    const checkAuthentication = async () => {
	      try {
	        const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
	        if (!response.data.isAuthenticated) {
	          navigate('/login'); // Redirect to login if not authenticated
	        }
	      } catch (error) {
	        console.error('Authentication check failed:', error);
	        navigate('/login'); // Redirect to login on error
	      }
	    };

	    checkAuthentication();
	  }, [navigate]);
	
	
	const handleRemoveUrl = async () => { // Removed the parameters since you're using useParams
	  if (userRole !== 'admin') {
	    alert('You do not have permission to delete this URL.');
	    return;
	  }
	  const isConfirmed = window.confirm("Are you sure you want to delete this URL? This action cannot be undone.");

	  if (isConfirmed) {
	    try {
	      // Using Axios for DELETE request
	      const response = await axios.delete(`https://api.contentenhance.com/delete_enhance_plan_url/${urlId}`, { withCredentials: true });
	      if (response.status === 200) {
	        // Using navigate to redirect to the project page after deletion
	        window.location.href = `/projects/${projectId}`; // Using navigate from useNavigate() hook
	      } else {
	        throw new Error('Failed to delete the URL.');
	      }
	    } catch (error) {
	      console.error('Error:', error);
	      alert('An error occurred while deleting the URL.');
	    }
	  }
	};
	
	
	useEffect(() => {
	    const fetchData = async () => {
	      try {
	        // Fetch user role for the project
	        let response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/check_role`, { withCredentials: true });
	        setUserRole(response.data.role);

	        // Fetch project details including number_of_swaps
	        response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}`, { withCredentials: true });
	        setNumOfSwaps(response.data.number_of_swaps || 0); // Set to 0 if null
	      } catch (error) {
	        if (axios.isAxiosError(error)) {
	          if (error.response?.status === 401) {
	            navigate('/login');
	          } else if (error.response?.status === 403) {
	            setErrorMessage('You do not have permission to access this resource.');
	          }
	        } else {
	          console.error('An unexpected error occurred:', error);
	        }
	      }
	    };

	    fetchData();
	  }, [projectId, navigate]);
	

    return (
        <div className="flex flex-col h-screen bg-darkBlue">
            <TopBar />
            <div className="flex h-screen bg-lighterDarkBlue text-white">
                <Sidebar />
                <div className="flex-1 bg-gray-850 p-10 overflow-auto">
                    <h1 className="text-white text-base mb-8">
		Remove URL from Enhance Plan
                    </h1>
                 <p className="text-white text-sm mb-4">Only remove this URL if you are sure you will not require any of the data in future.</p>
		<p className="text-white text-sm mb-4">You will be able to add another URL instead if you have any URL substitutions left this month. Otherwise you will need to wait until next month to be able to add a replacement URL to your plan.</p>
		 <p className="text-white text-sm mb-4">You currently have <strong>{numOfSwaps} URL substitutions</strong> left this month.</p>
                    <button className="mt-4 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded text-sm" onClick={handleRemoveUrl}>
                        Remove URL
                    </button>
                </div>
            </div>
        </div>
    );
}

export default RemoveUrl;