import React from 'react';
import TopBar from './TopBar';
import './HomePage.css'; // Import the custom CSS file

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white text-black">
      <TopBar />
      <section className="flex flex-col justify-center items-center text-center py-16 bg-white text-black">
        <h1 className="text-4xl mb-6">Privacy Policy</h1>
        <div className="text-left max-w-4xl mx-auto px-4 space-y-6">
          <h2 className="text-2xl font-bold">1. Introduction</h2>
          <p>
            Welcome to contentEnhance, a service provided by Content Enhance Ltd ("we," "us," "our"). 
            We are committed to protecting your privacy and ensuring that your personal information 
            is handled in a safe and responsible manner. This privacy policy explains how we collect, 
            use, and protect your personal data when you use our application.
          </p>
          
          <h2 className="text-2xl font-bold">2. Company Information</h2>
          <p>
            <strong>Name:</strong> Content Enhance Ltd<br />
            <strong>Contact Information:</strong><br />
            Contact Person: Richard Lawrence<br />
            Email: richard.lawrence@contentenhance.com
          </p>
          
          <h2 className="text-2xl font-bold">3. Purpose of the Application</h2>
          <p>
            contentEnhance is designed to optimize your content using data and AI, providing insights 
            and recommendations to improve your content performance.
          </p>
          
          <h2 className="text-2xl font-bold">4. Data Collection</h2>
          <p>We collect the following types of personal data:</p>
          <ul className="list-disc list-inside ml-6">
            <li>Email and Avatar: Collected during user registration and profile setup.</li>
            <li>Google Analytics and Google Search Console Data: Collected if the user opts to connect these accounts.</li>
            <li>Screenshots: Taken and stored of pages that users grant access to.</li>
            <li>Cookies: Users can optionally upload cookies to grant access to specific pages.</li>
          </ul>
          
          <h2 className="text-2xl font-bold">5. Methods of Data Collection</h2>
          <p>We collect data through the following methods:</p>
          <ul className="list-disc list-inside ml-6">
            <li>Analytics: Data is collected through analytics tools integrated within the application.</li>
            <li>OAuth Connection: Data is collected when users connect their Google Analytics and Google Search Console accounts.</li>
            <li>User Upload: Users can upload cookies to the application.</li>
          </ul>

          <h2 className="text-2xl font-bold">6. Scopes of Data Collection</h2>
          <p>We collect and use the following Google user data with your permission:</p>
          <ul className="list-disc list-inside ml-6">
            <li>To associate personal info with Google Account.</li>
            <li>To see primary Google Account email.</li>
            <li>To see and download Google Analytics data.</li>
            <li>To view Google Search Console data.</li>
          </ul>
          <p className="font-bold">
            contentEnhance's use and transfer to any other app of information received from Google APIs will adhere to the Google API Services User Data Policy, including the Limited Use requirements. <a href="https://developers.google.com/terms/api-services-user-data-policy" className="text-blue-600 underline">Google API Services User Data Policy</a>
          </p>

          <h2 id="data-sharing" className="text-2xl font-bold">7. Data Sharing</h2>
          <p>We do not share your personal data with any third parties except as necessary to provide our services, comply with the law, or protect our rights. Specifically, we share data with:</p>
          <ul className="list-disc list-inside ml-6">
            <li>OpenAI: Used for processing AI-generated recommendations. If the user gives consent, the following basic data will be shared with OpenAI:</li>
            <ul className="list-disc list-inside ml-12">
              <li>The top five organic search queries bringing traffic to the page and number of clicks. (If Google Search Console connected as data source)</li>
              <li>The main source of traffic to the page e.g. 'google / organic'. (If Google Analytics connected as data source)</li>
              <li>The top device which users use when landing on the page e.g. 'mobile'. (If Google Analytics connected as data source)</li>
              <li>The URL of the page.</li>
			  <li>A screenshot of the page</li>
            </ul>
          </ul>
          
          <h2 className="text-2xl font-bold">8. Data Storage and Security</h2>
          <p>Data is stored and protected as follows:</p>
          <ul className="list-disc list-inside ml-6">
            <li>Data Storage: Data is stored in a PostgreSQL database hosted on Heroku.</li>
            <li>Data Security Measures:</li>
            <ul className="list-disc list-inside ml-6">
              <li>JWT Tokens: Used for secure user authentication.</li>
              <li>CSRF Tokens: Implemented to protect against cross-site request forgery.</li>
              <li>No Frames Policy: Ensured to prevent clickjacking attacks.</li>
              <li>Encryption: All data is encrypted both at rest and in transit.</li>
              <li>Access Controls: Strict access controls are in place to limit data access to authorized personnel only.</li>
            </ul>
          </ul>
          
          <h2 className="text-2xl font-bold">9. User Rights</h2>
          <p>Users have the following rights regarding their personal data:</p>
          <ul className="list-disc list-inside ml-6">
            <li>Access: Users can request access to their personal data.</li>
            <li>Rectification: Users can request corrections to inaccurate or incomplete data.</li>
            <li>Deletion: Users can request the deletion of their personal data.</li>
          </ul>
          <p>To exercise these rights, users can contact Richard Lawrence at richard.lawrence@contentenhance.com.</p>
          
          <h2 className="text-2xl font-bold">10. Cookies and Tracking</h2>
          <p>
            <strong>Use of Cookies:</strong> Our site allows users to upload cookies to grant access to specific pages. 
            We do not currently use tracking technologies.<br />
            <strong>Managing Cookies:</strong> Users can manage or disable cookies through their browser settings.
          </p>
          
          <h2 className="text-2xl font-bold">11. Legal Compliance</h2>
          <p>We comply with the General Data Protection Regulation (GDPR) as required for our operations within the EU.</p>
          
          <h2 className="text-2xl font-bold">12. Changes to the Privacy Policy</h2>
          <p>
            We may update this privacy policy from time to time. We will notify users of any changes by posting the new 
            policy on this page. Users are advised to review this policy periodically for any changes.
          </p>
          
          <h2 className="text-2xl font-bold">13. Contact Information</h2>
          <p>
            If you have any questions or concerns about this privacy policy or our data practices, please contact:<br />
            Richard Lawrence<br />
            Email: richard.lawrence@contentenhance.com
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
