import React from 'react';

const PulsingLoader = () => {
  const loaderStyle = {
    width: '100%',
    height: '100%', // Ensure the loader spans the full height of the row
  };

  return (
    <div style={loaderStyle}></div>
  );
};

export default PulsingLoader;

