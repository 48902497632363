import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { useNavigate, useParams } from 'react-router-dom';

const MigrateURLs = () => {
	const navigate = useNavigate();
	useEffect(() => {
	    const checkAuthentication = async () => {
	      try {
	        const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
	        if (!response.data.isAuthenticated) {
	          navigate('/login'); // Redirect to login if not authenticated
	        }
	      } catch (error) {
	        console.error('Authentication check failed:', error);
	        navigate('/login'); // Redirect to login on error
	      }
	    };

	    checkAuthentication();
	  }, [navigate]);
  return (
    <div className="flex flex-col h-screen bg-darkBlue">
      <TopBar />
      <div className="flex h-screen bg-lighterDarkBlue text-white">
        <Sidebar />
        <div className="flex-1 bg-gray-850 p-10 overflow-auto">
          <h1 className="text-white text-base mb-8">
            Coming soon...
          </h1>
        </div>
      </div>
    </div>
  );
}

export default MigrateURLs;