import React from 'react';
import TopBar from './TopBar';
import './HomePage.css'; // Import the custom CSS file

const TermsOfService = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white text-black">
      <TopBar />
      <section className="flex flex-col justify-center items-center text-center py-16 bg-white text-black">
        <h1 className="text-4xl mb-6">Terms of Service</h1>
        <div className="text-left max-w-4xl mx-auto px-4 space-y-6">
          <h2 className="text-2xl font-bold">1. Introduction</h2>
          <p>
            Welcome to contentEnhance, a service provided by Content Enhance Ltd ("we," "us," "our"). By accessing or using our service, you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you may not use the service.
          </p>
          <h2 className="text-2xl font-bold">2. Definitions</h2>
          <p>
            <strong>"Service"</strong> refers to the contentEnhance application and any related services provided by Content Enhance Ltd.<br />
            <strong>"User"</strong> refers to any individual or entity using the Service.<br />
            <strong>"Content"</strong> refers to any information, text, graphics, or other materials uploaded, downloaded, or appearing on the Service.
          </p>
          <h2 className="text-2xl font-bold">3. Acceptance of Terms</h2>
          <p>
            By accessing or using the Service, you agree to be bound by these Terms and any future modifications. If you do not agree, do not use the Service.
          </p>
          <h2 className="text-2xl font-bold">4. Changes to Terms</h2>
          <p>
            We may modify these Terms at any time. We will notify users of any changes by posting the new Terms on our website and/or sending an email to registered users. Continued use of the Service after changes constitutes acceptance of the new Terms.
          </p>
          <h2 className="text-2xl font-bold">5. User Accounts</h2>
          <p>
            To access certain features of the Service, you may be required to create an account. You agree to provide accurate and complete information and to update this information as necessary. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.
          </p>
          <h2 className="text-2xl font-bold">6. User Responsibilities</h2>
          <p>
            Users agree to use the Service in accordance with these Terms and applicable laws. Prohibited activities include, but are not limited to:
          </p>
          <ul className="list-disc list-inside ml-6">
            <li>Spamming, phishing, or engaging in any other harmful behavior.</li>
            <li>Uploading, posting, or sharing content that is illegal, harmful, or violates any third-party rights.</li>
            <li>Attempting to interfere with the proper functioning of the Service, including hacking or bypassing security measures.</li>
          </ul>
          <h2 className="text-2xl font-bold">7. Intellectual Property</h2>
          <p>
            The Service and its original content, features, and functionality are and will remain the exclusive property of Content Enhance Ltd and its licensors. Users may not use, copy, reproduce, or distribute any content without our explicit permission.
          </p>
          <h2 className="text-2xl font-bold">8. User-Generated Content</h2>
          <p>
            Users retain ownership of the content they create and share on the Service. By posting content, users grant us a non-exclusive, royalty-free, worldwide license to use, display, and distribute their content in connection with the Service.
          </p>
          <h2 className="text-2xl font-bold">9. Privacy Policy</h2>
          <p>
            Our Privacy Policy describes how we handle user data and privacy. By using the Service, you agree to the terms of the Privacy Policy.
          </p>
          <h2 className="text-2xl font-bold">10. Disclaimers and Limitation of Liability</h2>
          <p>
            The Service is provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not warrant that the Service will be uninterrupted or error-free. To the fullest extent permitted by law, we disclaim all warranties and will not be liable for any damages arising from the use of the Service.
          </p>
          <h2 className="text-2xl font-bold">11. Termination</h2>
          <p>
            We reserve the right to suspend or terminate your account and access to the Service at any time, without notice, for conduct that we believe violates these Terms or is harmful to other users. Upon termination, your right to use the Service will immediately cease.
          </p>
          <h2 className="text-2xl font-bold">12. Governing Law</h2>
          <p>
            These Terms are governed by and construed in accordance with the laws of the United Kingdom. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts located in the United Kingdom.
          </p>
          <h2 className="text-2xl font-bold">13. Contact Information</h2>
          <p>
            If you have any questions or concerns about these Terms, please contact us at:<br />
            Richard Lawrence<br />
            Email: richard.lawrence@contentenhance.com
          </p>
        </div>
      </section>
    </div>
  );
};

export default TermsOfService;
