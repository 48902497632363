import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ResponsiveBar } from '@nivo/bar';
import axios from 'axios';
import { format, subDays } from 'date-fns';

const NextDestinationsOverTime = () => {
    const { urlId } = useParams();
    const [data, setData] = useState([]);
    const [sortedKeys, setSortedKeys] = useState([]);
    const [hasData, setHasData] = useState(true);

    useEffect(() => {
        if (urlId) {
            const fetchData = async () => {
                try {
                    const response = await axios.get('https://api.contentenhance.com/api/next_url_data', {
                        params: { url_id: urlId },
                        withCredentials: true,
                    });
                    const nextUrlsData = response.data.next_urls;

                    if (nextUrlsData.length === 0) {
                        setHasData(false);
                        return;
                    }

                    // Gather all keys (next_urls) across all dates
                    const allKeys = new Set();
                    nextUrlsData.forEach(item => allKeys.add(item.next_url));

                    // Combine data and ensure all keys are present for each date
                    let combinedData = nextUrlsData.reduce((acc, item) => {
                        const adjustedDate = format(subDays(new Date(item.date), 2), 'yyyy-MM-dd');
                        const dateEntry = acc.find(entry => entry.date === adjustedDate);
                        if (dateEntry) {
                            if (dateEntry[item.next_url]) {
                                dateEntry[item.next_url] += item.sessions;
                            } else {
                                dateEntry[item.next_url] = item.sessions;
                            }
                            dateEntry.total += item.sessions;
                        } else {
                            acc.push({
                                date: adjustedDate,
                                [item.next_url]: item.sessions,
                                total: item.sessions,
                            });
                        }
                        return acc;
                    }, []);

                    // Format data and add missing keys
                    let formattedData = combinedData.map(entry => {
                        const totalSessions = entry.total;
                        const percentageEntry = { date: entry.date, originalData: entry };

                        allKeys.forEach(key => {
                            percentageEntry[key] = parseFloat(((entry[key] || 0) / totalSessions * 100).toFixed(1));
                        });

                        return percentageEntry;
                    });

                    const keySums = Object.keys(formattedData[0] || {}).filter(key => key !== 'date' && key !== 'originalData')
                        .map(key => ({
                            key,
                            total: formattedData.reduce((sum, entry) => sum + (entry[key] || 0), 0)
                        }));

                    const sortedKeys = keySums.sort((a, b) => a.total - b.total).map(item => item.key).reverse();
                    setSortedKeys(sortedKeys);

                    formattedData = formattedData.sort((a, b) => new Date(a.date) - new Date(b.date));

                    setData(formattedData);
                    setHasData(true);
                } catch (error) {
                    console.error('Error fetching next URL data:', error);
                    setHasData(false);
                }
            };
            fetchData();
        }
    }, [urlId]);

    return (
        <div style={{ height: 280 }} className="min-w-[1200px]">
            {hasData && data.length > 0 ? (
                <>
                    <h2 className="mt-8 text-base" style={{ textAlign: 'center', color: 'white' }}>Next destinations over time (%)</h2>
                    <ResponsiveBar
                        data={data}
                        keys={sortedKeys}
                        indexBy="date"
                        margin={{ top: 20, right: 200, bottom: 50, left: 60 }}
                        padding={0.3}
                        groupMode="stacked"
                        colors={{ scheme: 'pastel2' }}
                        axisTop={null}
                        axisRight={null}
						axisBottom={{
						                            tickSize: 5,
						                            tickPadding: 5,
						                            tickRotation: -45, // Rotate labels to a diagonal position
						                            
						                            legendPosition: 'middle',
						                            legendOffset: 32,
						                            tickColor: 'white',
						                            legendTextColor: 'white',
						                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '% split over previous 90 days',
                            legendPosition: 'middle',
                            legendOffset: -50,
                            format: value => `${value}%`,
                            tickColor: 'white',
                            legendTextColor: 'white',
                            max: 100,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor="black"
						labelFormat={value => `${Number(value).toFixed(0)}%`}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'right',
                                direction: 'column',
                                justify: false,
                                translateX: 170,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 180,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                textColor: 'white',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        tooltip={({ id, value, indexValue, data }) => {
                            const originalValue = data.originalData[id];
                            return (
                                <div style={{ color: 'white', background: 'black', padding: '5px', borderRadius: '3px' ,  fontSize: '12px'}}>
                                    <strong>{id}</strong><br />
                                    {indexValue}: {originalValue} sessions<br />
                                    ({value}%)
                                </div>
                            );
                        }}
                        theme={{
                            axis: {
                                ticks: {
                                    text: { fill: 'white' },
                                },
                                legend: {
                                    text: { fill: 'white' },
                                },
                            },
                            grid: {
                                line: {
                                    stroke: 'none',
                                },
                            },
                            legends: {
                                text: { fill: 'white' },
                            },
                            tooltip: {
                                container: {
                                    background: 'black',
                                    color: 'white',
                                    fontSize: '12px',
                                },
                            },
                        }}
                        role="application"
                        ariaLabel="Next destinations over time stacked bar chart"
                        barAriaLabel={e => `${e.id}: ${e.formattedValue}% sessions on ${e.indexValue}`}
                    />
                </>
            ) : (
                <div className="text-center text-gray-400 mt-8">Not enough data available for Next Destinations</div>
            )}
        </div>
    );
};

export default NextDestinationsOverTime;
