import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { useLocation, Link } from 'react-router-dom'; // Import Link from react-router-dom
import GoogleOAuthButton from './GoogleOAuthButton';
import axios from 'axios'; // Import axios here
import symbol from './contentenhance-new-project.png';
import { useNavigate } from 'react-router-dom';
import SpinnerComponent from './SpinnerComponent'; // Ensure the path is correct


function ProjectPage() {
    const [showModal, setShowModal] = useState(false);
	const [showMobileModal, setShowMobileModal] = useState(false);
    const [dateRange, setDateRange] = useState('');
    const [projects, setProjects] = useState([]);
    const [ga4Account, setGa4Account] = useState('');
     const [ga4Property, setGa4Property] = useState('');
     const [gscProperty, setGscProperty] = useState('');
    const location = useLocation();
    const [ga4Accounts, setGa4Accounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [ga4Properties, setGa4Properties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState('');
    const [gscProperties, setGscProperties] = useState([]);
    const [selectedGscProperty, setSelectedGscProperty] = useState('');
	const [accountNameToIdMap, setAccountNameToIdMap] = useState({});
	const [projectName, setProjectName] = useState(''); 
	const navigate = useNavigate();
	const [audienceDescription, setAudienceDescription] = useState('');
	const [validationMessage, setValidationMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [showHelp, setShowHelp] = useState(false); // New state for toggling help section
	const [isGenerating, setIsGenerating] = useState(false); // New state for loading
	const [generationError, setGenerationError] = useState(''); // New state for error handling
	const [websiteAddress, setWebsiteAddress] = useState('');
	
	
	
	const toggleHelp = () => {
	    setShowHelp(!showHelp); // Toggle the help section visibility
	};

	    // Existing useEffects and functions...
	
	useEffect(() => {
	    const checkAuthentication = async () => {
	      try {
	        const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
	        if (!response.data.isAuthenticated) {
	          navigate('/login'); // Redirect to login if not authenticated
	        }
	      } catch (error) {
	        console.error('Authentication check failed:', error);
	        navigate('/login'); // Redirect to login on error
	      }
	    };

	    checkAuthentication();
	  }, [navigate]);

	    
	
	useEffect(() => {
	    // Fetches both GA4 accounts and GSC properties on component mount
	    fetchGA4Accounts();
	    fetchGscProperties();
	}, []);
	
    const validateAudienceDescription = (description) => {
        const words = description.trim().split(/\s+/); // Split by whitespace and filter out empty strings
        if (words.length > 1000) {
            setValidationMessage('Audience description must be less than 1000 words.');
            return false;
        } else {
            setValidationMessage(''); // Clear validation message if valid
            return true;
        }
    };
	
	useEffect(() => {
	    const handleResize = () => {
	        if (window.innerWidth < 768) { // Example threshold for mobile
	            setShowMobileModal(true);
	        }
	    };

	    handleResize(); // Check on component mount

	    window.addEventListener('resize', handleResize); // Add event listener

	    return () => window.removeEventListener('resize', handleResize); // Cleanup
	}, []);
	

    const handleAudienceDescriptionChange = (e) => {
        const description = e.target.value;
        setAudienceDescription(description);
        validateAudienceDescription(description);
    };
	

	
    // Function to fetch GA4 accounts
	// Function to fetch GA4 accounts and update the mapping of account names to IDs
	// Function to fetch GA4 accounts
	const fetchGA4Accounts = async () => {
	    try {
	        // No need to get the access token from localStorage
	        const response = await axios.get('https://api.contentenhance.com/api/ga4/accounts', {
	            withCredentials: true // Ensure credentials (cookies) are sent with the request
	        });
	        console.log("GA4 Accounts Response:", response.data);

	        // Set the accounts directly from the response
	        setGa4Accounts(response.data || []);
	    } catch (error) {
	        console.error('Error fetching GA4 accounts:', error);
	    }
	};
	


	const fetchGscProperties = async () => {
	    try {
	        // No need to get the access token from localStorage
	        const response = await axios.get('https://api.contentenhance.com/api/gsc/properties', {
	            withCredentials: true // Ensure credentials (cookies) are sent with the request
	        });
	        setGscProperties(response.data.siteEntry || []);
	    } catch (error) {
	        console.error('Error fetching GSC properties:', error);
	    }
	};
	
	const handleGscPropertySelection = (gscProperty) => {
	    // Reset validation message
	    setValidationMessage('');

	    setSelectedGscProperty(gscProperty);
	};
	
	const handleGenerateAudienceStarter = async () => {
	    setIsGenerating(true);
	    setGenerationError('');
	    try {
	        const response = await axios.post('https://api.contentenhance.com/api/generate-audience', {
	            website: websiteAddress // Use the actual data from the state
	        }, {
	            withCredentials: true // Ensure credentials (cookies) are sent with the request
	        });
	        setAudienceDescription(response.data.audienceStarter); // Assuming the response contains audienceStarter
	    } catch (error) {
	        console.error('Error generating audience starter:', error);
	        setGenerationError('Failed to generate audience starter. Please try again.');
	    } finally {
	        setIsGenerating(false);
	    }
	};
	
	
	const handleAccountSelection = (accountName) => {
		setValidationMessage('');
	    // Find the account by the selected account name
	    const account = ga4Accounts.find(acc => acc.name === accountName);
	    setSelectedAccount(account);

	    // Set the properties of the selected account to the ga4Properties state
	    if (account && Array.isArray(account.properties)) {
	        setGa4Properties(account.properties);
	    } else {
	        setGa4Properties([]);
	    }
	};
	
	const handlePropertySelection = (propertyString) => {
		setValidationMessage('');
	    const selectedProp = ga4Properties.find(p => p.property === propertyString);
	    console.log("Selected GA4 property object:", selectedProp);
	    setSelectedProperty(selectedProp);
	};
	
	
	
	const fetchGA4Data = async (propertyId, projectId, siteUrl) => {
	    try {

	        const response = await axios.post('https://api.contentenhance.com/api/fetch_ga4_data', {
	            propertyId,
	            projectId,
	            siteUrl,
	        }, {
	            withCredentials: true // Ensure credentials (cookies) are sent with the request
	        });

	        // Handle the response as needed, such as updating state or UI
	    } catch (error) {
	        console.error('Error fetching GA4 data:', error);
	        // Handle the error, such as displaying an error message to the user
	    }
	};
	

	

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('modal') === 'open') {
            setShowModal(true);
        }
    }, [location]);
	
    useEffect(() => {
        // Fetch projects right after the component mounts
        fetchProjects();
    }, []); // The empty array ensures this effect only runs once after the initial render

	const fetchProjects = async () => {
	    try {
	        // Use axios to fetch projects and include credentials
	        const response = await axios.get('https://api.contentenhance.com/api/projects', {
	            withCredentials: true // Ensure credentials are sent with the request
	        });

	        // Sort the projects alphabetically by project_name
	        const sortedProjects = response.data.sort((a, b) => 
	            a.project_name.localeCompare(b.project_name)
	        );

	        // Set the sorted projects in state
	        setProjects(sortedProjects);
	    } catch (error) {
	        console.error('Error fetching projects:', error);
	        if (error.response && error.response.status === 401) {
	            navigate('/login');
	        }
	    }
	};

	const toggleModal = async () => {
	        try {
	            const canCreateProjectResponse = await axios.get('https://api.contentenhance.com/api/can-create-project', {
	                withCredentials: true
	            });

	            if (!canCreateProjectResponse.data.canCreate) {
	                setErrorMessage('Project allocation limit reached. Add more or delete existing project.');
	                return;
	            }
	            // Reset error message and show modal if the user can create a project
	            setErrorMessage('');
	            setShowModal(!showModal);
	        } catch (error) {
	            console.error('Error checking project creation allowance:', error);
	            setErrorMessage('Failed to check project creation allowance. Please try again.');
	        }
	    };
	
		const validateSelections = () => {
		    if (selectedGscProperty && (!selectedAccount || !selectedProperty)) {
		        setValidationMessage('Please select both a GA4 Account and Property when a GSC Property is selected - data extraction does not work properly without both.');
		        return false;
		    }
		    setValidationMessage('');
		    return true;
		};


	const handleCreateProject = async () => {
	    if (!projectName.trim()) {
	        console.error('The project name is required.');
	        return;
	    }

	    if (!validateAudienceDescription(audienceDescription)) {
	        return;
	    }
		
		if (!validateSelections()) {
		        return;
		    }

	    try {
	        let propertyId, ga4AccountId, ga4AccountName, ga4PropertyId, ga4PropertyName, gscPropertyUrl;
	        if (selectedProperty && selectedProperty.property) {
	            // Extract the propertyId from the selectedProperty if it exists
	            propertyId = selectedProperty.property.split('/')[1];
	        }
		    if (selectedAccount) {
		        ga4AccountId = selectedAccount.id;  // Assuming the account object has an id field
		        ga4AccountName = selectedAccount.name;  // Storing the name for display purposes
		    }
		    if (selectedProperty) {
		        ga4PropertyId = selectedProperty.property.split('/')[1]; // Extract the GA4 property ID
		        ga4PropertyName = selectedProperty.displayName;  // Storing the display name
		    }
		    gscPropertyUrl = selectedGscProperty; // Directly taken from the selection

	        const projectResponse = await axios.post('https://api.contentenhance.com/api/create_project', {
	            project_name: projectName,
	            audience_insights: audienceDescription,
	            property_id: propertyId,  // This can be undefined if GA4 property isn't provided
	            site_url: selectedGscProperty || undefined,
	            ga4_account: ga4AccountName,
	            ga4_property: ga4PropertyName,
	            gsc_property: selectedGscProperty || undefined,
	        }, { withCredentials: true });

	        const projectId = projectResponse.data.project_id;
	        console.log('Project created with ID:', projectId);

	        // Call fetchGA4Data only if GA4 property is selected
	        if (propertyId) {
	            await fetchGA4Data(propertyId, projectId, selectedGscProperty);
	        }

	        // Redirect based on whether GA4 property is provided
	        navigate(`/projects/${projectId}/ai-access-check`);
	    } catch (error) {
	        console.error('Error in creating project or fetching data:', error);
	    }
	};
	
	

//	const fetchGSCData = async (projectId, siteUrl) => {
//	    try {
	        // Change from axios.get to axios.post and move projectId and siteUrl to the request body
//	        const response = await axios.post('https://api.contentenhance.com/api/fetch_gsc_data', {
//	            projectId, // Assuming your backend is expecting projectId in the request body
//	            siteUrl, // Assuming your backend is expecting siteUrl in the request body
//	        }, {
//	            withCredentials: true // Ensure credentials (cookies) are sent with the request
//	        });

	        // Handle the response as needed, such as updating state or UI
//	        console.log('GSC data fetched successfully:', response.data);
//	    } catch (error) {
//	        console.error('Error fetching GSC data:', error);
	        // Handle the error, such as displaying an error message to the user
//	    }
//	};
	
	
    return (
           <div className="flex flex-col h-screen bg-darkBlue">
               <TopBar />
               <div className="flex h-screen bg-lighterDarkBlue text-white">
                   <Sidebar />
                   <div className="flex-1 bg-gray-850 p-10 overflow-auto">
                       <h1 className="text-white text-base mb-8">Projects</h1>
                       {/* Check if there are no projects and render the larger prompt */}
                       {projects.length === 0 ? (
                           <div>
                               <div className="bg-gray-900 p-10 rounded-lg shadow">
                                   <div className="text-center">
                                       <p className="text-white text-base">👋 Create your first project to get started</p>
                                       {errorMessage && <p className="text-sm text-red-200 text-center">{errorMessage}</p>}
									   <button onClick={toggleModal} className="mt-4 mb-4 bg-blue-500 text-white text-sm px-6 py-2 rounded hover:bg-blue-600">
                                           + New Project
                                       </button>
                                       <p className="text-sm text-white mt-2">We will walk you through the process step-by-step and you will be providing better content experiences before you know it.</p>
                                   </div>
                               </div>
                           </div>
                       ) : (
                           // Existing projects grid
<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 h-64 mb-6">
                           
                               {/* Existing projects */}
                               {projects.map((project) => (
                                   <Link to={`/projects/${project.project_id}`} key={project.project_id} className="rounded-lg p-4 flex flex-col bg-gray-900 items-center justify-center hover:shadow-lg transition">
                                       <img src={symbol} alt="Project summary" className="w-10 h-10 mb-2" />
				<span className="text-white text-sm">Project: {project.project_name}</span>
            </Link>
        ))}
        {/* New Project Square - Shown only if there are existing projects */}
        <div className="rounded-lg p-4 flex flex-col bg-gray-900 items-center justify-center hover:shadow-lg transition cursor-pointer" onClick={toggleModal}>
            <div className="text-gray-700 text-4xl">+</div>
            <span className="text-white text-sm">New Project</span>
		{errorMessage && <p className="text-sm text-red-200 text-center">{errorMessage}</p>}
        </div>
    </div>
)}
{showMobileModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1300]">
    <div className="w-full max-w-md p-4 space-y-2 rounded-lg bg-gray-900 shadow-2xl z-[1300]">
      <button 
        onClick={() => setShowMobileModal(false)} 
        className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800 text-3xl p-2 z-[1300]"
      >
        &times;
      </button>
      <h2 className="text-center text-gray-200 text-lg z-[1300]">Better on Larger Screens</h2>
      <p className="text-center text-gray-400 text-sm z-[1300]">
        The experience is currently designed for larger screens to better display the data. Please switch if you can.
      </p>
      <div className="flex justify-center mt-4">
        <button 
          onClick={() => setShowMobileModal(false)} 
          className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 text-sm z-[1300]"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}
{/* Modal */}
{showModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="w-full max-w-md p-4 space-y-2 rounded-lg bg-gray-900 shadow-2xl" style={{ maxWidth: '600px', overflowY: 'auto', maxHeight: '80vh', }}>
      <button onClick={toggleModal} className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800 text-3xl p-2">
        <span aria-hidden="true">&times;</span>
      </button>
      <h2 className="text-base text-gray-200 text-center">Create project</h2>
      <div className="relative flex py-4 items-center">
        <div className="flex-grow border-t border-gray-700"></div>
      </div>

      <h3 className="text-base text-gray-200 mb-2">Step 1: Connect your Google account (optional)</h3>
      <GoogleOAuthButton />

      <div className="relative flex py-4 items-center">
        <div className="flex-grow border-t border-gray-700"></div>
      </div>

      <h3 className="text-base text-gray-200 mb-2">Step 2: Connect data sources (optional)</h3>
      {/* GA4 Account Selection */}
      <select 
        value={selectedAccount ? selectedAccount.name : ''}
        onChange={(e) => handleAccountSelection(e.target.value)}
        className="text-sm appearance-none w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 mb-4 bg-gray-700"
      >
        <option value="">Select a GA4 Account</option>
        {ga4Accounts.map(account => (
          <option key={account.id} value={account.name}>{account.name}</option>
        ))}
      </select>

      {/* GA4 Property Selection */}
      <select
        value={selectedProperty ? selectedProperty.property : ''}
        onChange={(e) => handlePropertySelection(e.target.value)}
        className="text-sm appearance-none w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 mb-4 bg-gray-700"
      >
        <option value="">Select a GA4 Property</option>
        {ga4Properties.map((prop, index) => (
          <option key={index} value={prop.property}>{prop.displayName}</option>
        ))}
      </select>

      {/* GSC Property Selection */}
  <select 
      value={selectedGscProperty} 
      onChange={(e) => handleGscPropertySelection(e.target.value)}
      className="text-sm appearance-none w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 mb-2 bg-gray-700"
  >
      <option value="">Select a GSC Property</option>
      {gscProperties.map(property => (
          <option key={property.siteUrl} value={property.siteUrl}>{property.siteUrl}</option>
      ))}
  </select>
		
		{/* Display validation message */}
	{validationMessage && <p className="text-sm text-red-200 text-center">{validationMessage}</p>}

      <div className="relative flex py-4 items-center">
        <div className="flex-grow border-t border-gray-700"></div>
      </div>

<h3 className="text-base text-gray-200 mb-2">Step 3: Name your project</h3>
      <input 
        type="text"
        id="name"
        autoComplete="name"
        required
        placeholder="Enter project name"
        value={projectName}
        onChange={(e) => setProjectName(e.target.value)}
        className="appearance-none w-full px-3 py-2 border border-gray-600 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-700"
      />
      <div className="relative flex py-4 items-center">
        <div className="flex-grow border-t border-gray-700"></div>
      </div>
      {/* New field for Target Audience Description */}
      <div className="mb-4">
  <div className="flex items-center mb-2">
          <h3 className="text-base text-gray-200">
              Step 4: Describe your target audience (optional)
          </h3>
          <span onClick={toggleHelp} className="cursor-pointer text-blue-500 ml-2 text-sm">Help me with this!</span>
      </div>
	  {showHelp && (
	      <div className="mt-2 flex items-center space-x-2 mb-2">
	          <input
	              id="help-textbox"
	              placeholder="Add your website address to generate audience starter"
		      value={websiteAddress}
		      onChange={(e) => setWebsiteAddress(e.target.value)}
	              className="flex-grow appearance-none px-3 py-2 border border-gray-600 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-700"
	          ></input>
	          {isGenerating ? (
	              <div className="flex justify-center items-center mb-2 ml-2 w-2/12">
	                  <SpinnerComponent />
	              </div>
	          ) : (
	              <button
	                  className="bg-blue-600 text-sm text-white py-2 px-4 rounded hover:bg-blue-700"
	                  onClick={handleGenerateAudienceStarter}
	              >
	                  Generate
	              </button>
	          )}
	      </div>
	  )}
	  {generationError && <p className="text-red-500 text-sm mt-2">{generationError}</p>}
	  
	  
        <textarea 
          id="audience-description"
          rows="4"
          placeholder="Briefly describe your target audience in up to 1,000 words (optional)"
	      value={audienceDescription}  // Bind textarea value to audienceDescription state
	      onChange={handleAudienceDescriptionChange}  // Update state on change
          className="appearance-none w-full px-3 py-2 border border-gray-600 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-700"
        ></textarea>
      </div>

      <div className="mt-10">
        <button onClick={handleCreateProject} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:ring-4 focus:ring-blue-300">
          Get started
        </button>
      </div>
    </div>
  </div>
)}

			                </div>
			            </div>
			        </div>
			    );
			}

export default ProjectPage;