import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import TopBarMarketing from './TopBarMarketing';
import './ContentPerformance.css'; // Import the custom CSS file
import Pricing from './Pricing'; // Import the Pricing component
import Footer from './Footer'; // Import the Footer component
import { Helmet } from 'react-helmet'; // Import Helmet from react-helmet
import SankeyDiagramExampleComponent from './SankeyDiagramExampleComponent';
import BarChartComponent from './BarChartComponent';
import ReadingTimeChartComponent from './ReadingTimeChartComponent';

const ContentPerformance = () => {
	const dummyReadingTimePercentage = 75;
  const images = {
    image0: require('./images/product-overview.gif'),
  };

  const dummyNextUrls = [
    { next_url: '/about', sessions: 30, percentage: 20 },
    { next_url: '/contact', sessions: 50, percentage: 30 },
  ];

  const dummyTopReferringUrls = [
    { referring_url: 'google.com', sessions: 100 },
    { referring_url: 'facebook.com', sessions: 50 },
  ];

  const dummyTopSourceMediums = [
    { source_medium: '(direct) / (none)', sessions: 200 },
    { source_medium: 'email / newsletter', sessions: 100 },
  ];

  const dummyTotalSessions = 500;
  
  const dummyEngagementData = [
    { date: '2024-10-01', engagement_time: 60, sessions: 100, engagement_rate: 0.8 },
    { date: '2024-10-02', engagement_time: 75, sessions: 120, engagement_rate: 0.85 },
    { date: '2024-10-03', engagement_time: 50, sessions: 90, engagement_rate: 0.75 },
    { date: '2024-10-04', engagement_time: 65, sessions: 110, engagement_rate: 0.82 },
    { date: '2024-10-05', engagement_time: 80, sessions: 130, engagement_rate: 0.88 },
    { date: '2024-10-06', engagement_time: 55, sessions: 95, engagement_rate: 0.78 },
    { date: '2024-10-07', engagement_time: 70, sessions: 115, engagement_rate: 0.84 },
    { date: '2024-10-08', engagement_time: 68, sessions: 105, engagement_rate: 0.81 },
    { date: '2024-10-09', engagement_time: 72, sessions: 125, engagement_rate: 0.86 },
    { date: '2024-10-10', engagement_time: 58, sessions: 102, engagement_rate: 0.79 },
    { date: '2024-10-11', engagement_time: 63, sessions: 107, engagement_rate: 0.83 },
    { date: '2024-10-12', engagement_time: 77, sessions: 135, engagement_rate: 0.89 },
    { date: '2024-10-13', engagement_time: 62, sessions: 112, engagement_rate: 0.8 },
    { date: '2024-10-14', engagement_time: 55, sessions: 100, engagement_rate: 0.76 },
    { date: '2024-10-15', engagement_time: 66, sessions: 118, engagement_rate: 0.85 },
    { date: '2024-10-16', engagement_time: 73, sessions: 127, engagement_rate: 0.87 },
    { date: '2024-10-17', engagement_time: 59, sessions: 109, engagement_rate: 0.8 },
    { date: '2024-10-18', engagement_time: 64, sessions: 120, engagement_rate: 0.84 },
    { date: '2024-10-19', engagement_time: 78, sessions: 138, engagement_rate: 0.9 },
    { date: '2024-10-20', engagement_time: 67, sessions: 115, engagement_rate: 0.86 },
    { date: '2024-10-21', engagement_time: 70, sessions: 130, engagement_rate: 0.88 },
    { date: '2024-10-22', engagement_time: 60, sessions: 105, engagement_rate: 0.81 },
    { date: '2024-10-23', engagement_time: 74, sessions: 133, engagement_rate: 0.89 },
    { date: '2024-10-24', engagement_time: 65, sessions: 118, engagement_rate: 0.82 },
    { date: '2024-10-25', engagement_time: 69, sessions: 125, engagement_rate: 0.87 },
    { date: '2024-10-26', engagement_time: 56, sessions: 102, engagement_rate: 0.78 },
    { date: '2024-10-27', engagement_time: 61, sessions: 112, engagement_rate: 0.8 },
    { date: '2024-10-28', engagement_time: 75, sessions: 130, engagement_rate: 0.88 },
    { date: '2024-10-29', engagement_time: 58, sessions: 99, engagement_rate: 0.79 },
    { date: '2024-10-30', engagement_time: 68, sessions: 122, engagement_rate: 0.85 },
  ];
  
  const dummyEngagementDataStepChange = [
      { date: '2024-10-01', engagement_time: 55, sessions: 100, engagement_rate: 0.78 },
      { date: '2024-10-02', engagement_time: 60, sessions: 120, engagement_rate: 0.80 },
      { date: '2024-10-03', engagement_time: 52, sessions: 90, engagement_rate: 0.75 },
      { date: '2024-10-04', engagement_time: 58, sessions: 110, engagement_rate: 0.79 },
      { date: '2024-10-05', engagement_time: 63, sessions: 130, engagement_rate: 0.82 },
      { date: '2024-10-06', engagement_time: 50, sessions: 95, engagement_rate: 0.76 },
      { date: '2024-10-07', engagement_time: 57, sessions: 115, engagement_rate: 0.78 },
      { date: '2024-10-08', engagement_time: 54, sessions: 105, engagement_rate: 0.77 },
      { date: '2024-10-09', engagement_time: 59, sessions: 125, engagement_rate: 0.81 },
      { date: '2024-10-10', engagement_time: 53, sessions: 102, engagement_rate: 0.76 },
      { date: '2024-10-11', engagement_time: 55, sessions: 107, engagement_rate: 0.78 },
      { date: '2024-10-12', engagement_time: 62, sessions: 135, engagement_rate: 0.83 },
      { date: '2024-10-13', engagement_time: 57, sessions: 112, engagement_rate: 0.79 },
      { date: '2024-10-14', engagement_time: 52, sessions: 100, engagement_rate: 0.76 },
      { date: '2024-10-15', engagement_time: 60, sessions: 118, engagement_rate: 0.80 },
      // Step change occurs here
      { date: '2024-10-16', engagement_time: 80, sessions: 127, engagement_rate: 0.88 },
      { date: '2024-10-17', engagement_time: 78, sessions: 109, engagement_rate: 0.86 },
      { date: '2024-10-18', engagement_time: 83, sessions: 120, engagement_rate: 0.89 },
      { date: '2024-10-19', engagement_time: 85, sessions: 138, engagement_rate: 0.9 },
      { date: '2024-10-20', engagement_time: 82, sessions: 115, engagement_rate: 0.88 },
      { date: '2024-10-21', engagement_time: 87, sessions: 130, engagement_rate: 0.91 },
      { date: '2024-10-22', engagement_time: 79, sessions: 105, engagement_rate: 0.87 },
      { date: '2024-10-23', engagement_time: 85, sessions: 133, engagement_rate: 0.92 },
      { date: '2024-10-24', engagement_time: 81, sessions: 118, engagement_rate: 0.88 },
      { date: '2024-10-25', engagement_time: 83, sessions: 125, engagement_rate: 0.9 },
      { date: '2024-10-26', engagement_time: 76, sessions: 102, engagement_rate: 0.85 },
      { date: '2024-10-27', engagement_time: 80, sessions: 112, engagement_rate: 0.87 },
      { date: '2024-10-28', engagement_time: 88, sessions: 130, engagement_rate: 0.92 },
      { date: '2024-10-29', engagement_time: 74, sessions: 99, engagement_rate: 0.84 },
      { date: '2024-10-30', engagement_time: 82, sessions: 122, engagement_rate: 0.89 },
  ];
  
  
  const dummyInternalData = [
    { date: '2024-10-01', engagement_time: 28, sessions: 55, engagement_rate: 0.65 },
    { date: '2024-10-02', engagement_time: 32, sessions: 60, engagement_rate: 0.7 },
    { date: '2024-10-03', engagement_time: 27, sessions: 58, engagement_rate: 0.6 },
    { date: '2024-10-04', engagement_time: 31, sessions: 62, engagement_rate: 0.68 },
    { date: '2024-10-05', engagement_time: 35, sessions: 66, engagement_rate: 0.75 },
    { date: '2024-10-06', engagement_time: 29, sessions: 57, engagement_rate: 0.63 },
    { date: '2024-10-07', engagement_time: 34, sessions: 65, engagement_rate: 0.72 },
    { date: '2024-10-08', engagement_time: 30, sessions: 61, engagement_rate: 0.67 },
    { date: '2024-10-09', engagement_time: 33, sessions: 64, engagement_rate: 0.7 },
    { date: '2024-10-10', engagement_time: 29, sessions: 60, engagement_rate: 0.66 },
    { date: '2024-10-11', engagement_time: 32, sessions: 63, engagement_rate: 0.69 },
    { date: '2024-10-12', engagement_time: 36, sessions: 68, engagement_rate: 0.76 },
    { date: '2024-10-13', engagement_time: 31, sessions: 62, engagement_rate: 0.68 },
    { date: '2024-10-14', engagement_time: 28, sessions: 59, engagement_rate: 0.64 },
    { date: '2024-10-15', engagement_time: 34, sessions: 66, engagement_rate: 0.73 },
    { date: '2024-10-16', engagement_time: 37, sessions: 69, engagement_rate: 0.78 },
    { date: '2024-10-17', engagement_time: 30, sessions: 61, engagement_rate: 0.67 },
    { date: '2024-10-18', engagement_time: 33, sessions: 63, engagement_rate: 0.7 },
    { date: '2024-10-19', engagement_time: 38, sessions: 70, engagement_rate: 0.8 },
    { date: '2024-10-20', engagement_time: 35, sessions: 65, engagement_rate: 0.75 },
    { date: '2024-10-21', engagement_time: 34, sessions: 64, engagement_rate: 0.72 },
    { date: '2024-10-22', engagement_time: 30, sessions: 60, engagement_rate: 0.68 },
    { date: '2024-10-23', engagement_time: 36, sessions: 67, engagement_rate: 0.74 },
    { date: '2024-10-24', engagement_time: 33, sessions: 62, engagement_rate: 0.7 },
    { date: '2024-10-25', engagement_time: 35, sessions: 65, engagement_rate: 0.76 },
    { date: '2024-10-26', engagement_time: 28, sessions: 59, engagement_rate: 0.65 },
    { date: '2024-10-27', engagement_time: 30, sessions: 61, engagement_rate: 0.66 },
    { date: '2024-10-28', engagement_time: 37, sessions: 70, engagement_rate: 0.77 },
    { date: '2024-10-29', engagement_time: 29, sessions: 60, engagement_rate: 0.68 },
    { date: '2024-10-30', engagement_time: 33, sessions: 63, engagement_rate: 0.71 },
  ];

  const dummyOrganicData = [
    { date: '2024-10-01', engagement_time: 45, sessions: 80, engagement_rate: 0.78 },
    { date: '2024-10-02', engagement_time: 50, sessions: 85, engagement_rate: 0.82 },
    { date: '2024-10-03', engagement_time: 43, sessions: 78, engagement_rate: 0.76 },
    { date: '2024-10-04', engagement_time: 47, sessions: 82, engagement_rate: 0.79 },
    { date: '2024-10-05', engagement_time: 52, sessions: 87, engagement_rate: 0.83 },
    { date: '2024-10-06', engagement_time: 44, sessions: 79, engagement_rate: 0.77 },
    { date: '2024-10-07', engagement_time: 48, sessions: 83, engagement_rate: 0.8 },
    { date: '2024-10-08', engagement_time: 46, sessions: 81, engagement_rate: 0.79 },
    { date: '2024-10-09', engagement_time: 49, sessions: 84, engagement_rate: 0.81 },
    { date: '2024-10-10', engagement_time: 43, sessions: 78, engagement_rate: 0.75 },
    { date: '2024-10-11', engagement_time: 46, sessions: 82, engagement_rate: 0.79 },
    { date: '2024-10-12', engagement_time: 51, sessions: 86, engagement_rate: 0.84 },
    { date: '2024-10-13', engagement_time: 47, sessions: 83, engagement_rate: 0.8 },
    { date: '2024-10-14', engagement_time: 44, sessions: 80, engagement_rate: 0.76 },
    { date: '2024-10-15', engagement_time: 48, sessions: 85, engagement_rate: 0.82 },
    { date: '2024-10-16', engagement_time: 53, sessions: 88, engagement_rate: 0.85 },
    { date: '2024-10-17', engagement_time: 45, sessions: 81, engagement_rate: 0.78 },
    { date: '2024-10-18', engagement_time: 47, sessions: 83, engagement_rate: 0.8 },
    { date: '2024-10-19', engagement_time: 54, sessions: 89, engagement_rate: 0.87 },
    { date: '2024-10-20', engagement_time: 49, sessions: 84, engagement_rate: 0.81 },
    { date: '2024-10-21', engagement_time: 50, sessions: 85, engagement_rate: 0.83 },
    { date: '2024-10-22', engagement_time: 44, sessions: 79, engagement_rate: 0.77 },
    { date: '2024-10-23', engagement_time: 52, sessions: 86, engagement_rate: 0.84 },
    { date: '2024-10-24', engagement_time: 47, sessions: 82, engagement_rate: 0.8 },
    { date: '2024-10-25', engagement_time: 49, sessions: 85, engagement_rate: 0.83 },
    { date: '2024-10-26', engagement_time: 43, sessions: 78, engagement_rate: 0.75 },
    { date: '2024-10-27', engagement_time: 45, sessions: 80, engagement_rate: 0.77 },
    { date: '2024-10-28', engagement_time: 53, sessions: 88, engagement_rate: 0.85 },
    { date: '2024-10-29', engagement_time: 44, sessions: 79, engagement_rate: 0.78 },
    { date: '2024-10-30', engagement_time: 47, sessions: 82, engagement_rate: 0.8 },
  ];
  

  return (
    <div className="flex flex-col min-h-screen text-black">
      <Helmet>
        <title>Measuring Content Performance with contentEnhance</title>
        <meta
          name="description"
          content="Learn how to measure and optimize your content performance using key engagement metrics with contentEnhance."
        />
      </Helmet>

      <TopBarMarketing />

      <div className="container mt-6">
        {/* What contentEnhance Section */}
        <section id="what-contentEnhance" className="breakout-block">
          <div className="why-section">
            <div className="why-block">
              <div className="why-text">
                <p className="text-sm text-gray-500 mb-6">
                  Level up your content analytics
                </p>
                <h1 className="text-6xl font-bold mb-6">
                  How to measure your content performance in 2024
                </h1>
                <p className="mb-6">
                  contentEnhance features new innovative ways to measure content
                  performance, including interactive journey flows, automated
                  performance change detection and measuring how much of a
                  page's content that people consume on average.
                </p>
                <p className="mb-6">
                  Includes improvement ideas briefed by your audience insight.
                  All you need to do is hook up some existing data sources to
                  get going.
                </p>
                <a href="/signup" className="button-link">
                  Sign up free
                </a>
              </div>

              <div className="bg-darkBlue w-full sm:w-[700px] h-[400px] mt-6">
                <SankeyDiagramExampleComponent
                  url="https://example.com"
                  nextUrls={dummyNextUrls}
                  topReferringUrls={dummyTopReferringUrls}
                  topSourceMediums={dummyTopSourceMediums}
                  totalSessions={dummyTotalSessions}
                />
              </div>
            </div>
          </div>
        </section>

        {/* Content Performance Section */}
        <section id="content-performance" className="breakout-block">
          <div className="performance-section">
            <div className="performance-text">
              <h2 className="text-3xl font-bold mb-6 mt-6">
                Key things to think about
              </h2>
              <p className="mb-6">
                Measuring content performance should aim to measure the influence
                your content has on your audience, in terms of the audience
                taking action that aligns with your goals.
              </p>
              <p className="mb-6">
                While metrics like page views and unique visitors provide
                insight into your content's reach, they obviously don't tell the
                full story in terms of how effective it is for actually
                resonating with your audience.
              </p>
              <p className="mb-6">
                Similarly, focusing on conversions resulting from views of your
                pages doesn't necessarily mean that a page has been influential
                within that audience's journey. A user may convert after viewing
                a page, but that doesn't necessarily mean the page itself was
                the key influencer in their decision. The user's overall journey
                and interactions with your brand across multiple touchpoints
                potentially played a more significant role.
              </p>
              <p className="mb-6">
                To gauge the performance of your content while providing
                actionable insight to improve it, you should try to keep things
                simple:
              </p>
              <ul className="list-disc ml-6 mb-8">
                <li>
                  Have they spent a reasonable amount of time digesting the
                  information?
                </li>
                <li>Has the user scrolled down the page?</li>
                <li>
                  Have they gone on to a further destination that shows intent to
                  hit one of your goals?
                </li>
              </ul>

              <h2 className="text-3xl font-bold mb-6 mt-6">Measuring engagement time</h2>
              <p className="mb-6">
                A key metric to track is how long users are spending on your
                content pages. Engagement Time, or the average time spent on a
                page, indicates whether your content is holding users' attention
                and providing value.
              </p>
              <p className="mb-6">
                Google Analytics 4 provides this metric out of the box, which is
                a benefit compared to the old Universal Analytics. In Universal
                Analytics, measuring time on page required setting up custom
                tracking, as the default 'Time on Page' metric was often
                inaccurate due to its reliance on pageviews.
              </p>
              <p className="mb-6">
                In contrast, GA4's 'average engagement time' metric uses events
                (like clicks, scrolls, and form submissions) to determine if a
                session is still active, providing a more reliable way to
                measure how long users are actually spending on your content.
              </p>
              <p className="mb-8">
                Therefore, "average engagement time" for each page can give some
                insight into its performance. Pages with higher-than-average
                engagement times are likely resonating well with your audience,
                while those with lower times may need improvement.
              </p>
													            <div className="bg-darkBlue h-[450px] w-[100%] pt-4 pb-8 pl-4 pr-4">
																<div className="bg-darkBlue h-[280px] w-[100%] text-white">
													                <p className="text-base mb-4 mt-8 text-center">Example engagement time trend graph</p>
                  <BarChartComponent
                    engagementData={dummyEngagementData}
				organicData={dummyOrganicData}
				  internalData={dummyInternalData}
                    completedTasks={[]} // Replace with actual tasks data if any
                  />
                </div>
					</div>

              <h2 className="text-3xl font-bold mb-6 mt-6">Measuring scrolling behavior</h2>
              <p className="mb-6">
                Measuring scroll depth is a great way to understand how much of
                your content users are actually consuming. By tracking scroll
                depth, you can see what percentage of visitors make it to
                different parts of your page. This gives you insight into whether
                your content is engaging enough to keep people reading or if they
                are quickly bouncing.
              </p>
              <p className="mb-6">
                One summary metric that you can use out of the box from Google
                Analytics 4 (GA4) is Engagement Rate. Engagement Rate measures
                the percentage of sessions that were "engaged sessions". An
                engaged session is one that lasts longer than 10 seconds, has a
                conversion event, or has at least 2 pageviews or screenviews.
              </p>
              <p className="mb-6">
                By default, a page scroll is a conversion event in GA4, so if a
                user scrolls, this counts as an "engaged session". Therefore, the
                higher the engagement rate for your page, the higher the
                proportion of users embarking on some kind of journey on the
                page without bouncing straight off. This is a useful starting
                point for understanding if your content is meeting the
                expectation in terms of where it was linked from or found via
                search.
              </p>
              <p className="mb-6">
                Scrolling journeys can also be tracked in more detail by setting
                up scroll depth values in Google Tag Manager. This allows you to
                track what percentage of users scroll 25%, 50%, 75%, and 100%
                of the way down your pages.
              </p>
              <p className="mb-6">
                However, you will need to set this up in Google Tag Manager to
                get more granular data on scroll behavior. Here's how:
              </p>
              <ul className="list-disc ml-6 mb-6">
                <li>
                  Create a new trigger of type 'Scroll Depth' in Google Tag
                  Manager.
                </li>
                <li>
                  Configure the trigger to fire at the desired scroll
                  percentages, such as 25%, 50%, 75%, and 100%.
                </li>
                <li>
                  Create a new Google Analytics 4 event tag for each scroll
                  depth percentage.
                </li>
                <li>
                  Set the event name to something like 'scroll25percent',
                  'scroll50percent', etc.
                </li>
                <li>
                  Under 'Triggering', select the corresponding Scroll Depth
                  trigger you created.
                </li>
              </ul>
              <p className="mb-6">
                Publish your container, and you will start seeing the scroll depth
                events flow through to GA4. With this more detailed scroll data,
                you can get a clearer picture of how users are engaging with your
                content beyond the Engagement Rate metric.
              </p>
              <p className="mb-6">
                For example, you may find that most users scroll 50% of the way
                through your articles, but very few make it to 100%.
              </p>

              <h2 className="text-3xl font-bold mb-6 mt-6">Percentage of content consumed</h2>
              <p className="mb-6">
                However, for those of you that are unable to add scroll depth to
                Google Tag Manager—or if you want to use a single, simpler metric—
                you can use the percentage of content consumed. This is essentially
                the average engagement time of the page according to Google
                Analytics 4 (as outlined above) as a percentage of the estimated
                reading time for the page.
              </p>
              <p className="mb-6">
                To measure this, you need to estimate how long it takes to consume
                the content on the whole. Reading time is a reasonable proxy for
                this—although it isn't perfect if the page contains video content,
                for example. If you can extract the duration of videos featured on
                the page, you can combine these with the reading time to create a
                fuller total consumption time to measure against.
              </p>
              <p className="mb-8">
                Tools like contentEnhance help calculate this, by factoring in both
                reading time and video durations.
              </p>
<div className="bg-darkBlue h-[450px] w-[100%] pt-4 pb-8 pl-4 pr-4 flex justify-center items-center">
            <ReadingTimeChartComponent percentage={dummyReadingTimePercentage} />
          </div>
				

              <h2 className="text-3xl font-bold mb-6 mt-6">A/B testing</h2>
              <p className="mb-6">
                A/B testing allows you to definitively prove which changes have
                the biggest impact and is an important part of content
                optimization. With A/B testing, you create two versions of a piece
                of content—version A (the control) and version B (the variation
                with the change you want to test). Half of your traffic is shown
                version A, and half is shown version B. By measuring the
                difference in engagement metrics between the two versions, you
                can determine whether the change had a statistically significant
                impact.
              </p>
              <p className="mb-6">
                However, A/B testing is difficult and not always accessible. It
                requires significant traffic to each variation to reach
                statistical significance, and the technical setup can be complex.
                This puts it out of reach for many content teams, especially those
                working on lower-traffic pages or lacking dedicated development
                resources.
              </p>
              <p className="mb-8">
                contentEnhance allows you to take an alternative approach. It uses
                a statistical approach called Pettitt's test to detect significant
                changes in your content performance metrics over time, without
                the need for a separate control group.
              </p>
													            <div className="bg-darkBlue h-[450px] w-[100%] pt-4 pb-8 pl-4 pr-4">
																<div className="bg-darkBlue h-[280px] w-[100%] text-white">
													                <p className="text-base mb-4 mt-8 text-center">Example engagement time trend graph with change detection</p>
                  <BarChartComponent
                    engagementData={dummyEngagementDataStepChange}
					organicData={dummyOrganicData}
					  internalData={dummyInternalData}
                    completedTasks={[]} // Replace with actual tasks data if any
                  />
                </div>
					</div>

              <h2 className="text-3xl font-bold mb-6 mt-6">Considering journey flow</h2>
              <p className="mb-6">
                Finally, while engagement metrics are important, to get a full
                picture of content performance, you also need to consider the user
                journey. What matters is not just how many people are viewing a
                page or how long they stay, but what action they take next.
              </p>
              <p className="mb-6">
                In contentEnhance, you can use the URL journey mapper to visualize
                the typical paths users take to and from each content piece. This
                shows you which pages are acting as effective entry points and
                which ones are driving users towards key conversion pages. See the
                following example:
              </p>
              <div className="bg-darkBlue w-full sm:w-[700px] h-[400px] mb-6 justify-center">
                <SankeyDiagramExampleComponent
                  url="https://example.com"
                  nextUrls={dummyNextUrls}
                  topReferringUrls={dummyTopReferringUrls}
                  topSourceMediums={dummyTopSourceMediums}
                  totalSessions={dummyTotalSessions}
                />
              </div>
              <p className="mb-8">
                By combining engagement data with user flow insights, contentEnhance
                gives you a comprehensive view of how your content is performing
                and where the biggest opportunities lie for improvement.
              </p>

              <h2 className="text-3xl font-bold mb-6 mt-6">Putting it all together</h2>
              <p className="mb-6">
                To effectively measure content performance, focus on a combination
                of key metrics:
              </p>
              <ul className="list-disc ml-6 mb-6">
                <li>
                  <strong>Engagement Time:</strong> The average time users spend
                  on a page, indicating how well the content holds their attention.
                </li>
                <li>
                  <strong>Engagement Rate:</strong> The percentage of sessions
                  that are "engaged"—lasting longer than 10 seconds, having a
                  conversion event, or having multiple page/screen views.
                </li>
                <li>
                  <strong>Scroll Depth:</strong> The percentage of users who
                  scroll to different parts of the page (25%, 50%, 75%, 100%).
                </li>
                <li>
                  <strong>Percentage of Content Consumed:</strong> The average
                  engagement time as a percentage of the estimated total
                  consumption time (reading time + video duration).
                </li>
                <li>
                  <strong>User Journey:</strong> The paths users take to and from
                  each piece of content.
                </li>
              </ul>
              <p className="mb-8">
                By continuously tracking these metrics and using tools like
                contentEnhance to get actionable optimization suggestions, you can
                take a data-driven approach to improving your content's performance
                and ensure your content is meeting both user needs and search
                engine expectations.
              </p>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section id="pricing" className="pricing-section">
          <Pricing />
        </section>

        {/* Footer */}
        <Footer />
      </div>
    </div>
  );
};

export default ContentPerformance;
