import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import GoogleOAuthButtonManage from './GoogleOAuthButton2';
import symbol from './contentenhance-new-project.png';

const ManageOrganization = () => {
    const navigate = useNavigate();
    const { organizationId } = useParams(); // Using route parameters to fetch organizationId
    const [organizationSettings, setOrganizationSettings] = useState({});
    const [userRole, setUserRole] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [organizationName, setOrganizationName] = useState('');
    const [originalOrganizationName, setOriginalOrganizationName] = useState(''); // State to hold the original name for comparison or reset
    const [errorMessage, setErrorMessage] = useState('');
    const [file, setFile] = useState(null); // State to hold the selected file
	const [refreshDate, setRefreshDate] = useState('');
	

    const location = useLocation();
	
    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
                if (!response.data.isAuthenticated) {
                    navigate('/login'); // Redirect to login if not authenticated
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                navigate('/login'); // Redirect to login on error
            }
        };

        checkAuthentication();
    }, [navigate, organizationId]);


	useEffect(() => {
	    const fetchOrganization = async () => {
	        try {
	            const response = await axios.get(`https://api.contentenhance.com/api/organizations/${organizationId}`);
	            if (response.data) {
                    setOrganizationName(response.data.organization_name);
                    setOriginalOrganizationName(response.data.organization_name); // Save the original name
                    setOrganizationSettings(response.data); // Save the organization settings
					
					
                    const updatedDate = new Date(response.data.organization_plan_updated);
                    const nextRefreshDate = new Date(updatedDate);
                    nextRefreshDate.setDate(updatedDate.getDate() + 30);
                    setRefreshDate(nextRefreshDate.toISOString().split('T')[0]);

	            }
	        } catch (error) {
	            console.error('Failed to fetch organization details:', error);
	        }
	    };
	    fetchOrganization();
	}, [organizationId]);


    useEffect(() => {
        const fetchUserRoleForOrganization = async () => {
            try {
                const response = await axios.get(`https://api.contentenhance.com/api/organizations/${organizationId}/check_role`, { withCredentials: true });
                if (response.status === 200) {
                    setUserRole(response.data.role); // Handle successful response
                } else {
                    console.error('Unexpected status code:', response.status);
                    // Handle other statuses explicitly if needed
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    // Check if the error is due to a 401 Unauthorized response
                    if (error.response && error.response.status === 401) {
                        navigate('/login'); // Redirect to login page
                    } else if (error.response && error.response.status === 403) {
                        console.error('Error:', error.message);
                        setErrorMessage('You do not have permission to access this resource.');
                    } else {
                        console.error('Error fetching user role for organization:', error.message);
                    }
                } else {
                    // Handle non-Axios errors
                    console.error('An unexpected error occurred:', error);
                }
            }
        };

        fetchUserRoleForOrganization();
    }, [organizationId, navigate]); // Depend on organizationId and navigate

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('modal') === 'open') {
            setShowModal(true);
        }
    }, [location]);

    const handleDeleteOrganization = async () => {
        if (userRole !== 'admin') {
            alert('You do not have permission to delete this organization.');
            return;
        }
        const isConfirmed = window.confirm("Are you sure you want to delete this organization? This action cannot be undone.");

        if (isConfirmed) {
            try {
                const response = await axios.delete(`https://api.contentenhance.com/delete_organization/${organizationId}`);
                if (response.status === 200) {
                    // Redirect to a safe page after deletion, such as the organizations list
                    window.location.href = "/organizations";
                } else {
                    throw new Error('Failed to delete the organization.');
                }
            } catch (error) {
                console.error('Error:', error);
                alert('An error occurred while deleting the organization.');
            }
        }
    };

	const handleSubmit = async () => {
	    if (userRole !== 'admin') {
	        alert('You do not have permission to change the organization name.');
	        return;
	    }
	    if (organizationName !== originalOrganizationName) {
	        try {
	            const response = await axios.post(`https://api.contentenhance.com/api/update_organization/${organizationId}`, {
	                organization_name: organizationName
	            });
	            console.log('Organization name updated:', response.data);
	            alert('Organization name updated successfully');
	        } catch (error) {
	            console.error('Error updating organization name:', error);
	            alert('Error updating organization name. Please try again later.');
	        }
	    } else {
	        alert('The new organization name is the same as the current one.');
	    }
	};


    const toggleModal = () => {
        if (userRole !== 'admin') {
            alert('You do not have permission to perform this action.');
            return;
        }
        setErrorMessage('');
        setShowModal(!showModal);
    };

    const handleNameChange = (event) => {
        setOrganizationName(event.target.value);
    };

	const handleFileChange = (event) => {
	        const file = event.target.files[0];
	        if (file) {
	            if (file.size > 2 * 1024 * 1024) { // 2 MB
	                setErrorMessage('File size must be less than 2 MB');
	                return;
	            }

	            const img = new Image();
	            img.onload = () => {
	                if (img.width > 500 || img.height > 500 || img.width < 100 || img.height < 100) {
	                    setErrorMessage('Image dimensions must be between 100x100 and 500x500 pixels');
	                } else {
	                    setFile(file);
	                    setErrorMessage('');
	                }
	            };
	            img.onerror = () => {
	                setErrorMessage('Invalid image file');
	            };
	            img.src = URL.createObjectURL(file);
	        }
	    };

		const handleUpload = async () => {
		    if (userRole !== 'admin') {
		        alert('You do not have permission to upload an image.');
		        return;
		    }
		    const formData = new FormData();
		    formData.append('file', file);

		    try {
		        const response = await axios.post(`https://api.contentenhance.com/api/upload_organization_logo/${organizationId}`, formData, {
		            headers: {
		                'Content-Type': 'multipart/form-data'
		            }
		        });
		        console.log('Logo uploaded:', response.data);
				alert('Logo uploaded successfully'); // Display success message
		        toggleModal();
		        // Refresh organization details to get the new logo URL
		        const orgResponse = await axios.get(`https://api.contentenhance.com/api/organizations/${organizationId}`);
		        if (orgResponse.data) {
		            setOrganizationSettings(orgResponse.data); // Update the organization settings
		        }
		    } catch (error) {
		        console.error('Error uploading logo:', error);
		        if (error.response && error.response.data && error.response.data.error) {
		            setErrorMessage(error.response.data.error); // Display specific error message from the endpoint
		        } else {
		            setErrorMessage('Failed to upload logo.'); // Display generic error message
		        }
		    }
		};
    return (
        <div className="flex flex-col h-screen bg-darkBlue">
            <TopBar />
            <div className="flex h-screen bg-lighterDarkBlue text-white">
                <Sidebar />
                <div className="flex-1 bg-gray-850 p-10 overflow-auto">
                    <h1 className="text-white text-base mb-8">Organization settings</h1>
<div className="bg-gray-900 mb-8 p-4">
                        <h2 className="text-white text-base mb-2">Organization plan overview</h2>
                        <p className="text-white text-sm">Plan: {organizationSettings.plan}</p>
    <p className="text-white text-sm">
        Next refresh date: {refreshDate}
    </p>
                        <p className="text-white text-sm">Refreshes left: {organizationSettings.number_of_refreshes}</p>
                        <p className="text-white text-sm">Swaps left: {organizationSettings.number_of_swaps}</p>
                        <p className="text-white text-sm mb-2">Generates left: {organizationSettings.number_of_generates}</p>
        <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded text-sm" onClick={() => { window.location.href = 'https://www.contentenhance.com/pricing'; }}>
            Upgrade
        </button>
                    </div>
                    <div className="bg-gray-900 mb-8 p-4">
                        <h2 className="text-white text-base mb-2">Rename organization</h2>
                        <input
                            type="text"
                            value={organizationName}
                            onChange={handleNameChange}
                            className="text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900 h-[35px]"
                        />
                        <button onClick={handleSubmit} className="p-2 rounded text-sm h-[35px] text-white bg-blue-500 hover:bg-blue-900 ml-2">
                            Save changes
                        </button>
                    </div>

                    <div className="bg-gray-900 mb-8 p-4">
                        <h2 className="text-white text-base mb-2">Change organization image</h2>
							{organizationSettings.logo_url && (
							                            <img src={organizationSettings.logo_url} alt="Organization Logo" className="mb-4 max-w-xs" />
							                        )}
                        <button onClick={                            toggleModal}
                            className="p-2 rounded text-sm h-[35px] text-white bg-blue-500 hover:bg-blue-900 mt-4"
                        >
                            Upload image
                        </button>
                    </div>

                    <div className="bg-gray-900 mb-8 p-4">
                        <h2 className="text-white text-base mb-2">Delete organization</h2>
                        <p className="text-white text-sm mb-2">Only delete this organization if you are sure you will not require any of the data in the future.</p>
                        <button className="mt-4 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded text-sm" onClick={handleDeleteOrganization}>
                            Delete
                        </button>
                    </div>

                    {showModal && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-gray-900 p-8 rounded-lg shadow-lg">
                                <h2 className="text-white text-base mb-4">Upload organization image</h2>
                                <input type="file" onChange={handleFileChange} className="text-white text-sm" />
                                <div className="flex mt-4">
                                    <button onClick={handleUpload} className="p-2 rounded text-sm h-[35px] text-white bg-blue-500 hover:bg-blue-900 mr-2">
                                        Upload
                                    </button>
                                    <button onClick={toggleModal} className="p-2 rounded text-sm h-[35px] text-white bg-gray-500 hover:bg-gray-700">
                                        Cancel
                                    </button>
                                </div>
                                {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};



export default ManageOrganization;

