import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams
import { IconButton, TextField, Button, Select, MenuItem, InputLabel, FormControl, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import SendIcon from '@mui/icons-material/Send';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import AddCircleIcon from '@mui/icons-material/AddCircle'; // Add URLs

const AddUserProject = () => {
    const { projectId } = useParams();
    const [email, setEmail] = useState('');
	const [userRole, setUserRole] = useState('');
    const [role, setRole] = useState('admin');
    const [message, setMessage] = useState('');
    const [users, setUsers] = useState([]);
	const [selectedUserIds, setSelectedUserIds] = useState(new Set());
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
	const navigate = useNavigate(); // Hook to programmatically navigate
	const [errorMessage, setErrorMessage] = useState(''); 
	
	useEffect(() => {
	    const checkAuthentication = async () => {
	      try {
	        const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
	        if (!response.data.isAuthenticated) {
	          navigate('/login'); // Redirect to login if not authenticated
	        }
	      } catch (error) {
	        console.error('Authentication check failed:', error);
	        navigate('/login'); // Redirect to login on error
	      }
	    };

	    checkAuthentication();
	  }, [navigate]);
	
	
	const isAnyUserSelected = selectedUserIds.size > 0;
	
	const handleCheckboxChange = (userId) => {
	    setSelectedUserIds(prevSelectedUserIds => {
	        const newSelectedUserIds = new Set(prevSelectedUserIds);
	        if (newSelectedUserIds.has(userId)) {
	            newSelectedUserIds.delete(userId);
	        } else {
	            newSelectedUserIds.add(userId);
	        }
	        return newSelectedUserIds;
	    });
	};
	
	
	const handleSubmitDelete = async () => {
	    if (userRole !== 'admin') {
	        alert("You don't have permission to remove users.");
	        return;
	    }
	    try {
	        await Promise.all(Array.from(selectedUserIds).map(userId =>
	            axios.post(`https://api.contentenhance.com/api/projects/${projectId}/remove_user`, { user_id: userId })
	        ));

	        // Clear selected users and fetch updated user list
	        setSelectedUserIds(new Set());
	        fetchUsers();
	    } catch (error) {
	        console.error('Error removing users:', error);
	    }
	};

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/users`);
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
	
	useEffect(() => {
	    const fetchUserRole = async () => {
	        try {
	            const response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/check_role`, { withCredentials: true });
	            setUserRole(response.data.role);
	        } catch (error) {
	            console.error('Error fetching URL data:', error);
	            if (error.response) {
	                if (error.response.status === 401) {
	                    // Redirect to login if unauthorized
	                    navigate('/login');
	                } else if (error.response.status === 403) {
	                    console.error('Forbidden access:', error.response.data.error);
	                    // Update UI to inform the user
	                    setErrorMessage('You do not have permission to access this resource.');
	                }
	            }
	        }
	    };

	    fetchUserRole();
	}, [projectId, navigate]); // Assuming navigate and setErrorMessage are defined and correct dependencies
	

    useEffect(() => {
        fetchUsers();
    }, [projectId]);
	const handleRoleChangeWrapper = (userId) => (newRole) => {
	    handleRoleChange(userId, newRole);
	};
	
	const handleRoleChange = async (userId, newRole) => {
	    if (userRole !== 'admin') {
	        alert("You don't have permission to change user roles.");
	        return;
	    }
	    try {
	        const response = await axios.post('https://api.contentenhance.com/api/update_user_project_role', {
	            user_id: userId,
	            projectId: projectId,
	            role: newRole,
	        });

	        // Update the user's role in the local state
	        const updatedUsers = users.map(user => {
	            if (user.user_id === userId) {
	                return { ...user, role: newRole };
	            }
	            return user;
	        });
	        setUsers(updatedUsers);
	    } catch (error) {
	        console.error('Error updating user role:', error);
	    }
	};
	

	const handleSubmit = async (e) => {
	    e.preventDefault();
	    if (userRole !== 'admin') {
	        alert("You don't have permission to add users.");
	        return;
	    }
	    try {
	        const response = await axios.post('https://api.contentenhance.com/api/associate_user_to_project', {
	            email,
	            project_id: projectId,
	            role
	        });
	        setMessage(response.data.message);
	        setEmail('');
	        setRole('');
	        fetchUsers();
	        setShowModal(false); // Close modal after form submission
	    } catch (error) {
	        setMessage(error.response ? error.response.data.error : 'Server error');
	        setShowModal(false); // Close modal even on error
	    }
	};

    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const oddRowStyle = {
            backgroundColor: '#191A23'// Light gray background for odd rows
       };
       const evenRowStyle = {
           backgroundColor: 'rgb(18 24 39 / var(--tw-bg-opacity))' // White background for even rows
       };
	   const styles = {
   		selectInput: {
   		    backgroundColor: 'transparent', // Make the main element background transparent
   		    color: '#ffffff',
   		    padding: '10px',
   		    borderRadius: '5px',
   		    appearance: 'none',
   		    position: 'relative', // Needed for custom dropdown arrow
   		  },

   		  // New styles for dropdown
   		  selectDropdown: {
   		    '&::-webkit-listbox': {
   		      backgroundColor: '#191A23',
   		      border: '1px solid #ffffff',
   		      borderRadius: '5px',
   		      padding: '5px',
   		    },
   		    '& option': {
   		      backgroundColor: '#191A23',
   		      color: '#ffffff',
   		      padding: '5px',
   		    },
   		    '& option:hover': {
   		      backgroundColor: '#2a2b36',
   		    },
   		  },
	};
	   
    return (
<div className="flex flex-col h-screen bg-darkBlue">
    <TopBar />
    <div className="flex h-screen bg-lighterDarkBlue text-white">
        <Sidebar />
        <div className="flex-1 bg-gray-850 p-10 overflow-auto">
            <h1 className="text-white text-base mb-8">Add project member</h1>

            {/* Button to trigger modal */}
            <div className="flex justify-center">
		<button
		    className="px-6 py-2 bg-blue-600 text-white text-sm rounded hover:bg-blue-900 flex items-center space-x-1"
		    onClick={() => {
		        if (userRole !== 'admin') {
		            alert("You don't have permission to add users.");
		            return;
		        }
		        toggleModal();
		    }}
		>
		    <AddCircleIcon sx={{ color: '#ffffff', mr: 1 }} fontSize="small" />
		    Add User
		</button>
            </div>

            {/* Modal */}
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="w-full max-w-md p-4 space-y-2 rounded-lg bg-gray-900 shadow-2xl" style={{ maxWidth: '600px' }}>
                        <button onClick={toggleModal} className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800 text-3xl p-2">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h2 className="text-base text-gray-200 text-center">Add User</h2>
                        <div className="relative flex py-4 items-center">
                            <div className="flex-grow border-t border-gray-700"></div>
                        </div>

                        <form onSubmit={handleSubmit}>
                            {/* Email Address Input */}
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-200">Email Address</label>
                                <input
                                    autoFocus
                                    id="email"
                                    type="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="appearance-none text-sm w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-700"
                                    disabled={userRole !== 'admin'}
                                />
                            </div>

                            {/* Role Selection */}
                            <div className="mb-4">
                                <label htmlFor="role-select" className="block text-sm font-medium text-gray-200 text-sm">Role</label>
                                <select
                                    id="role-select"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    className="appearance-none w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-700 text-sm"
                                    disabled={userRole !== 'admin'}
                                >
                                    <option value="admin">Admin</option>
                                    <option value="editor">Editor</option>
                                    <option value="viewer">Viewer</option>
                                </select>
                            </div>
                            <div className="flex items-center justify-end space-x-2">
                                <button onClick={toggleModal} className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-200 bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500">
                                    Cancel
                                </button>
                                <button type="submit" className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* User Table */}
            <div className="p-4 rounded">
                <table className="min-w-full">
                    <thead className="bg-blue-900">
                        <tr>
                            <th className="text-center text-sm bg-blue-900 text-white font-normal p-4">Email</th>
                            <th className="text-center text-sm bg-blue-900 text-white font-normal p-4">Role</th>
                            <th className="text-center text-sm bg-blue-900 text-white font-normal p-4">Remove from project</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={user.user_id} style={index % 2 === 0 ? evenRowStyle : oddRowStyle}>
                                <td className="text-sm text-center p-4">{user.email}</td>
                                <td className="text-sm text-center p-4">
                                    <select
                                        style={styles.selectInput}
                                        value={user.role}
                                        onChange={(e) => handleRoleChangeWrapper(user.user_id)(e.target.value)}
                                        
                                    >
                                        <option value="admin">Admin</option>
                                        <option value="editor">Editor</option>
                                        <option value="viewer">Viewer</option>
                                    </select>
                                </td>
                                <td className="text-sm text-center p-4">
                                    <div className="text-center pr-4 pl-4 text-sm">
                                        <input
                                            type="checkbox"
                                            checked={selectedUserIds.has(user.user_id)}
                                            onChange={() => handleCheckboxChange(user.user_id)}
                                            
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Message display */}
            {message && <div className="text-center my-4 p-2 bg-gray-700 rounded text-sm">{message}</div>}
        </div>
    </div>

    {isAnyUserSelected && (
        <div className="fixed bottom-0 left-0 w-full py-2 px-4 bg-blue-900">
            <div className="flex justify-end">
                <button
                    className="bg-white text-sm text-blue-900 py-2 px-4 rounded mr-4"
                    onClick={handleSubmitDelete}
                >
                    Remove users
                </button>
            </div>
        </div>
    )}
</div>
        
    );
};

export default AddUserProject;