import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css'; // Import the main CSS file

const Footer = () => {
  return (
    <footer className="text-black py-6 text-sm">
      <div className="footer-container mx-auto px-4">
        <div className="footer-flex flex-wrap justify-center">
          <div className="footer-column footer-mb-6 footer-md-mb-0">
            <div className="content-wrapper">
              <h3 className="footer-h3 text-lg font-semibold mb-2">Product</h3>
              <ul className="footer-ul">
                <li className="footer-li mb-2">
                  <a href="#how-it-works" className="footer-a hover:underline">How it works</a>
                </li>
                <li>
                  <a href="#pricing" className="footer-a hover:underline">Pricing</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-column footer-mb-6 footer-md-mb-0">
            <div className="content-wrapper">
              <h3 className="footer-h3 text-lg font-semibold mb-2">Legal</h3>
              <ul className="footer-ul">
                <li className="footer-li mb-2">
                  <a href="https://www.contentenhance.com/terms-of-service" className="footer-a hover:underline">Terms</a>
                </li>
                <li>
                  <a href="https://www.contentenhance.com/privacy-policy" className="footer-a hover:underline">Privacy policy</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-column">
            <div className="content-wrapper">
              <h3 className="footer-h3 text-lg font-semibold mb-2">Social</h3>
              <ul className="footer-ul">
                <li>
                  <a href="https://x.com/contentEnhance" className="footer-a hover:underline">X</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
