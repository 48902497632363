import { useEffect } from 'react';
import axios from 'axios';
import { useTaskStatus } from './TaskStatusContext';

export const usePollTask = (taskIds) => {
    const { taskStatuses, completeTask } = useTaskStatus();

	useEffect(() => {
	    let isMounted = true; // Flag to track component mount status

	    const checkTaskStatus = async (taskId) => {
	        if (!isMounted || taskStatuses[taskId] === 'complete') return;

	        try {
	            const response = await axios.get(`https://api.contentenhance.com/api/task-status/${taskId}`);
	            const { status } = response.data;

	            if (status === 'SUCCESS' && isMounted) {
	                completeTask(taskId);
	            } else {
	                // Re-poll after a delay if the task is not yet complete
	                setTimeout(() => {
	                    if (isMounted) checkTaskStatus(taskId);
	                }, 5000);
	            }
	        } catch (error) {
	            console.error(`Error polling task status for ${taskId}:`, error);
	        }
	    };

	    taskIds.forEach((taskId) => {
	        checkTaskStatus(taskId);
	    });

	    return () => {
	        isMounted = false; // Set the flag to false when the component unmounts
	    };
	}, [taskIds, taskStatuses, completeTask]);
    
};
