import React from 'react';

const IssueResolutionModal = ({ isOpen, onClose, selectedIssue, onIssueResolved, navigate, projectId }) => {
    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (e.target.id === 'modal-overlay') {
            onClose(); // Close the modal if the overlay is clicked
        }
    };
    const handleProgressClick = () => {
           navigate(`/projects/${projectId}`);
       };
	

    return (
        <div id="modal-overlay" className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={handleOverlayClick}>
            <div className="w-full max-w-3xl p-10 space-y-6 rounded-lg bg-gray-900 shadow-2xl" onClick={(e) => e.stopPropagation()}>
                {/* Conditionally render content based on selectedIssue */}
                {selectedIssue === 'ai_blocked' && (
                    <div className="text-white text-sm text-center">
                        <p>Please whitelist the following static IP addresses with your CDN or hosting provider to resolve the bot issue:</p>
                        <p className="font-bold mt-4">52.16.121.13</p>
				        <p className="font-bold">54.154.218.66</p>  {/* Example IP, replace with the actual IP address */}
                    </div>
                )}
                {selectedIssue === 'there_is_another_issue' && (
                    <div className="text-white text-sm text-center">
                        <p>Get in contact with the image in the screenshot and describe what the issue is.</p>
                        {/* Example IP, replace with the actual IP address */}
                    </div>
                )}
                <div className="flex justify-center mt-4">
            <button
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded text-sm mr-2"
                onClick={onIssueResolved}
            >
                Issue has been resolved
            </button>
            <button
                className="mt-4 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded text-sm mr-2"
                onClick={handleProgressClick}
            >
                Progress to next step anyway
            </button>
            
					<button
                        className="mt-4 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded text-sm"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default IssueResolutionModal;
