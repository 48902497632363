// TaskStatusContext.js
import React, { createContext, useContext, useState } from 'react';

const TaskStatusContext = createContext();

export const useTaskStatus = () => useContext(TaskStatusContext);

export const TaskStatusProvider = ({ children }) => {
    const [taskStatuses, setTaskStatuses] = useState({}); // {taskId: 'pending', ...}

    const startTask = (taskId) => setTaskStatuses((prev) => ({ ...prev, [taskId]: 'pending' }));
    const completeTask = (taskId) => setTaskStatuses((prev) => ({ ...prev, [taskId]: 'complete' }));

    return (
        <TaskStatusContext.Provider value={{ taskStatuses, startTask, completeTask }}>
            {children}
        </TaskStatusContext.Provider>
    );
};
