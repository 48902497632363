import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import axios from 'axios';

const DeleteProject = () => {
	const { projectId, urlId } = useParams();
	const [userRole, setUserRole] = useState('');
	const navigate = useNavigate(); // Hook to programmatically navigate
	const [errorMessage, setErrorMessage] = useState(''); 
	
	useEffect(() => {
	    const checkAuthentication = async () => {
	      try {
	        const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
	        if (!response.data.isAuthenticated) {
	          navigate('/login'); // Redirect to login if not authenticated
	        }
	      } catch (error) {
	        console.error('Authentication check failed:', error);
	        navigate('/login'); // Redirect to login on error
	      }
	    };

	    checkAuthentication();
	  }, [navigate]);
	
    const handleDeleteProject = async () => {
		if (userRole !== 'admin') {
					alert('You do not have permission to delete this project.');
					return;
				}
      const isConfirmed = window.confirm("Are you sure you want to delete this project? This action cannot be undone.");

      if (isConfirmed) {
        try {
          // Replace this URL with your actual endpoint for project deletion
          const response = await fetch(`https://api.contentenhance.com/delete_project/${projectId}`, { method: 'DELETE' });
          if (response.ok) {
            // Redirect to a safe page after deletion, such as the projects list
            window.location.href = "/projects";
          } else {
            throw new Error('Failed to delete the project.');
          }
        } catch (error) {
          console.error('Error:', error);
          alert('An error occurred while deleting the project.');
        }
      }
    };
	
	useEffect(() => {
	    const fetchUserRoleForProject = async () => {
	        try {
	            const response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/check_role`, { withCredentials: true });
	            if (response.status === 200) {
	                setUserRole(response.data.role); // Handle successful response
	            } else {
	                console.error('Unexpected status code:', response.status);
	                // Handle other statuses explicitly if needed
	            }
	        } catch (error) {
	            if (axios.isAxiosError(error)) {
	                // Check if the error is due to a 401 Unauthorized response
	                if (error.response && error.response.status === 401) {
	                    navigate('/login'); // Redirect to login page
	                } else if (error.response && error.response.status === 403) {
	                    console.error('Error:', error.message);
						setErrorMessage('You do not have permission to access this resource.');
	                } else {
	                    console.error('Error fetching user role for project:', error.message);
	                }
	            } else {
	                // Handle non-Axios errors
	                console.error('An unexpected error occurred:', error);
	            }
	        }
	    };

	    fetchUserRoleForProject();
	}, [projectId, navigate]); // Depend on projectId and navigate
	

    return (
        <div className="flex flex-col h-screen bg-darkBlue">
            <TopBar />
            <div className="flex h-screen bg-lighterDarkBlue text-white">
                <Sidebar />
                <div className="flex-1 bg-gray-850 p-10 overflow-auto">
                    <h1 className="text-white text-base mb-8">
		Delete Project 
                    </h1>
                 <p className="text-white text-sm mb-4">Only delete this project if you are sure you will not require any of the data in future.</p>
                    <button className="mt-4 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded text-sm" onClick={handleDeleteProject}>
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DeleteProject;