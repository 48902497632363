import React, { useState } from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <div
        style={{
          position: 'relative',
          maxWidth: '80%',
          maxHeight: '80%',
          overflow: 'auto',
          backgroundColor: 'white',
          padding: 20,
          borderRadius: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            background: 'transparent',
            border: 'none',
            color: 'gray',
            fontSize: '1.5rem',
            cursor: 'pointer',
            padding: '10px'
          }}
          aria-label="Close Modal"
        >&times;</button>
        {children}
      </div>
    </div>
  );
};

const Calendar = ({ screenshots }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const today = new Date();
  const [currentYear, setCurrentYear] = useState(today.getUTCFullYear());
  const [selectedDates, setSelectedDates] = useState([]);
  const [isCompareMode, setIsCompareMode] = useState(false);

  const toggleZoom = () => setIsZoomed(!isZoomed);

  const highlightedDates = screenshots ? screenshots.reduce((acc, screenshot) => {
    const date = new Date(screenshot.captured_date);
    const datePart = date.toISOString().split('T')[0];
    acc[datePart] = screenshot.image_url;
    return acc;
  }, {}) : {};

  const handleDateClick = (formattedDate) => {
    const imageUrl = highlightedDates[formattedDate];
    if (isCompareMode) {
      let newSelection = [...selectedDates, formattedDate];
      if (newSelection.length > 2) { // If more than two dates are selected, drop the oldest
        newSelection = newSelection.slice(-2); // Keep the last two selections only
      }
      // Sort the dates here before setting the state
      newSelection = newSelection
        .map(date => new Date(date))  // Convert to Date objects for sorting
        .sort((a, b) => a - b)        // Sort dates in ascending order
        .map(date => date.toISOString().split('T')[0]); // Convert back to ISO string format

      setSelectedDates(newSelection);
      if (newSelection.length === 2) {
        setIsModalOpen(true);
      }
    } else {
      setSelectedDate(formattedDate);
      setIsModalOpen(true);
    }
  };
 
  const handleCompareClick = () => {
    setIsCompareMode(!isCompareMode);
    setSelectedDates([]);
    setIsModalOpen(false);
  };

  const isDateSelected = (date) => selectedDates.includes(date);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const daysInMonth = (month, year) => {
    return new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
  };

  const isDateHighlighted = (date) => {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const utcDateString = utcDate.toISOString().split('T')[0];
    const isHighlighted = Boolean(highlightedDates[utcDateString]);
    return isHighlighted;
  };

  const goToPreviousYear = () => {
    setCurrentYear(currentYear - 1);
  };

  const goToNextYear = () => {
    setCurrentYear(currentYear + 1);
  };

  const displayDate = (utcDate) => {
    const date = new Date(utcDate + 'Z');
    return date.toLocaleDateString(undefined, { year: 'numeric' });
  };
  const renderComparisonModal = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {selectedDates.map(date => (
          <div
            key={date}
            style={{
              width: '50%',
              padding: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                marginBottom: '5px',
                textAlign: 'center',
                width: '100%',
              }}
            >
              {new Date(date).toLocaleDateString()}
            </span>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',  // Ensure images align to the top
                height: '80vh',  // Ensure the container is tall enough to align at the top
              }}
            >
              <img
                src={highlightedDates[date]}
                alt={`Screenshot for ${date}`}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  width: 'auto',
                  height: 'auto',
                  objectFit: 'contain',
                  cursor: isZoomed ? 'zoom-out' : 'zoom-in',
                }}
                onClick={toggleZoom}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  
  
  
  
  
  
  

  return (
    <div className="text-white min-w-[1200px]">
      <div className="flex justify-between items-center mb-4">
        <button onClick={goToPreviousYear} className="p-2 border border-white rounded bg-blue-900 hover:bg-blue-500 transition">
          {'<'}
        </button>
        <span className="text-base text-white">{displayDate(`${currentYear}`)}</span>
        <button onClick={goToNextYear} className="p-2 border border-white rounded bg-blue-900 hover:bg-blue-500 transition">
          {'>'}
        </button>
      </div>
      <div className="flex justify-center items-center mb-4">
        <button onClick={handleCompareClick} className="w-44 px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">{isCompareMode ? 'Cancel Compare' : 'Compare'}</button>
      </div>
      <div className="flex flex-wrap -mx-2">
        {months.map((month, index) => {
          const days = [];
          for (let day = 1; day <= daysInMonth(index, currentYear); day++) {
            const date = new Date(Date.UTC(currentYear, index, day));
            const isoDate = date.toISOString().split('T')[0];
            const isSelected = isCompareMode && isDateSelected(isoDate);
            days.push(
              <div key={day} className={`px-2 w-14 ${highlightedDates[isoDate] ? 'cursor-pointer' : ''} ${isSelected ? 'bg-blue-500' : highlightedDates[isoDate] ? 'bg-purple-400' : ''}`} onClick={() => handleDateClick(isoDate)}>
                {day}
              </div>
            );
          }
          return (
            <div key={month} className="w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/4 p-2">
	    <div className="p-4 bg-gray-900 rounded-lg" style={{ height: '100%' }}>
	      <h3 className="text-base text-white mb-4 text-center">{month}</h3> {/* Center the month title */}
	      <div className="grid grid-cols-7 justify-items-center gap-2 text-white">
                  {days}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {isCompareMode && selectedDates.length === 2 ? (
          selectedDates.map(date => (
            <div key={date} style={{ display: 'inline-block', width: '50%', padding: 5 }}>
              <img
                src={highlightedDates[date]}
                alt={`Screenshot for ${date}`}
                style={{
                  maxWidth: isZoomed ? 'none' : '100%',
                  maxHeight: isZoomed ? 'none' : '100%',
                  width: isZoomed ? 'auto' : '100%',
                  height: isZoomed ? 'auto' : '100%',
                  transform: isZoomed ? 'scale(2)' : 'scale(1)',
                  transition: 'transform 0.25s ease',
                  cursor: isZoomed ? 'zoom-out' : 'zoom-in',
                }}
                onClick={toggleZoom}
              />
            </div>
          ))
        ) : (
          <img
            src={highlightedDates[selectedDate]}
            alt={`Screenshot for ${selectedDate}`}
            style={{
              maxWidth: isZoomed ? 'none' : '100%',
              maxHeight: isZoomed ? 'none' : '100%',
              width: isZoomed ? 'auto' : '100%',
              height: isZoomed ? 'auto' : '100%',
              transform: isZoomed ? 'scale(2)' : 'scale(1)',
              transition: 'transform 0.25s ease',
              cursor: isZoomed ? 'zoom-out' : 'zoom-in',
            }}
            onClick={toggleZoom}
          />
        )}
      </Modal>
    </div>
  );
};

export default Calendar;
