import React from 'react';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import SpinnerComponent from './SpinnerComponent';

const LoadingScreen = ({ message }) => {
    return (
        <div className="flex flex-col h-screen bg-darkBlue">
            <TopBar />
            <div className="flex h-screen bg-lighterDarkBlue text-white">
                <Sidebar />
                <div className="flex-1 bg-gray-850 p-10 overflow-auto flex flex-col items-center justify-start mt-[300px]">
                    <SpinnerComponent />
                    <div className="mt-4 text-white text-sm">{message}</div>
                </div>
            </div>
        </div>
    );
};

export default LoadingScreen;