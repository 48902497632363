import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './contentEnhance-logo.png';
import googleicon from "./google-icon.png"
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';

function SignupPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
      // Detect if the user is in an embedded browser
      if (isInEmbeddedBrowser()) {
        setMessage('For security reasons, please open this page in your default browser.');
        setTimeout(() => {
          // Redirect to open in default browser
          window.location.href = `https://api.contentenhance.com/signup`; 
        }, 3000); // Adjust delay as needed
      }
    }, []);
  
  const handleGoogleSignUp = () => {
      window.location.href = `https://api.contentenhance.com/google-signup`; 
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      try {
        const response = await axios.post(`https://api.contentenhance.com/api/signup`, {
          email: email,
          password: password,
		recaptchaToken: recaptchaToken
        });

        setMessage(response.data.message);

        // Check if the response indicates the user needs to join an organization
        if (response.data.organization) {
          navigate(`/join-organization?org_id=${response.data.organization.id}&user_id=${response.data.user_id}`);
        } else {
          // Otherwise, redirect to the /projects page
          navigate('/projects');
        }
      } catch (error) {
        console.error('There was an error creating the user:', error.response);
        // Tailor the message based on the response
        if (error.response && error.response.data && error.response.data.message) {
          setMessage(error.response.data.message);
        } else {
          setMessage('Error during signup');
        }
      }
    };
    const handleRecaptchaChange = (token) => {
      setRecaptchaToken(token);
    };
	
	function isInEmbeddedBrowser() {
	  const ua = navigator.userAgent || navigator.vendor || window.opera;

	  // Check for common embedded browsers (WebView)
	  const embeddedBrowsers = [
	    'FBAN', 'FBAV',       // Facebook
	    'Instagram',          // Instagram
	    'Line',               // Line
	    'WeChat',             // WeChat
	    'Twitter',            // Twitter
	    'Snapchat',           // Snapchat
	    'wv',                 // Generic WebView identifier
	    'LinkedIn',           // LinkedIn
	    'WhatsApp',           // WhatsApp
	    'Telegram',           // Telegram
	    'TikTok'              // TikTok
	  ];

	  return embeddedBrowsers.some(browser => ua.includes(browser));
	}
	
  return (
    <div className="flex items-center justify-center h-screen bg-darkBlue">
      <div className="w-full max-w-md p-8 space-y-6 rounded-lg bg-gray-900 shadow-2xl">
        <div className="text-center">
          <img src={logo} alt="contentEnhance Logo" className="h-12 mx-auto" />
	  <div className="relative flex py-5 items-center">
	  <div className="flex-grow border-t border-gray-700"></div>
	  </div>
          <h1 className="text-lg text-center text-white">Sign up</h1>
          <p className="text-gray-300 mt-2 text-sm">Create your contentEnhance account with your work email to find poor performing pages and start enhancing user experience.</p>
        </div>
        <button 
	  onClick={handleGoogleSignUp} // Add the onClick event handler
	  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700">
          <img src={googleicon} alt="Google" className="h-5 w-5 mr-3" />
          Continue with Google
        </button>
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-gray-700"></div>
          <span className="flex-shrink mx-4 text-gray-400">OR</span>
          <div className="flex-grow border-t border-gray-700"></div>
        </div>
<form onSubmit={handleSubmit} className="space-y-6">
  <div>
    <input 
      type="email"
      id="email"
      autoComplete="email"
      required
      placeholder="Enter your email address"
      className="appearance-none w-full px-3 py-2 text-sm border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-white bg-gray-700"
      value={email} // Bind the input value to the email state variable
      onChange={(e) => setEmail(e.target.value)} // Update the email state when the input changes
    />
  </div>
  <div>
    <input 
      type="password"
      id="password"
      autoComplete="new-password"
      required
      placeholder="Enter your password"
      className="appearance-none w-full px-3 py-2 text-sm border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-white bg-gray-700"
      value={password} // Bind the input value to the password state variable
      onChange={(e) => setPassword(e.target.value)} // Update the password state when the input changes
    />
  </div>
<div className="flex justify-center">
<ReCAPTCHA
            sitekey="6LfZvDgqAAAAAG9dPKI2ctGz7Eq76R_rDfHMOuSu"
	        theme="dark"
            onChange={handleRecaptchaChange}
          />
			</div>
  <button
    type="submit"
    className="w-full flex justify-center py-2 px-4 text-sm border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:ring-4 focus:ring-blue-300"
  >
    Continue with email
  </button>
</form>
        <div className="text-center text-sm text-gray-400">
          By continuing, you're agreeing to our <a href="#" className="text-blue-500 hover:underline">Terms</a> and <a href="#" className="text-blue-500 hover:underline">Privacy policy</a>.
        </div>
        <div className="text-center text-sm text-gray-400 mt-4">
          Already have an account? <a href="/login" className="text-blue-500 hover:underline">Log in</a>
        </div>
	  {message && <p className="text-center text-red-500">{message}</p>}
      </div>
    </div>
  );
}

export default SignupPage;