import React from 'react';
import axios from 'axios';
import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage'; // Add this import
import LoginPage from './LoginPage';
import SignUpPage from './SignUpPage';
import ProjectPage from './ProjectPage';
import ProjectOverview from './ProjectOverview';
import URLPage from './URLPage';
import { TaskStatusProvider } from './TaskStatusContext';
import AddUserProject from './AddUserProject';
import InviteAccountUser from './InviteAccountUser'; // Make sure this component exists
import MigrateURLs from './MigrateURLs'; // Make sure this component exists
import AudienceBrief from './AudienceBrief';
import ProjectDetails from './ProjectDetails';
import DeleteProject from './DeleteProject';
import RobotDetection from './RobotDetection';
import RemoveUrl from './RemoveUrl';
import CookieManager from './CookieManager';
import UserSettings from './UserSettings';
import OrganizationOverview from './OrganizationOverview';
import OrganizationProjects from './OrganizationProjects';
import JoinOrganization from './JoinOrganization';
import ManageOrganization from './ManageOrganization';
import AddUserOrganization from './AddUserOrganization';
import ForgottenPassword from './ForgottenPassword' 
import ResetPasswordPage from './ResetPasswordPage';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import Pricing from './Pricing';
import SignupOrganization from './SignupOrganization';
import VerifyEmailPage from './VerifyEmailPage';
import ContentPerformance from './ContentPerformance';
import './App.css';

axios.defaults.withCredentials = true;

async function refreshAccessToken() {
    try {
        // Send a POST request to the refresh endpoint
        const response = await axios.post('https://api.contentenhance.com/refresh_token');

        // Handle response - the new access token is automatically set in the cookie by the server
        console.log('Access token refreshed:', response.data.message);
        return response.data;  // You can return the whole response data or just the message
    } catch (error) {
        console.error('Error refreshing access token:', error);
        throw new Error('Failed to refresh access token');
    }
}

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const { access_token } = await refreshAccessToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
        return axios(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        // Handle failed refresh here (e.g., redirect to login)
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(config => {
    const token = document.cookie.split(';').find(c => c.trim().startsWith('csrf_token='));
    if (token) {
        const csrfToken = token.split('=')[1];
        config.headers['X-CSRFToken'] = csrfToken; // Make sure this header name matches your server-side expectations.
    } else {
        console.log("No CSRF token found in cookies");
    }
    return config;
}, error => Promise.reject(error));

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/projects" element={<ProjectPage />} />
        <Route path="/projects/:projectId/project-invite" element={<AddUserProject />} />
        <Route path="/projects/:projectId" element={
          <TaskStatusProvider>
            <ProjectOverview />
          </TaskStatusProvider>
        } />
        <Route path="/projects/:projectId/:urlId" element={<URLPage />} />
        <Route path="/account-invite" element={<InviteAccountUser />} />
        <Route path="/migrate-urls" element={<MigrateURLs />} />
		<Route path="/projects/:projectId/audience-brief" element={<AudienceBrief />} />
		<Route path="/projects/:projectId/details" element={<ProjectDetails />} />
		<Route path="/projects/:projectId/delete-project" element={<DeleteProject />} />
		<Route path="/projects/:projectId/ai-access-check" element={<RobotDetection />} />
		<Route path="/projects/:projectId/:urlId/remove-url" element={<RemoveUrl />} />
		<Route path="/projects/:projectId/cookie-manager" element={<CookieManager />} />
		<Route path="/user-settings" element={<UserSettings />} />
		<Route path="/workspaces" element={<OrganizationOverview />} />
        <Route path="/organization/projects/:organizationId" element={<OrganizationProjects />} />
		<Route path="/organization/:organizationId/details" element={<ManageOrganization />} />
		<Route path="/organization/:organizationId/organization-invite" element={<AddUserOrganization />} />
		<Route path="/join-organization" element={<JoinOrganization />} />
		<Route path="/forgotten-password" element={<ForgottenPassword />} />
		<Route path="/reset-password" element={<ResetPasswordPage />} />
		<Route path="/privacy-policy" element={<PrivacyPolicy />} />
		<Route path="/terms-of-service" element={<TermsOfService />} />
		<Route path="/verify-email" element={<VerifyEmailPage />} />
		<Route path="/pricing" element={<Pricing />} />
		<Route path="/signup-organization" element={<SignupOrganization />} />
		<Route path="/content-performance" element={<ContentPerformance />} />
      </Routes>
    </Router>
  );
}

export default App;
