import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

function JoinOrganization() {
  const [message, setMessage] = useState('');
  const [showResendButton, setShowResendButton] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orgId = queryParams.get('org_id');
    const userId = queryParams.get('user_id');
    if (orgId && userId) {
      fetchOrganization(orgId);
      setUserId(userId);
    }
  }, [location]);

  const fetchOrganization = async (orgId) => {
    try {
      const response = await axios.get(`https://api.contentenhance.com/api/organization/${orgId}`);
      setOrganization(response.data.organization);
    } catch (error) {
      console.error('Error fetching organization:', error);
    }
  };

  const handleRequestToJoinOrganization = async () => {
    try {
      const response = await axios.post(`https://api.contentenhance.com/api/request_join_organization`, {
        user_id: userId,
        organization_id: organization.organization_id,
      });

      setMessage(response.data.message);
      navigate('/projects');
    } catch (error) {
      const errorMsg = error.response?.data?.error || 'Error during requesting to join organization';
      setMessage(errorMsg);

      // Show resend button if the error indicates email verification is required
      if (errorMsg === 'Email address is not verified. Please verify your email first.') {
        setMessage('Your email is not verified. Please click the button below to resend the verification email.');
        setShowResendButton(true);
      } else {
        setShowResendButton(false);
      }
    }
  }; // <--- Add this missing closing brace here

  const handleResendVerificationEmail = async () => {
    try {
      await axios.post(`https://api.contentenhance.com/api/resend-verification-email`);
      setMessage('Verification email has been resent. Please check your inbox.');
    } catch (error) {
      console.error('Error resending verification email:', error);
      setMessage('Error resending verification email. Please try again later.');
    }
  };

  const handleSkip = () => {
    window.location.href = 'https://www.contentenhance.com/projects';
  };

  return (
    <div className="flex items-center justify-center h-screen bg-darkBlue">
      <div className="w-full max-w-md p-8 space-y-6 rounded-lg bg-gray-900 shadow-2xl">
        <div className="text-center">
          <h1 className="text-lg text-center text-white">Join Organization</h1>
          {organization && (
            <>
              <p className="text-gray-200 mt-2 text-sm">Your email address belongs to the domain of {organization.name}. Do you want to request to join this organization?</p>
              <button
                onClick={handleRequestToJoinOrganization}
                className="mt-4 px-4 py-2 bg-blue-600 text-sm text-white rounded-md hover:bg-blue-700"
              >
                Request to join {organization.name}
              </button>
              <button
                onClick={handleSkip}
                className="mt-4 px-4 py-2 bg-red-600 text-sm text-white rounded-md hover:bg-red-700 ml-2"
              >
                Skip
              </button>
            </>
          )}
          {!organization && (
            <p className="text-gray-200 mt-2 text-sm">Loading organization details...</p>
          )}
        </div>
        {message && <p className="text-center text-sm text-red-500">{message}</p>}
        {showResendButton && (
		<div className="flex justify-center mt-4">
		  <button
		    onClick={handleResendVerificationEmail}
		    className="px-4 py-2 bg-blue-600 text-sm text-white rounded-md hover:bg-blue-700"
		  >
		    Resend Verification Email
		  </button>
		</div>
        )}
      </div>
    </div>
  );
}

export default JoinOrganization;
