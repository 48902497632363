import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import IssueResolutionModal from './IssueResolutionModal';
import SpinnerComponent from './SpinnerComponent'; // Ensure this is correctly imported
import { useParams, useNavigate } from 'react-router-dom';

const RobotDetection = () => {
    const [exampleURL, setExampleURL] = useState('');
    const [latestScreenshotUrl, setLatestScreenshotUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false); // State to manage loading state
    const { projectId } = useParams(); // This extracts projectId from the URL
    const navigate = useNavigate();
    const [selectedIssue, setSelectedIssue] = useState('');
    const [projectDetails, setProjectDetails] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIssueStatus, setCurrentIssueStatus] = useState('');
    const [showResolveButtonOnly, setShowResolveButtonOnly] = useState(false);
    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
                if (!response.data.isAuthenticated) {
                    navigate('/login'); // Redirect to login if not authenticated
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                navigate('/login'); // Redirect to login on error
            }
        };

        checkAuthentication();
    }, [navigate]);

    useEffect(() => {
        // This function is defined inside useEffect to avoid defining it outside or inline
        const fetchProjectDetails = async () => {
            try {
                const response = await axios.get(`https://api.contentenhance.com/api/project_details/${projectId}`, {
                    withCredentials: true
                });
                setProjectDetails(response.data); // Set the project details state
                const issueStatus = response.data.issue_present;
                // Set based on fetched issue status, ensuring we're showing "Resolve issue" only for non-no_issue statuses
                setShowResolveButtonOnly(issueStatus && issueStatus !== 'no_issue');
            } catch (error) {
                console.error("Error fetching project details:", error);
            }
        };

        fetchProjectDetails(); // Call the fetch function when the component mounts
    }, [projectId]);

    // Function to poll for the latest screenshot URL
    const pollScreenshot = async (projectId) => {
        let attempts = 0;
        const maxAttempts = 10; // Poll up to 10 times

        while (attempts < maxAttempts) {
            try {
                const response = await axios.get(`https://api.contentenhance.com/get_latest_screenshot`, {
                    params: { project_id: projectId },
                    withCredentials: true
                });

				if (response.data.screenshot_url) {
				    // Append a random query parameter to force the browser to reload the image
				    const urlWithCacheBuster = `${response.data.screenshot_url}?t=${new Date().getTime()}`;
				    setLatestScreenshotUrl(urlWithCacheBuster);
				    setIsLoading(false);
				    return;
				}

                attempts += 1;
                await new Promise(resolve => setTimeout(resolve, 3000)); // Wait 3 seconds before next attempt

            } catch (error) {
                console.error("Error fetching latest screenshot:", error);
            }
        }

        setIsLoading(false);
        alert("Failed to retrieve screenshot. Please try again later.");
    };

    // Function to initiate the screenshot capture
    const getScreenshot = async () => {
        if (!['admin', 'editor'].includes(userRole)) {
            alert("You don't have permission to perform this action.");
            return;
        }
        if (!exampleURL) {
            alert("Please enter a URL.");
            return;
        }

        setIsLoading(true); // Start loading
        try {
            await axios.post('https://api.contentenhance.com/take_bot_screenshot', {
                url: exampleURL, 
                project_id: projectId // Pass projectId in the request body
            });

            // Start polling for the screenshot
            pollScreenshot(projectId);

        } catch (error) {
            console.error("Failed to get screenshot:", error);
            alert("Failed to get screenshot. Please try again.");
            setIsLoading(false); // Stop loading in case of an error
        }
    };

    const fetchUserRoleForProject = async (projectId) => {
        try {
            const response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/check_role`, { withCredentials: true });
            if (response.status === 200) {
                return response.data.role;
            }
        } catch (error) {
            console.error('Error fetching user role for project:', error);
            // Handle error (e.g., user not authenticated or doesn't have a role in the project)
        }
    };

    useEffect(() => {
        const fetchAndSetUserRole = async () => {
            // Assuming you have projectId and a function to fetch the role
            const role = await fetchUserRoleForProject(projectId);
            setUserRole(role);
        };

        fetchAndSetUserRole();
    }, [projectId]); // Re-fetch if projectId changes

    const handleIssueResolved = async () => {
        if (userRole !== 'admin' && userRole !== 'editor') {
            alert('You do not have permission to resolve issues.');
            return;
        }
    
        // Update the issue_resolved field to true
        const issuePayload = { project_id: projectId, issue_resolved: true };
        try {
            const response = await axios.post('https://api.contentenhance.com/api/update_issue_status', issuePayload);
            if (response.data.success) {
                console.log('Issue status updated successfully');
                // Redirect the user
                navigate(`/projects/${projectId}`);
            } else {
                console.error('Failed to update issue status');
            }
        } catch (error) {
            console.error("Failed to update issue status:", error);
        }
    };

    const handleAction = async (actionType) => {
        if (userRole !== 'admin' && userRole !== 'editor') {
            alert('You do not have permission to perform this action.');
            return;
        }
        setSelectedIssue(actionType);
        let issuePayload = { project_id: projectId }; // Include projectId in the payload
        let shouldNavigate = false; // Flag to determine if navigation should occur
        
        // Add logic to open the modal for specific action types
        if (actionType === 'ai_blocked' || actionType === 'there_is_another_issue') {
            setIsModalOpen(true); // Open the modal for these action types
        }

        switch (actionType) {
            case 'site_fine':
                // Prepare the payload for "My site looks fine" case
                issuePayload = { ...issuePayload, issue_present: 'no_issue', issue_resolved: true };
                shouldNavigate = true; // Set flag to true for later navigation
                break;
            case 'ai_blocked': // Adjusted to use 'bot_issue'
                issuePayload = { ...issuePayload, issue_present: 'bot_issue', issue_resolved: false };
                break;
            case 'there_is_another_issue': // Assuming this is for other issues
                issuePayload = { ...issuePayload, issue_present: 'other_issue', issue_resolved: false };
                break;
            default:
                console.error('Invalid action');
                return;
        }

        try {
            // Perform the API call to update issue status
            const response = await axios.post('https://api.contentenhance.com/api/update_issue_status', issuePayload);
            if (response.data.success) {
                console.log('Issue status updated successfully');
                setCurrentIssueStatus(issuePayload.issue_present);

                // Use projectDetails from the state directly
                if (projectDetails) { // Ensure projectDetails is not null
                    let navigatePath = `/projects/${projectId}`;
                    if (issuePayload.issue_present === 'no_issue') {
                        if (!projectDetails.propertyID) {
                            // If no GA4 property, append the query parameter
                            navigatePath += '?importUrls=true';
                        }
                    }

                    // If the update was successful and shouldNavigate is true, navigate
                    if (shouldNavigate) {
                        navigate(navigatePath);
                    }
                } else {
                    console.error("Project details not available");
                }
            } else {
                console.log('Failed to update issue status');
            }
        } catch (error) {
            console.error("Failed to update issue status:", error);
        }
    };

    return (
        <div className="flex flex-col h-screen bg-darkBlue">
            <TopBar />
            <div className="flex h-screen bg-lighterDarkBlue text-white">
                <Sidebar />
                <div className="flex-1 bg-gray-850 p-10 overflow-auto">
                    {projectDetails && (
                        <h1 className="text-white text-base mb-8">
                            Project: {projectDetails.project_name}
                        </h1>
                    )}
                    
                    <h2 className="text-white text-base mb-2 text-center">
                        Step 5: AI access check
                    </h2>
                    <p className="text-white text-sm mb-3 text-center">Enter an example URL from your website and check the screenshot below. If there is a captcha, you will need to add an IP address to the whitelist of your CDN or hosting service to obtain AI-generated recommendations:</p>
				<div className="relative flex py-4 justify-center text-grey-900">
				  <input 
				    type="text"
				    value={exampleURL}
				    onChange={(e) => setExampleURL(e.target.value)}
				    placeholder="Enter example URL"
				    className="h-[35px] mr-2 text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900 w-full sm:w-auto"
				  />
				  <div className="flex items-center justify-center h-full">
				    <button 
				      className="p-2 bg-blue-500 text-white rounded hover:bg-blue-900 text-sm h-[35px] min-w-[120px] whitespace-nowrap" // Added min-width and nowrap
				      onClick={getScreenshot}
				    >
				      Get screenshot
				    </button>
				  </div>
				</div>
                    {isLoading ? (
  <div className="w-full md:w-2/3 p-8 mx-auto">
    <div className="flex justify-center bg-gray-900 max-h-[600px] h-[400px] md:h-[500px]">
      <div className="mt-[240px] mb-[240px] flex flex-col justify-center items-center">
                                    <div>
                                        <SpinnerComponent />
                                    </div>
                                    <div className="text-white text-sm mt-2">Attempting to access website. Do not refresh.</div>
                                </div>
                            </div>
                        </div>
                    ) : (
  <div className="w-full md:w-2/3 p-8 mx-auto">
    <div className="flex justify-center bg-gray-900 max-h-[600px] h-[400px] md:h-[500px]">
      <div className="mt-[240px] mb-[240px] flex flex-col justify-center items-center">
			            {latestScreenshotUrl ? (
			                <img 
			                    src={latestScreenshotUrl} 
			                    alt="Generated Screenshot" 
			                    className="max-w-full h-auto" 
			                />
			            ) : (
			                <p className="mt-[250px] mb-[250px] text-sm">Screenshot will appear here</p>
			            )}
			        </div>
			    </div>
			</div>
                    )}
                    <div className="relative flex py-4 justify-center flex-col md:flex-row">
                        {showResolveButtonOnly ? (
                            <button 
                                className={`mt-4 ${latestScreenshotUrl ? 'bg-blue-500 hover:bg-blue-700' : 'bg-blue-300 cursor-not-allowed'} text-white py-2 px-4 rounded text-sm mr-2`}
                                onClick={handleIssueResolved} // Assuming this will open a modal or another component to resolve the issue
                                disabled={!latestScreenshotUrl}
                            >
                                Resolve issue
                            </button>
                        ) : (
                            <>
                                <button 
                                    className={`mt-4 ${latestScreenshotUrl ? 'bg-blue-500 hover:bg-blue-700' : 'bg-blue-300 cursor-not-allowed'} text-white py-2 px-4 rounded text-sm mr-2`} 
                                    onClick={() => handleAction('site_fine')}
                                    disabled={!latestScreenshotUrl}
                                >
                                    My site is accessible
                                </button>
                                <button 
                                    className={`mt-4 ${latestScreenshotUrl ? 'bg-red-600 hover:bg-red-700' : 'bg-red-300 cursor-not-allowed'} text-white py-2 px-4 rounded text-sm mr-2`} 
                                    onClick={() => handleAction('ai_blocked')}
                                    disabled={!latestScreenshotUrl}
                                >
                                    There is bot protection
                                </button>
                                <button 
                                    className="mt-4 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded text-sm" 
                                    onClick={() => handleAction('there_is_another_issue')}
                                >
                                    There is another issue
                                </button>
                            </>
                        )}
                    </div>
                
                    <IssueResolutionModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        selectedIssue={selectedIssue}
                        onIssueResolved={handleIssueResolved} 
                        navigate={navigate} // Pass navigate as a prop
                        projectId={projectId} // Pass projectId as a prop
                    />
                </div>
            </div>
        </div>
    );
};

export default RobotDetection;
