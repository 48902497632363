import React from 'react';
import { Link } from 'react-router-dom';

function VerifyEmailPage() {
  return (
    <div className="flex items-center justify-center h-screen bg-darkBlue">
      <div className="w-full max-w-md p-8 space-y-6 rounded-lg bg-gray-900 shadow-2xl text-center">
        <h1 className="text-lg text-white">Verify Your Email</h1>
        <p className="text-gray-300 mt-2 text-sm">
          We've sent a verification email to your email address. Please check your inbox and click the link to verify your email.
        </p>
        <Link to="/login" className="text-blue-500 hover:underline">
          Return to login
        </Link>
      </div>
    </div>
  );
}

export default VerifyEmailPage;
