import React, { useState } from 'react';
import axios from 'axios';
import logo from './contentEnhance-logo.png';
import googleicon from "./google-icon.png";
import { useNavigate } from 'react-router-dom';

function SignupOrganization() {
  const [organizationName, setOrganizationName] = useState('');
  const [organizationLogo, setOrganizationLogo] = useState(null);
  const [workEmail, setWorkEmail] = useState('');
  const [organizationDomain, setOrganizationDomain] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  
  const handleGoogleSignUp = () => {
    window.location.href = `https://api.contentenhance.com/google-signup`; 
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('organizationName', organizationName);
      formData.append('workEmail', workEmail);
      formData.append('organizationDomain', organizationDomain);
      if (organizationLogo) {
        formData.append('organizationLogo', organizationLogo);
      }

      const response = await axios.post(`https://api.contentenhance.com/api/signup-organization`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setMessage(response.data.message);

      if (response.data.organization) {
        navigate(`/join-organization?org_id=${response.data.organization.id}`);
      } else {
        navigate('/projects');
      }
    } catch (error) {
      console.error('There was an error creating the organization:', error.response);
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage('Error during signup');
      }
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-darkBlue">
      <div className="w-full max-w-md p-8 space-y-6 rounded-lg bg-gray-900 shadow-2xl">
        <div className="text-center">
          <img src={logo} alt="contentEnhance Logo" className="h-12 mx-auto" />
          <div className="relative flex py-5 items-center">
            <div className="flex-grow border-t border-gray-700"></div>
          </div>
          <h1 className="text-lg text-center text-white">Create Organization</h1>
          <p className="text-gray-300 mt-2 text-sm">Create your contentEnhance organization.</p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <input 
              type="text"
              id="organizationName"
              required
              placeholder="Enter your organization name"
              className="appearance-none w-full px-3 py-2 text-sm border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-white bg-gray-700"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
          </div>
          <div>
            <input 
              type="email"
              id="workEmail"
              required
              placeholder="Enter your work email"
              className="appearance-none w-full px-3 py-2 text-sm border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-white bg-gray-700"
              value={workEmail}
              onChange={(e) => setWorkEmail(e.target.value)}
            />
          </div>
          <div>
            <input 
              type="text"
              id="organizationDomain"
              required
              placeholder="Enter your organization domain"
              className="appearance-none w-full px-3 py-2 text-sm border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-white bg-gray-700"
              value={organizationDomain}
              onChange={(e) => setOrganizationDomain(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400">
              Organization logo (optional)
            </label>
            <input 
              type="file"
              id="organizationLogo"
              className="w-full px-3 py-2 text-sm text-gray-500 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 bg-gray-700"
              onChange={(e) => setOrganizationLogo(e.target.files[0])}
            />
          </div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 text-sm border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:ring-4 focus:ring-blue-300"
          >
            Create organization
          </button>
        </form>
        <div className="text-center text-sm text-gray-400">
          By continuing, you're agreeing to our <a href="#" className="text-blue-500 hover:underline">Terms</a> and <a href="#" className="text-blue-500 hover:underline">Privacy policy</a>.
        </div>
        {message && <p className="text-center text-red-500">{message}</p>}
      </div>
    </div>
  );
}

export default SignupOrganization;
