import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import logo from './contentEnhance-white.png';
import './Sidebar.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

function TopBarMarketing() {
  const [avatarUrl, setAvatarUrl] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.get('https://api.contentenhance.com/api/auth/status', { withCredentials: true });
        console.log('Auth Response:', response.data); // Check the structure of the response
        if (response.data.isAuthenticated) {
          setIsAuthenticated(true);
          setAvatarUrl(response.data.avatar_url);  // Ensure avatar_url exists in the response
          setEmail(response.data.email);  // Ensure email exists in the response
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Authentication check failed:', error);
        setIsAuthenticated(false);
      }
    };

    const cachedAuthStatus = localStorage.getItem('isAuthenticated');
    if (cachedAuthStatus === 'true') {
      setIsAuthenticated(true);
      axios.get('https://api.contentenhance.com/api/user/avatar', { withCredentials: true })
        .then(response => {
          console.log('User Response:', response.data);  // Log the structure of the user response
          setAvatarUrl(response.data.avatar_url);
          setEmail(response.data.email);
        })
        .catch(error => console.error('Error fetching avatar:', error));
    } else {
      checkAuthentication();
    }
  }, []);
 
  

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get('https://api.contentenhance.com/logout', { withCredentials: true });
      if (response.status === 200) {
        localStorage.clear();  // Clear all items from local storage
        window.location.href = 'https://www.contentenhance.com/login';  // Redirect to login page
      } else {
        throw new Error('Logout failed');
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const closeDropdown = (event) => {
      if (!event.target.closest('.dropdown-container')) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', closeDropdown);
    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, []);

  return (
    <div className="text-black px-4 py-2">
      <div className="container flex items-center justify-between">
        <div className="flex items-center">
          <Link to="/">
            <img src={logo} alt="Logo" className="h-12" />
          </Link>
          <div className="hidden md:flex ml-8">
            <a href="#why-contentEnhance" className="text-sm text-black hover:text-gray-800">Why contentEnhance?</a>
            <a href="#who-contentenhance" className="ml-8 text-sm text-black hover:text-gray-800">Who's it for?</a>
            <a href="#packed-features" className="ml-8 text-sm text-black hover:text-gray-800">Features</a>
            <a href="#how-it-works" className="ml-8 text-sm text-black hover:text-gray-800">How it works</a>
            <a href="#pricing" className="ml-8 text-sm text-black hover:text-gray-800">Pricing</a>
            <Link to="/signup" className="ml-8 text-sm text-black hover:text-gray-800">Get started</Link>
          </div>
        </div>
        <div className="flex items-center ml-auto">
          {isAuthenticated === null ? (
            <div className="h-8 w-8 rounded-full bg-gray-300 flex items-center justify-center text-sm mr-4">
              <span>Loading...</span>
            </div>
          ) : isAuthenticated ? (
            <div onClick={toggleDropdown} className="cursor-pointer">
              {avatarUrl ? (
                <img src={avatarUrl} alt="User Avatar" className="h-8 w-8 rounded-full object-cover mr-4" />
              ) : (
                <div className="h-8 w-8 rounded-full bg-gray-300 flex items-center justify-center text-sm mr-4">
                  <span>User</span>
                </div>
              )}
            </div>
          ) : (
            <button className="bg-blue-600 text-sm text-white py-2 px-4 rounded hover:bg-blue-700 mr-4" onClick={() => navigate('/login')}>
              Login
            </button>
          )}
          <button onClick={toggleMobileMenu} className="md:hidden text-black">
            {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </button>
        </div>
        {isDropdownOpen && isAuthenticated && (
          <div className="absolute right-2 mt-48 bg-gray-900 text-sm text-gray-200 rounded shadow-lg z-50 w-56 dropdown-container">
            <div className="email-display" title={email}>{email}</div>
            <div className="relative flex items-center">
              <div className="flex-grow border-t border-gray-700"></div>
            </div>
            <ul className="py-4">
              <li>
                <a href="/workspaces" className="sidebar-link" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <WorkspacesIcon style={{ color: '#b3b3b3', fontSize: '.875rem' }} />Workspaces 
                  <ArrowForwardIosIcon style={{ color: '#b3b3b3', fontSize: '.875rem', marginLeft: 'auto' }} />
                </a>
              </li>
              <li>
                <a href="/user-settings" className="sidebar-link" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <SettingsIcon style={{ color: '#b3b3b3', fontSize: '.875rem' }} />User settings 
                  <ArrowForwardIosIcon style={{ color: '#b3b3b3', fontSize: '.875rem', marginLeft: 'auto' }} />
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" onClick={handleLogout} className="sidebar-link" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <WavingHandIcon style={{ color: '#b3b3b3', fontSize: '.875rem' }} /> Logout
                  <ArrowForwardIosIcon style={{ color: '#b3b3b3', fontSize: '.875rem', marginLeft: 'auto' }} />
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden flex flex-col items-center mt-2">
          <a href="#why-contentEnhance" className="text-sm text-black hover:text-gray-800 mb-2">Why contentEnhance?</a>
          <a href="#who-contentenhance" className="text-sm text-black hover:text-gray-800 mb-2">Who's it for?</a>
          <a href="#packed-features" className="text-sm text-black hover:text-gray-800 mb-2">Features</a>
          <a href="#how-it-works" className="text-sm text-black hover:text-gray-800 mb-2">How it works</a>
          <a href="#pricing" className="text-sm text-black hover:text-gray-800 mb-2">Pricing</a>
          <Link to="/signup" className="text-sm text-black hover:text-gray-800 mb-2">Get started</Link>
        </div>
      )}
    </div>
  );
}

export default TopBarMarketing;
