import React, { useState, useEffect } from 'react';
import DeselectModal from './DeselectModal';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import IconButton from '@material-ui/core/IconButton';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SpinnerComponent from './SpinnerComponent'; // Ensure this component exists and is properly 
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';  // If you need support for tables, strikethrough, task lists, etc.

const Checklist = ({ tasks: initialTasks, updateTasks }) => {
  
  const [newTaskText, setNewTaskText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(null);
  const { projectId, urlId } = useParams(); 
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [hoveredTaskId, setHoveredTaskId] = useState(null);
  const [editedTaskText, setEditedTaskText] = useState('');
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState({});
  const [viewGenerated, setViewGenerated] = useState({});
  const [generatedContent, setGeneratedContent] = useState({});
  const [modalContent, setModalContent] = useState('');
  const [currentUserRole, setCurrentUserRole] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); 
  const [numberOfGenerates, setNumberOfGenerates] = useState(0);
  const [tasks, setTasks] = useState(initialTasks.map(task => ({
      ...task,
      assigned_user_id: task.assigned_user_id || null // Initialize with the assigned user, if any
  })).sort((a, b) => a.plan_id - b.plan_id)); // Sort tasks by plan_id
 
  
  useEffect(() => {
      const fetchGeneratedContent = async () => {
          setIsLoading(true);
          try {
              const response = await axios.get(`https://api.contentenhance.com/api/get-generations`, { params: { urlId } });
              console.log("API Response:", response.data);

              const fetchedContent = {};
              const viewGeneratedUpdates = {};

              // Iterate over the fetched data to update both content and view flags
              response.data.forEach(item => {
                  fetchedContent[item.planId] = item.generatedCopy;
                  viewGeneratedUpdates[item.planId] = true; // Assume if there's generated content, it's viewable
              });

              setGeneratedContent(prev => ({ ...prev, ...fetchedContent }));
              setViewGenerated(prev => ({ ...prev, ...viewGeneratedUpdates }));
          } catch (error) {
              console.error('Failed to fetch generated content:', error);
          }
          setIsLoading(false);
      };

      if (urlId) {
          fetchGeneratedContent();
      }
  }, [urlId]); // Dependency array ensures this effect runs whenever urlId changes
 
  
  
  useEffect(() => {
      const fetchUsers = async () => {
          try {
              const response = await axios.get(`https://api.contentenhance.com/api/url/${urlId}/users`);
              setUsers(response.data); // Assuming you have setUsers method and users state
          } catch (error) {
              console.error('Error fetching users:', error);
          }
      };

      fetchUsers();
  }, [urlId]);
  
  useEffect(() => {
  	    const fetchUserRoleForProject = async () => {
  	        try {
  	            const response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/check_role`, { withCredentials: true });
  	            if (response.status === 200) {
  	                setCurrentUserRole(response.data.role); // Handle successful response
  	            } else {
  	                console.error('Unexpected status code:', response.status);
  	                // Handle other statuses explicitly if needed
  	            }
  	        } catch (error) {
  	            if (axios.isAxiosError(error)) {
  	                if (error.response && error.response.status === 403) {
  	                    console.error('Error:', error.message);
  						setErrorMessage('You do not have permission to perform this action.');
  	                } else {
  	                    console.error('Error fetching user role for project:', error.message);
  	                }
  	            } else {
  	                // Handle non-Axios errors
  	                console.error('An unexpected error occurred:', error);
  	            }
  	        }
  	    };

  	    fetchUserRoleForProject();
  	}, [projectId]); // Depend on projectId and navigate
	
	const fetchGeneratesCount = async () => {
		try {
			const response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/generates-count`, { withCredentials: true });
			if (response.status === 200) {
				setNumberOfGenerates(response.data.number_of_generates);
			}
		} catch (error) {
			console.error('Error fetching number of generates:', error);
		}
	};

	useEffect(() => {
		fetchGeneratesCount();
	}, [projectId]);
  
  const handleUserAssignment = async (planId, userId) => {
	  if (!['admin', 'editor'].includes(currentUserRole)) {
	          alert("You do not have permission to perform this action.");
	          return;
	      }
      try {
          await axios.put(`https://api.contentenhance.com/api/update-assigned-user/${planId}`, { assigned_user_id: userId });
          // Update the tasks state
          const updatedTasks = tasks.map(task => 
              task.plan_id === planId ? { ...task, assigned_user_id: userId } : task
          );
          setTasks(updatedTasks);
      } catch (error) {
          console.error('Error assigning user:', error);
      }
  };
  const editTaskInDatabase = async (planId, updatedText) => {
    if (!['admin', 'editor'].includes(currentUserRole)) {
      alert("You do not have permission to perform this action.");
      return;
    }
    try {
      const response = await axios.put(
        `https://api.contentenhance.com/api/edit-task/${planId}`, 
        {
          recommendations: updatedText
        }, 
        {
          withCredentials: true // Add this option to send credentials
        }
      );
      // Update the tasks state with the response if needed
      // For example:
      const updatedTasks = tasks.map(task => task.plan_id === planId ? { ...task, recommendations: updatedText } : task);
      setTasks(updatedTasks);
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };
  
  
  const saveEditedTask = async (planId) => {
	  if (!['admin', 'editor'].includes(currentUserRole)) {
	          alert("You do not have permission to perform this action.");
	          return;
	      }
      await editTaskInDatabase(planId, editedTaskText);
      setEditingTaskId(null);
      setEditedTaskText('');
      setTasks(currentTasks => {
          return currentTasks.map(task => task.plan_id === planId ? { ...task, recommendations: editedTaskText } : task)
                             .sort((a, b) => a.plan_id - b.plan_id); // Sort tasks after editing
      });
  };
  
  const deleteTaskFromDatabase = async (planId) => {
	  if (!['admin', 'editor'].includes(currentUserRole)) {
	          alert("You do not have permission to perform this action.");
	          return;
	      }
    // Show confirmation dialog
    const isConfirmed = window.confirm('Are you sure you want to delete this task?');

    if (isConfirmed) {
      try {
        await axios.delete(`https://api.contentenhance.com/api/delete-task/${planId}`);
        setTasks(currentTasks => {
          const updatedTasks = currentTasks.filter(task => task.plan_id !== planId);
          return updatedTasks.sort((a, b) => a.plan_id - b.plan_id); // Sort tasks after deleting
        });
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    }
  };
  
  
  // Map the initialTasks to extract completed_date and initialize completionDates state
  const initialCompletionDates = initialTasks.reduce((acc, task) => {
    if (task.completed_date) {
      acc[task.plan_id] = task.completed_date;
    }
    return acc;
  }, {});

const [completionDates, setCompletionDates] = useState(initialCompletionDates);

const addTaskToDatabase = async (taskText) => {
	if (!['admin', 'editor'].includes(currentUserRole)) {
	        alert("You do not have permission to perform this action.");
	        return;
	    }
    try {
        const response = await axios.post('https://api.contentenhance.com/api/add-task', {
            url_id: urlId,
            recommendations: taskText,
        });
        setTasks(currentTasks => {
            const updatedTasks = [...currentTasks, response.data];
            return updatedTasks.sort((a, b) => a.plan_id - b.plan_id); // Sort tasks by plan_id after adding a new one
        });
    } catch (error) {
        console.error('Error adding task:', error);
    }
};

  const addTask = () => {
      addTaskToDatabase(newTaskText);
      setNewTaskText('');
  };
  
  const updateTaskStatusInDatabase = async (planId, status, completedDate) => {
      try {
          await axios.put(`https://api.contentenhance.com/api/update-task/${planId}`, {
              status,
              completed_date: completedDate // This needs to match the key expected by your backend
          });
      } catch (error) {
          console.error('Error updating task:', error);
      }
  };

  const markTaskCompleted = (planId, date) => {
    const newCompletionDates = { ...completionDates, [planId]: date };
    setCompletionDates(newCompletionDates);
    
    // If a date is picked, we update the task to 'completed', otherwise it remains or becomes 'pending'.
    const status = date ? 'completed' : 'pending';
    const completedDate = date || null; // Ensures that the key is always sent, even as null if no date is set
    updateTaskStatusInDatabase(planId, status, completedDate);
};
	  const markTaskRejected = async (planId) => {
		  if (!['admin', 'editor'].includes(currentUserRole)) {
		          alert("You do not have permission to perform this action.");
		          return;
		      }
	      const updatedTasks = tasks.map(task => 
	          task.plan_id === planId ? 
	              {...task, recommendation_status: task.recommendation_status === 'rejected' ? 'pending' : 'rejected'} : 
	              task
	      );
	      setTasks(updatedTasks);
	      const newStatus = updatedTasks.find(task => task.plan_id === planId).recommendation_status;
	      await updateTaskStatusInDatabase(planId, newStatus);
	  };
	  
	  const handleDateChange = (planId, date) => {
		  if (!['admin', 'editor'].includes(currentUserRole)) {
		          alert("You do not have permission to perform this action.");
		          return;
		      }
			  const newCompletionDates = { ...completionDates, [planId]: date };
			      setCompletionDates(newCompletionDates);

			      const status = date ? 'completed' : 'pending';
			      updateTaskStatusInDatabase(planId, status, date || null);
			      updateTasks(planId, status, date || null);
			    };

  const handleCheckboxChange = (index, isChecked) => {
    if (!isChecked && tasks[index].status === 'completed') {
      // Show the modal if they're unchecking a completed task
      setShowModal(true);
      setSelectedTaskIndex(index); // Keep track of which task we're dealing with
    } else {
      // Otherwise, directly mark the task as completed or pending
      const newTasks = [...tasks];
      newTasks[index].status = isChecked ? 'completed' : 'pending';
      setTasks(newTasks);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const confirmUncheck = () => {
    if (selectedTaskIndex !== null) {
      const newTasks = [...tasks];
      newTasks[selectedTaskIndex].status = 'pending';
      setTasks(newTasks);
    }
    closeModal();
  };

  // Function to call when "No" is clicked in the modal
  const handleModalClose = () => {
    // Uncheck the checkbox
    if (selectedTaskIndex !== null) {
      const newTasks = [...tasks];
      newTasks[selectedTaskIndex].status = 'pending';
      setTasks(newTasks);
    }
    setShowModal(false);
  };

  const closeAndResetModal = () => {
    setShowModal(false);
  };
  
  const handleSupportClick = async (planId) => {
	  if (!['admin', 'editor'].includes(currentUserRole)) {
	          alert("You do not have permission to perform this action.");
	          return;
	      }
		  try {
		         // Fetch current number of generates left
		         const countResponse = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/generates-count`);
		         const generatesLeft = countResponse.data.number_of_generates;
        
		         // Confirm action
		         const confirm = window.confirm(`You have ${generatesLeft} generations left. Are you sure you want to use one?`);
		         if (!confirm) return;

      // Set loading true for specific planId
      setIsLoading(prev => ({ ...prev, [planId]: true }));
	  await axios.put(`https://api.contentenhance.com/api/projects/${projectId}/decrement-generates`)

          const response = await axios.post('https://api.contentenhance.com/api/process-generate-request', {
              project_id: projectId,
              url_id: urlId,
              task_id: planId,
          });

          // Assuming the API returns the generated content directly
          setViewGenerated(prev => ({ ...prev, [planId]: true }));
          setGeneratedContent(prev => ({ ...prev, [planId]: response.data }));
		  
		  // Fetch the updated number of generates after decrement
          fetchGeneratesCount();
      } catch (error) {
          console.error('Failed to generate help:', error);
      } finally {
          // Set loading false for specific planId
          setIsLoading(prev => ({ ...prev, [planId]: false }));
      }
  };
  
   
   const handleViewGeneration = (planId) => {
       if (generatedContent[planId]) {
           setModalContent(generatedContent[planId]);  // Set the content of the modal
           setShowModal(true);                        // Open the modal
       } else {
           console.error('No generated content available to display.');
       }
   };
   
   const markdownContainerStyles = {
     padding: '1rem', // You can adjust the values accordingly
     maxHeight: '50vh',
     overflow: 'auto',
     backgroundColor: '#1f2937', // This is Tailwind's gray-800 for example
     marginBottom: '1rem',
     minWidth: '90%'
   };

   const markdownBodyStyles = {
     color: '#e5e7eb', // This is Tailwind's gray-200 for example
     fontSize: '0.875rem', // This is equivalent to 'text-sm' in Tailwind
	 textAlign: 'left'
   };

   const markdownHeaderStyles = {
     fontSize: '1.25rem', // equivalent to 'text-xl' in Tailwind for h1
     fontWeight: 'bold',
     marginBottom: '0.5rem',
	 textAlign: 'center',
   };

   const markdownLinkStyles = {
     color: '#1a0dab',
     textDecoration: 'underline'
   };

   const markdownListStyles = {
     paddingLeft: '1.5rem',
     marginTop: '0.5rem',
     marginBottom: '0.5rem',
	 listStyleType: 'disc' // This creates the traditional bullet point style
   };

   const markdownListItemStyles = {
     marginBottom: '0.25rem'
   };

   const markdownCodeBlockStyles = {
     backgroundColor: '#6a737d',
     border: '1px solid #ccc',
     padding: '0.2em 0.4em',
	 fontSize: '0.875rem',
     marginTop: '0.5rem',
     marginBottom: '0.5rem',
     whiteSpace: 'pre-wrap', // This allows the text to wrap
     overflowWrap: 'break-word', // This will break long words if necessary
     wordBreak: 'break-all' // This ensures the break happens at any character to prevent overflow
   };

   const markdownBlockquoteStyles = {
     borderLeft: '4px solid #ddd',
     paddingLeft: '1em',
     marginTop: '0.5rem',
     marginBottom: '0.5rem',
     color: '#6a737d'
   };
   const markdownParagraphStyles = {
     marginBottom: '1em', // Adjust the value to get the desired space
   };
   const markdownHrStyles = {
     marginTop: '1.5em', // Adjust the value to get the desired space above
     marginBottom: '1.5em', // Adjust the value to get the desired space below
     backgroundColor: '#e5e7eb', // You can set the color to match your scheme
     border: 'none',
     height: '1px', // You can adjust the thickness of the line
   };
   
   
   
   const Modal = ({ isOpen, onClose, content }) => {
       if (!isOpen) return null;

       return (
           <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={onClose}>
               <div className="w-full max-w-3xl p-10 space-y-6 rounded-lg bg-gray-900 shadow-2xl" onClick={(e) => e.stopPropagation()}>
                   <div>
				   <div className="text-center">
                       <h2 className="text-base text-gray-200">Generated support</h2>
		   </div>
                       <div className="relative flex py-4 items-center">
                           <div className="flex-grow border-t border-gray-700"></div>
                       </div>
		 
		    <div style={markdownContainerStyles}> 
		                           <ReactMarkdown className="text-gray-200 text-sm leading-7" children={content} remarkPlugins={[remarkGfm]} 
								   components={{
    // Apply styles to each element type
    h1: ({ node, ...props }) => <h1 style={markdownHeaderStyles} {...props} />,
    a: ({ node, ...props }) => <a style={markdownLinkStyles} {...props} />,
    ul: ({ node, ...props }) => <ul style={markdownListStyles} {...props} />,
    li: ({ node, ...props }) => <li style={markdownListItemStyles} {...props} />,
    code: ({ node, ...props }) => <code style={markdownCodeBlockStyles} {...props} />,
    blockquote: ({ node, ...props }) => <blockquote style={markdownBlockquoteStyles} {...props} />,
	p: ({ node, ...props }) => <p style={markdownParagraphStyles} {...props} />,
	hr: ({ node, ...props }) => <hr style={markdownHrStyles} {...props} />,
    // ... define other elements as needed
  }}
  style={markdownBodyStyles}/>
  
		                       </div>
                       <div className="items-center px-4 py-3">
                           <button
                               className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                               onClick={onClose}
                           >
                               Close
                           </button>
                       </div>
                   </div>
               </div>
           </div>
       );
   };
  // Inline styles
  const styles = {
    completed: {
      textDecoration: 'none',
      color: 'green',
    },
    rejected: {
      textDecoration: 'line-through',
    },
    button: {
      // Add fixed width and adjust other styles as needed
      width: '100px', // Set a fixed width
      border: '1px solid #ddd', // Optional: Add border
      padding: '10px', // Adjust padding
      background: '#1e3a8a', // Adjust background color
      cursor: 'pointer',
      textAlign: 'center',
    },

      
      
		
   
    input: {
		flexGrow: 1, // Input takes the remaining space
      marginRight: '10px', // Add margin between input and button
      height: '50px', // Increase height of input box
      padding: '10px', // Adjust padding for better appearance
		marginTop: '20px',
		
		backgroundColor: '#191A23',
		color: '#ffffff'
    },
    inputContainer: {
      display: 'flex',
      justifyContent: 'center', // Center the button
		width: '90%',
		alignItems: 'center',
		marginTop: '10px', // Add some margin at the top
		color: '#ffffff'
    },
    addButton: {
      // Ensure it matches the width of the accept/reject section
      width: '100px', // Match the width with the reject/accept buttons
      alignSelf: 'center', // Center button vertically in its container
		height: '50px',
		marginTop: '20px',
    },
    taskCell: {
      position: 'relative', // Set the cell to be relative for absolute positioning of children
      textDecoration: 'none', // Default text decoration
      maxWidth: '200px', // Set a maximum width for the cell
    },
    editDeleteContainer: {
      position: 'absolute',
      bottom: '10px',
      right: '10px',
      display: 'flex', // Use flexbox for layout
      gap: '10px', // Space between buttons
    },
    editDeleteButton: {
      // Styles for edit and delete buttons
      cursor: 'pointer',
      
      
      padding: '5px 10px', // Adjust padding
      // Other button styles...
    },
	saveButtonStyle: {
	    backgroundColor: '#1e3a8a', // Green color
	   
	    border: 'none',
	    padding: '10px 15px',
	    margin: '5px',
	    borderRadius: '5px',
	    cursor: 'pointer',
	   
	},

	// Cancel button style
	cancelButtonStyle: {
	    backgroundColor: '#1e3a8a', // Red color
	    border: 'none',
	    padding: '10px 15px',
	    margin: '5px',
	    borderRadius: '5px',
	    cursor: 'pointer',
	    
	},
	
	editInputStyle: {
	    flexGrow: 1, // Allows the input to grow to fill available space
	    width: '100%', // Makes the input take the full width of its container
	    height: '80px', // Increase height to allow more room for text
	    padding: '10px', // Padding for better text alignment
	    marginRight: '10px', // Keeps a margin between input and buttons
	    border: '1px solid #ccc', // Adds a subtle border to the input
	    backgroundColor: '#f5f5f5', // Light gray color
	    color: '#333', // Darker text for better contrast
	    resize: 'none', // Allows resizing if needed (remove this if you don't want resizing)
	    overflowY: 'auto', // Ensures scrollable content when overflowing
	    borderRadius: '5px', // Rounded corners for aesthetics
	},
		selectInput: {
		    backgroundColor: 'transparent', // Make the main element background transparent
		    color: '#ffffff',
		    padding: '10px',
		    borderRadius: '5px',
		    appearance: 'none',
		    position: 'relative', // Needed for custom dropdown arrow
		  },

		  // New styles for dropdown
		  selectDropdown: {
		    '&::-webkit-listbox': {
		      backgroundColor: '#191A23',
		      border: '1px solid #ffffff',
		      borderRadius: '5px',
		      padding: '5px',
		    },
		    '& option': {
		      backgroundColor: '#191A23',
		      color: '#ffffff',
		      padding: '5px',
		    },
		    '& option:hover': {
		      backgroundColor: '#2a2b36',
		    },
		  },
	    dateInput: {
	      // ... existing styles for date input, if any ...
	      backgroundColor: 'transparent', // Set background to transparent
	      color: 'white', // Set text color to white or any other color
	      padding: '10px', // Padding for visual comfort
	      borderRadius: '5px', // Border radius for rounded corners
	      // Add other styles if needed
	    },
  };
  const oddRowStyle = {
          backgroundColor: '#191A23'// Light gray background for odd rows
     };
     const evenRowStyle = {
         backgroundColor: 'rgb(18 24 39 / var(--tw-bg-opacity))' // White background for even rows
     };
  return (
<div>

			<Modal isOpen={showModal} onClose={() => setShowModal(false)} content={modalContent} />
			<table className="text-gray-900 w-full text-sm min-w-[1200px]">
	                            <thead className="text-sm h-[50px]">
	                                <tr className="sticky -top-10 bg-blue-500 z-10">
                <th className="text-center bg-blue-900 text-white font-normal"><div className="flex items-center justify-center gap-x-0.5">
<IconButton size="small" style={{ color: 'white' }}>
          <ChecklistIcon fontSize="inherit" />
        </IconButton>Task</div></th>
	  <th className="text-center bg-blue-900 text-white font-normal"><div className="flex items-center justify-center gap-x-0.5">
<IconButton size="small" style={{ color: 'white' }}>
          <PersonAddIcon fontSize="inherit" />
        </IconButton>Lead User Assigned</div></th>
                <th className="text-center bg-blue-900 text-white font-normal"><div className="flex items-center justify-center gap-x-0.5">
<IconButton size="small" style={{ color: 'white' }}>
          <CalendarMonthIcon fontSize="inherit" />
        </IconButton>Date Set Live</div></th>
	  <th className="text-center bg-blue-900 text-white font-normal">
	  <div className="flex items-center justify-center gap-x-0.5">
<IconButton size="small" style={{ color: 'white' }}>
          <SupportAgentIcon fontSize="inherit" /></IconButton>
	  Task Support
	  </div></th>
	  <th className="text-center bg-blue-900 text-white font-normal"><div className="flex items-center justify-center gap-x-0.5">
<IconButton size="small" style={{ color: 'white' }}>
          <CloseIcon fontSize="inherit" />
        </IconButton>Reject Task</div></th>
            </tr>
        </thead>
        <tbody>
	  {tasks.map((task, index) => (
	                          <tr className="h-[150px] text-white"  key={task.plan_id} 
	                              style={index % 2 === 0 ? evenRowStyle : oddRowStyle} onMouseEnter={() => setHoveredTaskId(task.plan_id)} onMouseLeave={() => setHoveredTaskId(null)}>
		    <td className="text-center p-4" 
		        style={{ 
		          ...styles.taskCell,
		          textDecoration: task.recommendation_status === 'rejected' ? 'line-through' : 'none'
		        }} 
		        onMouseEnter={() => setHoveredTaskId(task.plan_id)} 
		        onMouseLeave={() => setHoveredTaskId(null)}>
                        
				{editingTaskId === task.plan_id ? (
				    <>
				        <textarea
				            value={editingTaskId === task.plan_id ? editedTaskText : ''}
				            onChange={(e) => setEditedTaskText(e.target.value)}
				            style={styles.editInputStyle}
				        />
				        <button onClick={() => saveEditedTask(task.plan_id)} style={styles.saveButtonStyle}>Save</button>
				        <button onClick={() => {
				            setEditingTaskId(null);
				            setNewTaskText(''); // Optionally reset the input or keep the last edited text
				        }} style={styles.cancelButtonStyle}>Cancel</button>
				    </>
				) : (
				    <span>{task.recommendations || 'No recommendation'}</span>
				)}
				        {hoveredTaskId === task.plan_id && (
				          <div style={styles.editDeleteContainer}>
							<button style={styles.editDeleteButton} onClick={() => {
							    setEditingTaskId(task.plan_id);
							    setEditedTaskText(task.recommendations); // Correct function name
							}}>Edit</button>
				            <button style={styles.editDeleteButton} onClick={() => deleteTaskFromDatabase(task.plan_id)}>Delete</button>
				          </div>
				        )}
				      </td>

						<td className="text-center">
					<select style={styles.selectInput}
					    value={task.assigned_user_id || ''}
					    onChange={(e) => handleUserAssignment(task.plan_id, e.target.value)}
					>
					    <option value="">Select User</option>
					    {users.map(user => {
					        const username = user.email.split('@')[0];
					        return <option key={user.user_id} value={user.user_id}>{username}</option>;
					    })}
					</select>
						</td>
                     <td className="text-center">
	   <input
	                    type="date"
	                    value={completionDates[task.plan_id] || ''}
	                    onChange={(e) => handleDateChange(task.plan_id, e.target.value)}
	                    style={styles.dateInput}
	                  />
							</td>
				<td className="text-center">
				            {isLoading[task.plan_id] ? (
								<div className="flex justify-center items-center h-full">
				                <SpinnerComponent />
									</div>
				            ) : viewGenerated[task.plan_id] ? (
				                <button className="w-44 px-4 py-2 bg-cyan-600 text-white text-sm font-medium rounded-md shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
				                    onClick={() => handleViewGeneration(task.plan_id)}>View generation</button>
				            ) : (
				                <button className="w-44 px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
				                    onClick={() => handleSupportClick(task.plan_id)}>Generate further help</button>
				            )}
				        </td>
							 <td className="text-center">
                        <input
                            type="checkbox"
                            checked={task.recommendation_status === 'rejected'}
                            onChange={(e) => markTaskRejected(task.plan_id)}
                        /> Reject Task
                    </td>
                </tr>
            ))}
<tr>
                       <td className="text-center text-white" style={styles.inputContainer}>
                           <input 
                               type="text"
                               value={newTaskText}
                               onChange={(e) => setNewTaskText(e.target.value)}
                               placeholder="Enter a new task"
                               style={styles.input} // Style as needed
                           />
                   
                           <button 
                               className="p-2 bg-blue-900 text-white rounded hover:bg-blue-500" 
                               onClick={addTask}
                               style={styles.addButton} // Style as needed
                           >
                               Add
                           </button>
                       </td>
                   </tr>
               </tbody>
           </table>
		
     
    </div>
  );
};

export default Checklist;