import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ResponsiveSankey } from '@nivo/sankey';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import axios from 'axios';

const SankeyDiagramComponent = ({ url, nextUrls, topReferringUrls, topSourceMediums, totalSessions }) => {
    const { urlId } = useParams();
    const [nextUrlsState, setNextUrlsState] = useState(nextUrls);
    const [currentSource, setCurrentSource] = useState(null);
    const [isInteractiveData, setIsInteractiveData] = useState(false);
    const [currentSourceSessions, setCurrentSourceSessions] = useState(null);

    const fetchNextUrls = async (source) => {
        try {
            const response = await axios.get('https://api.contentenhance.com/api/next-url-modifier', {
                params: { source, urlId },
                withCredentials: true 
            });
            if (response.data && response.data.length > 0) {
                setNextUrlsState(response.data);
                setIsInteractiveData(true);
            } else {
                setNextUrlsState([]);
                setIsInteractiveData(true);
            }
        } catch (error) {
            console.error('Error fetching next URLs:', error);
            setNextUrlsState([]);
            setIsInteractiveData(true);
        }
    };

    const handleClick = (item) => {
        let source = '';
        let sourceSessions = 0;

        if (item.id && item.id.startsWith('Referrer-')) {
            source = item.label.split(' (')[0].trim();
            sourceSessions = item.value;
        } else if (item.source && item.source.id.startsWith('Referrer-')) {
            source = item.source.label.split(' (')[0].trim();
            sourceSessions = item.value;
        } else {
            return;
        }

        if (source === "(direct) / (none)" || source === "(direct) /") {
            source = "(direct) / (none)";
        }

        setCurrentSource(source);
        setCurrentSourceSessions(sourceSessions);
        fetchNextUrls(source);
    };

    const resetState = () => {
        setNextUrlsState(nextUrls);
        setCurrentSource(null);
        setCurrentSourceSessions(null);
        setIsInteractiveData(false);
    };

    if ((!nextUrlsState || nextUrlsState.length === 0) && 
        (!topReferringUrls || topReferringUrls.length === 0) && 
        (!topSourceMediums || topSourceMediums.length === 0)) {
        return null;
    }

    const combinedReferrals = [
        ...(topReferringUrls || []).filter(ref => ref.referring_url !== 'Other').slice(0, 5),
        ...(topSourceMediums || []).filter(src => src.source_medium !== 'Other').slice(0, 5)
    ].map(item => ({ referrer: item.source_medium || item.referring_url, sessions: item.sessions }));
    
    const filteredReferrals = currentSource
        ? combinedReferrals.filter(ref => ref.referrer === currentSource)
        : combinedReferrals;

    const dedupedCombinedReferrals = Array.from(new Set(filteredReferrals.map(item => item.referrer)))
        .map(referrer => filteredReferrals.find(item => item.referrer === referrer));

    // Function to scale next URL sessions proportionally to match the source sessions
		const scaleNextUrlSessions = (nextUrls, sourceSessions) => {
		    const totalNextSessions = nextUrls.reduce((acc, nextUrl) => acc + nextUrl.sessions, 0);

		    // If next URLs' total sessions exceed the source, scale them down proportionally
		    if (totalNextSessions > sourceSessions) {
		        return nextUrls.map(nextUrl => ({
		            ...nextUrl,
		            scaledSessions: Math.round((nextUrl.sessions / totalNextSessions) * sourceSessions) // Round scaled sessions
		        }));
		    }

		    // If next URLs' sessions don't exceed the source, keep them as they are, but round the sessions
		    return nextUrls.map(nextUrl => ({
		        ...nextUrl,
		        scaledSessions: Math.round(nextUrl.sessions) // Round unscaled sessions
		    }));
		};
    
    // Updated calculation for percentage
    const calculatePercentage = (sessions, total) => {
        const percentage = (sessions / total) * 100;
        return percentage < 0.5 ? percentage.toFixed(1) : Math.round(percentage);
    };

    const effectiveTotalSessions = Math.max(
        filteredReferrals.reduce((acc, item) => acc + item.sessions, 0),
        totalSessions,
        nextUrlsState.reduce((acc, nextUrl) => acc + nextUrl.sessions, 0)
    );

    const sumOfSessions = nextUrlsState.reduce((acc, nextUrl) => acc + (nextUrl.sessions || 0), 0);

    const dedupedNextUrls = Array.from(new Set(
        (nextUrlsState || []).map(nextUrl => nextUrl.next_url)
    )).map(next_url => nextUrlsState.find(item => item.next_url === next_url))
    .slice(0, 10);

    // Scale next URLs only if there is interactive data (after click)
    const scaledNextUrls = isInteractiveData 
        ? scaleNextUrlSessions(dedupedNextUrls, currentSourceSessions)  // After click: scale next URLs' sessions
        : dedupedNextUrls;  // Before click: use original nextUrls without scaling
    
    const otherReferralsSessions = effectiveTotalSessions - filteredReferrals.reduce((acc, item) => acc + item.sessions, 0);

    let exitUnknownSessions, exitUnknownPercentage;

    if (isInteractiveData) {
        exitUnknownSessions = currentSourceSessions - sumOfSessions;
        exitUnknownPercentage = calculatePercentage(exitUnknownSessions, currentSourceSessions);
    } else {
        exitUnknownSessions = effectiveTotalSessions - sumOfSessions;
        exitUnknownPercentage = calculatePercentage(exitUnknownSessions, effectiveTotalSessions);
    }

    const formatPercentage = (percentage) => {
        return percentage < 0.5 ? percentage.toFixed(1) : Math.round(percentage);
    };

    const nodes = [
		...dedupedCombinedReferrals.map((ref, index) => ({
		        id: `Referrer-${ref.referrer}-${index}`,
		        label: `${ref.referrer} (${calculatePercentage(ref.sessions, effectiveTotalSessions)}%)`,
		        value: Math.round(ref.sessions) // Round the sessions here
		    })),
		    { id: `URL-${url}`, label: url },
		    ...scaledNextUrls.map((nextUrl, index) => ({
		        id: `NextURL-${nextUrl.next_url}-${index}`,
		        label: `${nextUrl.next_url} (${calculatePercentage(isInteractiveData ? nextUrl.scaledSessions : nextUrl.sessions, currentSourceSessions || effectiveTotalSessions)}%)`,
		        value: isInteractiveData ? Math.round(nextUrl.scaledSessions) : Math.round(nextUrl.sessions) // Round scaled/unscaled sessions
		    })),
        ...(exitUnknownPercentage > 0 ? [{ id: 'Drop-off', label: `Exit / Unknown (${formatPercentage(exitUnknownPercentage)}%)` }] : []),
        ...(currentSource ? [] : [{ id: 'Other-Referrals', label: `Other (${calculatePercentage(otherReferralsSessions, effectiveTotalSessions)}%)` }])
    ];

    const links = [
		...dedupedCombinedReferrals.map((ref, index) => ({
		        source: `Referrer-${ref.referrer}-${index}`,
		        target: `URL-${url}`,
		        value: Math.round(ref.sessions) // Round the sessions here
		    })),
		    ...scaledNextUrls.map((nextUrl, index) => ({
		        source: `URL-${url}`,
		        target: `NextURL-${nextUrl.next_url}-${index}`,
		        value: isInteractiveData ? Math.round(nextUrl.scaledSessions) : Math.round(nextUrl.sessions) // Round scaled/unscaled sessions
		    })),
        ...(exitUnknownPercentage > 0 ? [{
            source: `URL-${url}`,
            target: 'Drop-off',
            value: exitUnknownSessions
        }] : []),
        ...(currentSource ? [] : [{
            source: 'Other-Referrals',
            target: `URL-${url}`,
            value: otherReferralsSessions
        }])
    ];

    const CustomLinkTooltip = ({ link }) => (
        <div style={{ color: link.color, background: 'black', padding: '5px 10px', borderRadius: '3px' }}>
            {link.value} sessions
        </div>
    );

    return (
        <div className="sankey-diagram-container text-white text-sm font-normal" style={{ height: 350, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '100%' }}>
                <div className="flex justify-center mt-8 items-center">
                    <h3 className="text-center text-white text-base">Full Journey Flow</h3>
                    <Tooltip title="This diagram shows the flow of traffic from different sources to the main URL and their subsequent onward journeys. The referring URL part will be very accurate. The next URL will slightly depend on how your GA4 is setup - it may only pick up on external links.">
                        <IconButton size="small" style={{ color: 'grey', marginLeft: '8px' }}>
                            <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.9rem' }} />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="flex justify-center items-center mt-2">
                    <button
                        className="bg-gray-700 text-white px-3 py-1 text-sm rounded-md hover:bg-gray-900"
                        onClick={resetState}
                    >
                        Reset
                    </button>
                </div>
                <ResponsiveSankey
                    data={{ nodes, links }}
                    margin={{ top: 10, right: 340, bottom: 60, left: 340 }}
                    align="justify"
                    colors={{ scheme: 'pastel2' }}
                    nodeOpacity={1}
                    nodeHoverOthersOpacity={0.35}
                    nodeThickness={6}
                    nodeSpacing={8}
                    nodeBorderWidth={0}
                    nodeBorderColor={{ from: 'color', modifiers: [['brighter', 0.8]] }}
                    linkOpacity={0.5}
                    linkHoverOthersOpacity={0.1}
                    linkContract={3}
                    linkBlendMode="normal"
                    labelPosition="outside"
                    labelPadding={2}
                    labelTextColor="#ffffff"
                    label={(node) => node.label}
                    layers={['links', 'nodes', 'labels', 'legends']}
                    tooltip={CustomLinkTooltip}
                    theme={{
                        tooltip: {
                            container: {
                                background: 'black',
                            },
                        },
                    }}
                    onClick={handleClick}
                />
            </div>
        </div>
    );
};

export default SankeyDiagramComponent;
