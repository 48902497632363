// GoogleOAuthButtonManage.js
import React from 'react';
import googlelogo from './google-icon.png';

const GoogleOAuthButtonThree = ({ organizationId }) => {  // Destructure projectId from props
    const handleConnect = () => {
        // Include the projectId in the OAuth URL query string
        window.location.href = `https://api.contentenhance.com/google-login-three?organization_id=${organizationId}`; 
    };

    return (
        <button onClick={handleConnect} className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700">
            <img src={googlelogo} alt="Google" className="h-5 w-5 mr-2" />
            Connect your Google account
        </button>
    );
}

export default GoogleOAuthButtonThree;