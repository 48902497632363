import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Sidebar from './Sidebar';
import TopBar from './TopBar';

const AddUserOrganization = () => {
    const { organizationId } = useParams();
    const [email, setEmail] = useState('');
    const [userRole, setUserRole] = useState('');
    const [role, setRole] = useState('member');
    const [message, setMessage] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUserIds, setSelectedUserIds] = useState(new Set());
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
                if (!response.data.isAuthenticated) {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                navigate('/login');
            }
        };

        checkAuthentication();
    }, [navigate]);

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                const response = await axios.get(`https://api.contentenhance.com/api/organizations/${organizationId}/check_role`, { withCredentials: true });
                setUserRole(response.data.role);
            } catch (error) {
                console.error('Error fetching user role:', error);
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate('/login');
                    } else if (error.response.status === 403) {
                        setErrorMessage('You do not have permission to access this resource.');
                    }
                }
            }
        };

        fetchUserRole();
    }, [organizationId, navigate]);

    useEffect(() => {
        fetchUsers();
    }, [organizationId]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`https://api.contentenhance.com/api/organizations/${organizationId}/users`);
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleCheckboxChange = (userId) => {
        setSelectedUserIds(prevSelectedUserIds => {
            const newSelectedUserIds = new Set(prevSelectedUserIds);
            if (newSelectedUserIds.has(userId)) {
                newSelectedUserIds.delete(userId);
            } else {
                newSelectedUserIds.add(userId);
            }
            return newSelectedUserIds;
        });
    };

    const handleSubmitDelete = async () => {
        if (userRole !== 'admin') {
            alert("You don't have permission to remove users.");
            return;
        }
        try {
            await Promise.all(Array.from(selectedUserIds).map(userId =>
                axios.post(`https://api.contentenhance.com/api/organizations/${organizationId}/remove_user`, { user_id: userId })
            ));

            setSelectedUserIds(new Set());
            fetchUsers();
        } catch (error) {
            console.error('Error removing users:', error);
        }
    };

    const handleRoleChangeWrapper = (userId) => (newRole) => {
        handleRoleChange(userId, newRole);
    };

    const handleRoleChange = async (userId, newRole) => {
        if (userRole !== 'admin') {
            alert("You don't have permission to change user roles.");
            return;
        }
        try {
            const response = await axios.post('https://api.contentenhance.com/api/update_user_organization_role', {
                user_id: userId,
                organizationId: organizationId,
                role: newRole,
            });

            const updatedUsers = users.map(user => {
                if (user.user_id === userId) {
                    return { ...user, role: newRole };
                }
                return user;
            });
            setUsers(updatedUsers);
        } catch (error) {
            console.error('Error updating user role:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (userRole !== 'admin') {
            alert("You don't have permission to add users.");
            return;
        }
        try {
            const response = await axios.post('https://api.contentenhance.com/api/associate_user_to_organization', {
                email,
                organization_id: organizationId,
                role
            });
            setMessage(response.data.message);
            setEmail('');
            setRole('member');
            fetchUsers();
            setShowModal(false);
        } catch (error) {
            setMessage(error.response ? error.response.data.error : 'Server error');
        }
    };

    const toggleModal = () => {
        if (userRole !== 'admin') {
            alert("You don't have permission to add users.");
            return;
        }
        setShowModal(!showModal);
    };

    const isAnyUserSelected = selectedUserIds.size > 0;

    const oddRowStyle = {
        backgroundColor: '#191A23'
    };
    const evenRowStyle = {
        backgroundColor: 'rgb(18 24 39 / var(--tw-bg-opacity))'
    };
    const styles = {
		selectInput: {
		    backgroundColor: 'transparent', // Make the main element background transparent
		    color: '#ffffff',
		    padding: '10px',
		    borderRadius: '5px',
		    appearance: 'none',
		    position: 'relative', // Needed for custom dropdown arrow
		  },

		  // New styles for dropdown
		  selectDropdown: {
		    '&::-webkit-listbox': {
		      backgroundColor: '#191A23',
		      border: '1px solid #ffffff',
		      borderRadius: '5px',
		      padding: '5px',
		    },
		    '& option': {
		      backgroundColor: '#191A23',
		      color: '#ffffff',
		      padding: '5px',
		    },
		    '& option:hover': {
		      backgroundColor: '#2a2b36',
		    },
		  },
    };

    return (
        <div className="flex flex-col h-screen bg-darkBlue">
            <TopBar />
            <div className="flex h-screen bg-lighterDarkBlue text-white">
                <Sidebar />
                <div className="flex-1 bg-gray-850 p-10 overflow-auto">
                    <h1 className="text-white text-base mb-8">Add organization member</h1>
                    <div className="flex justify-center">
                        <button className="px-6 py-2 bg-blue-600 text-white text-sm rounded hover:bg-blue-900 flex items-center space-x-1" onClick={toggleModal}>
                            <AddCircleIcon sx={{ color: '#ffffff', mr: 1 }} fontSize="small" />
                            Add User
                        </button>
                    </div>

                    {errorMessage && <div className="text-center my-4 p-2 bg-gray-700 rounded text-sm text-red-500">{errorMessage}</div>}

                    {showModal && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div className="w-full max-w-md p-4 space-y-2 rounded-lg bg-gray-900 shadow-2xl" style={{ maxWidth: '600px' }}>
                                <button onClick={toggleModal} className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800 text-3xl p-2">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h2 className="text-base text-gray-200 text-center">Add User</h2>
                                <div className="relative flex py-4 items-center">
                                    <div className="flex-grow border-t border-gray-700"></div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-200">Email Address</label>
                                        <input
                                            autoFocus
                                            id="email"
                                            type="email"
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="appearance-none text-sm w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-700"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="role-select" className="block text-sm font-medium text-gray-200 text-sm">Role</label>
                                        <select
                                            id="role-select"
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                            className="appearance-none w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-700 text-sm"
                                        >
                                            <option value="admin">Admin</option>
                                            <option value="member">Member</option>
                                        </select>
                                    </div>

                                    <div className="flex items-center justify-end space-x-2">
                                        <button onClick={toggleModal} className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-200 bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500">
                                            Cancel
                                        </button>
                                        <button type="submit" className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    <div className="p-4 rounded">
                        <table className="min-w-full">
                            <thead className="bg-blue-900">
                                <tr>
                                    <th className="text-center text-sm bg-blue-900 text-white font-normal p-4">Email</th>
                                    <th className="text-center text-sm bg-blue-900 text-white font-normal p-4">Role</th>
                                    <th className="text-center text-sm bg-blue-900 text-white font-normal p-4">Remove from organization</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) => (
                                    <tr key={user.user_id} style={index % 2 === 0 ? evenRowStyle : oddRowStyle}>
                                        <td className="text-sm text-center p-4">{user.email}</td>
                                        <td className="text-sm text-center p-4">
                                            <select style={styles.selectInput}
                                                value={user.role}
                                                onChange={(e) => handleRoleChangeWrapper(user.user_id)(e.target.value)}
                                            >
                                                <option value="admin">Admin</option>
                                                <option value="member">Member</option>
                                            </select>
                                        </td>
                                        <td className="text-sm text-center p-4">
                                            <div className="text-center pr-4 pl-4 text-sm">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedUserIds.has(user.user_id)}
                                                    onChange={() => handleCheckboxChange(user.user_id)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {message && <div className="text-center my-4 p-2 bg-gray-700 rounded text-sm">{message}</div>}
                </div>

                {isAnyUserSelected && (
                    <div className="fixed bottom-0 left-0 w-full py-2 px-4 bg-blue-900">
                        <div className="flex justify-end">
                            <button
                                className="bg-white text-sm text-blue-900 py-2 px-4 rounded mr-4"
                                onClick={handleSubmitDelete}
                            >
                                Remove users
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddUserOrganization;
