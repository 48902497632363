import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from './contentEnhance-logo.png';
import googleicon from "./google-icon.png"

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate(); // useNavigate for redirection
	
    useEffect(() => {
        // Detect if the user is in an embedded browser
        if (isInEmbeddedBrowser()) {
          setMessage('For security reasons, please open this page in your default browser.');
          setTimeout(() => {
            // Redirect to open in default browser
            window.location.href = `https://api.contentenhance.com/signup`; 
          }, 3000); // Adjust delay as needed
        }
      }, []);
  
    const handleGoogleLogin = () => {
      window.location.href = `https://api.contentenhance.com/google-logup`;
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      setMessage(''); // Clear any existing messages

      try {
        const response = await axios.post('https://api.contentenhance.com/api/login', { email, password }, { withCredentials: true });
        if (response.status === 200 && response.data.message) {
          // Login is successful
          checkOrganizationMembership();
        } else if (response.data.error) {
          // Login failed with a message from the server
          setMessage(response.data.error);
        }
      } catch (error) {
        // Network error or the request was blocked, etc.
        setMessage(error.response?.data?.error || 'Login failed. Please try again later.');
      }
    };

    const checkOrganizationMembership = async () => {
      try {
        const response = await axios.get('https://api.contentenhance.com/api/user_organization_check', { withCredentials: true });

        if (response.status === 200 && response.data.isInOrganization) {
          navigate('/workspaces');
        } else {
          navigate('/projects');
        }
      } catch (error) {
        // Handle error appropriately
        console.error('Error checking organization membership:', error);
        navigate('/projects'); // Fallback to /projects in case of error
      }
    };
	
	function isInEmbeddedBrowser() {
	  const ua = navigator.userAgent || navigator.vendor || window.opera;

	  // Check for common embedded browsers (WebView)
	  const embeddedBrowsers = [
	    'FBAN', 'FBAV',       // Facebook
	    'Instagram',          // Instagram
	    'Line',               // Line
	    'WeChat',             // WeChat
	    'Twitter',            // Twitter
	    'Snapchat',           // Snapchat
	    'wv',                 // Generic WebView identifier
	    'LinkedIn',           // LinkedIn
	    'WhatsApp',           // WhatsApp
	    'Telegram',           // Telegram
	    'TikTok'              // TikTok
	  ];

	  return embeddedBrowsers.some(browser => ua.includes(browser));
	}

  return (
 <div className="flex items-center justify-center h-screen bg-darkBlue">
      <div className="w-full max-w-md p-8 space-y-6 rounded-lg bg-gray-900 shadow-2xl">
	  <div className="text-center">
          <img src={logo} alt="contentEnhance Logo" className="h-12 mx-auto" />
	  <div className="relative flex py-5 items-center">
	  <div className="flex-grow border-t border-gray-700"></div>
	  </div>
        <h1 className="text-lg text-center text-white">Login</h1>
	  </div>
        <button
	  onClick={handleGoogleLogin} // Add the onClick event handler 
	  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700">
          <img src={googleicon} alt="Google" className="h-5 w-5 mr-3" />
          Login with Google
        </button>
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-gray-700"></div>
          <span className="flex-shrink mx-4 text-gray-400">OR</span>
          <div className="flex-grow border-t border-gray-700"></div>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <input
	          name="email"
              type="email"
              id="email"
              autoComplete="email"
              required
              placeholder="Enter your email address"
              className="appearance-none w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-white bg-gray-700 text-sm"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <input
			  name="password"
              type="password"
              id="password"
              autoComplete="new-password"
              required
              placeholder="Enter your password"
              className="appearance-none w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-white bg-gray-700 text-sm"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300">
            Login
         
</button>
        </form>
        {message && <p className="text-center text-red-500">{message}</p>}
        <div className="text-center text-sm text-gray-400 mt-4">
          <a href="/forgotten-password" className="text-blue-500 hover:underline">Forgotten password?</a>
        </div>
        <div className="text-center text-sm text-gray-400 mt-4">
          Don't have an account yet? <a href="/signup" className="text-blue-500 hover:underline">Sign up</a>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;