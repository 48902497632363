import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController
);

function percentile(arr, p) {
  if (arr.length === 0) return 0;
  if (p <= 0) return arr[0];
  if (p >= 1) return arr[arr.length - 1];
  const sortedArr = [...arr].sort((a, b) => a - b);
  const index = (sortedArr.length - 1) * p;
  const lower = Math.floor(index);
  const upper = lower + 1;
  const weight = index % 1;
  return upper >= sortedArr.length ? sortedArr[lower] : sortedArr[lower] * (1 - weight) + sortedArr[upper] * weight;
}

function arrayToCSV(data) {
  const csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(','));
  for (const row of data) {
    const values = headers.map(header => `"${String(row[header]).replace(/"/g, '""')}"`);
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
}

const smoothData = (data) => {
  const smoothedData = [];
  const smoothingFactor = 0.1;
  data.reduce((previous, current) => {
    const smoothedValue = Math.round(previous + (current - previous) * smoothingFactor);
    smoothedData.push(smoothedValue);
    return smoothedValue;
  }, data[0]);
  return smoothedData;
};

// Pettitt's Test to detect a single change point
function pettittsTest(data) {
  const n = data.length;
  let K = new Array(n).fill(0);

  for (let t = 0; t < n; t++) {
    let s = 0;
    for (let i = 0; i <= t; i++) {
      for (let j = t + 1; j < n; j++) {
        if (data[j] > data[i]) {
          s += 1;
        } else if (data[j] < data[i]) {
          s -= 1;
        }
      }
    }
    K[t] = s;
  }

  const Kmax = Math.max(...K.map(Math.abs));
  const KmaxIndex = K.findIndex((k) => Math.abs(k) === Kmax);

  const nFloat = parseFloat(n);
  const pValue = 2 * Math.exp((-6 * Kmax ** 2) / (nFloat ** 3 + nFloat ** 2));

  return { changePoint: KmaxIndex, pValue };
}

// Recursive function to find multiple change points
function findMultipleChangePoints(data, significanceLevel = 0.05) {
  const changePoints = [];

  const recursivePettittTest = (data) => {
    const { changePoint, pValue } = pettittsTest(data);

    if (pValue < significanceLevel && changePoint !== -1) {
      changePoints.push({ changePoint, pValue });
      recursivePettittTest(data.slice(0, changePoint));
    }
  };

  recursivePettittTest(data);

  return changePoints;
}

const CombinationChart = () => {
  const { projectId } = useParams();
  const [metricType, setMetricType] = useState('total');
  const [metricView, setMetricView] = useState('engagement_time');
  const [chartData, setChartData] = useState({ labels: [], datasets: [], yMax: 0 });
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 90)));
  const [endDate, setEndDate] = useState(new Date());
  const [fullData, setFullData] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [showSmoothedData, setShowSmoothedData] = useState(true);
  const [isChangePointSignificant, setIsChangePointSignificant] = useState(false);

  const handleDownloadCSV = () => {
    const currentData = chartData.datasets[0].data.map((value, index) => ({
      date: chartData.labels[index],
      engagement_time: value,
      sessions: chartData.datasets[1].data[index]
    }));
    const csvData = arrayToCSV(currentData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${metricType}-data.csv`;
    link.click();
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.contentenhance.com/api/enhanced-urls/summary/${projectId}/${metricType}`, { withCredentials: true });
        let fetchedData = response.data;

        const tasksResponse = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/completed-tasks`, { withCredentials: true });
        const fetchedTasks = tasksResponse.data;

        setFullData(fetchedData);
        setCompletedTasks(fetchedTasks);  // Store completed tasks in state
        updateChartData(fetchedData, fetchedTasks, startDate, endDate);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [projectId, metricType]);

  const updateChartData = (data, tasks, startDate, endDate) => {
    const filteredData = data.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
    });

    const labels = filteredData.map(item => item.date);
    const engagementTimeData = filteredData.map(item => item.average_engagement_time);
    const sessionsData = filteredData.map(item => item.sessions);

    const totalEngagedSessions = filteredData.map(item => item.sessions * item.engagement_rate);
    const totalSessions = filteredData.map(item => item.sessions);
    const aggregatedEngagementRateData = totalEngagedSessions.map((engagedSessions, index) => {
      return totalSessions[index] ? (engagedSessions / totalSessions[index]) * 100 : 0;
    });

    const metricData = metricView === 'engagement_time' ? engagementTimeData : aggregatedEngagementRateData;
    let chartMetricData = metricData;

    if (showSmoothedData) {
      chartMetricData = smoothData(metricData);
    }

    const yMaxRaw = Math.max(...metricData);
    const yMaxSmoothed = Math.max(...chartMetricData);
    const yMax = showSmoothedData ? yMaxSmoothed : yMaxRaw;

    const changePoints = findMultipleChangePoints(metricData);
    setIsChangePointSignificant(changePoints.length > 0);

    const backgroundColors = chartMetricData.map((_, index) => {
      const isChangePoint = changePoints.some((cp) => cp.changePoint === index);
      return isChangePoint ? '#ff6961' : (metricView === 'engagement_time' ? '#CDA2D3' : '#FFFFE0');
    });

    setChartData({
      labels,
      datasets: [
        {
          type: 'bar',
          label: metricView === 'engagement_time' ? 'Engagement Time' : 'Engagement Rate',
          backgroundColor: backgroundColors,
          data: chartMetricData
        },
        {
          type: 'line',
          label: 'Sessions',
          borderColor: 'lightblue',
          backgroundColor: 'rgba(173, 216, 230, 0.5)',
          data: sessionsData,
          fill: false,
          yAxisID: 'y1'
        }
      ],
      yMax: yMax
    });
  };

  useEffect(() => {
    if (fullData.length) {
      updateChartData(fullData, completedTasks, startDate, endDate);
    }
  }, [startDate, endDate, metricView, showSmoothedData]);

  const options = {
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: 'white'
        },
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        beginAtZero: true,
        min: 0,
        max: chartData.yMax,
        grid: {
          display: false
        },
        ticks: {
          color: metricView === 'engagement_time' ? '#CDA2D3' : '#FFFFE0',
          callback: function(value) {
            return metricView === 'engagement_time' ? Math.round(value) : Math.round(value) + '%';
          }
        },
        title: {
          display: true,
          text: metricView === 'engagement_time' ? 'Engagement Time' : 'Engagement Rate',
          color: metricView === 'engagement_time' ? '#CDA2D3' : '#FFFFE0'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        min: 0,
        beginAtZero: true,
        grid: {
          drawOnChartArea: false
        },
        ticks: {
          color: 'lightblue',
          callback: function(value) {
            return Math.round(value);
          }
        },
        title: {
          display: true,
          text: 'Sessions',
          color: 'lightblue'
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: 'white',
          generateLabels: (chart) => {
            const labels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);

            if (isChangePointSignificant) {
              labels.push({
                text: 'Significant Change Point',
                fillStyle: '#ff6961',
                strokeStyle: '#ff6961',
                lineWidth: 1,
                hidden: false,
                index: chart.data.datasets.length,
                color: 'white'
              });
            }

            return labels;
          },
        },
        display: true,
        position: 'top',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (context.dataset.type === 'bar') {
              label += ': ' + Math.round(context.raw);
              if (metricView === 'engagement_rate') {
                label += '%';
              }
              if (showSmoothedData && context.dataset.label !== 'Sessions') {
                label += ' (smoothed)';
              }
            } else if (context.dataset.type === 'line') {
              label += ': ' + Math.round(context.raw) + ' sessions';
            }
            return label;
          },
          afterBody: function(tooltipItems) {
            const date = chartData.labels[tooltipItems[0].dataIndex];
            const tasksForDate = completedTasks.filter(task => 
              new Date(task.completed_date).toISOString().substring(0, 10) === date
            );

            if (tasksForDate.length > 0) {
              return tasksForDate.map(task => 
                `Task set live: ${task.recommendations.length > 150 ? 
                `${task.recommendations.substring(0, 150)}...` : task.recommendations}\nURL: ${task.url}`
              );
            }

            return ['No tasks completed on this date'];
          }
        }
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 60
      }
    },
  };

  const hasChartData = chartData.datasets.some(dataset => dataset.data.length > 0);

  return (
    <>
      {hasChartData && (
<div className="w-full mb-8 text-white">
  <div className="min-w-[1200px] h-[280px]">
    <h2 className="text-base mb-4 text-center">Engagement vs. Sessions - All Enhance Plan URLs</h2>
    <div className="mb-2 flex justify-center">
      <div>
        <select
          className="text-sm appearance-none px-3 py-1 rounded-md text-center shadow-sm placeholder-blue-500 focus:outline-none focus:ring-blue-200 focus:border-blue-200 text-white bg-blue-500 mr-4"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'calc(100% - 8px)',
            backgroundPositionY: 'center',
          }}
          id="metricType"
          value={metricType}
          onChange={(e) => setMetricType(e.target.value)}
        >
          <option value="total">All Traffic</option>
          <option value="internal">Internal Traffic</option>
          <option value="organic">Organic Search Traffic</option>
        </select>
      </div>
      <div>
        <select
          className="text-sm appearance-none px-7 py-1 rounded-md text-center shadow-sm placeholder-pink-500 focus:outline-none focus:ring-pink-200 focus:border-pink-200 text-white bg-pink-500 mr-4"
          style={{
			backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'calc(100% - 8px)',
            backgroundPositionY: 'center',
          }}
          id="metricView"
          value={metricView}
          onChange={(e) => setMetricView(e.target.value)}
        >
          <option value="engagement_time">Engagement Time</option>
          <option value="engagement_rate">Engagement Rate</option>
        </select>
      </div>
      <div className="flex items-center mr-4">
        <input
          type="checkbox"
          id="showSmoothedData"
          checked={showSmoothedData}
          onChange={(e) => setShowSmoothedData(e.target.checked)}
          className="mr-2"
        />
        <label htmlFor="showSmoothedData" className="text-white text-sm">Show smoothed data</label>
      </div>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        className="text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900 mr-4"
      />
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        className="text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900 mr-4"
      />
      <button onClick={handleDownloadCSV} className="bg-gray-700 text-white px-3 py-1 text-sm rounded-md hover:bg-gray-900">
        Export CSV
      </button>
    </div>
    <Bar data={chartData} options={options} />
  </div>
</div>
      )}
    </>
  );
};

export default CombinationChart;