import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import GoogleOAuthButtonManage from './GoogleOAuthButton2';
import symbol from './contentenhance-new-project.png';

const ProjectDetails = () => {
    const navigate = useNavigate();
    const { projectId } = useParams(); // Assuming you're using route parameters to fetch projectId
    const [projectSettings, setProjectSettings] = useState({});
	const [userRole, setUserRole] = useState('');
	
    const [showModal, setShowModal] = useState(false);
    const [ga4Account, setGa4Account] = useState('');
     const [ga4Property, setGa4Property] = useState('');
     const [gscProperty, setGscProperty] = useState('');
    const location = useLocation();
    const [ga4Accounts, setGa4Accounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [ga4Properties, setGa4Properties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState('');
    const [gscProperties, setGscProperties] = useState([]);
    const [selectedGscProperty, setSelectedGscProperty] = useState('');
	const [accountNameToIdMap, setAccountNameToIdMap] = useState({});
	const [projectName, setProjectName] = useState(''); 
	const [errorMessage, setErrorMessage] = useState('');
	const [originalProjectName, setOriginalProjectName] = useState(''); // State to hold the original name for comparison or reset
	const [validationMessage, setValidationMessage] = useState('');

	    useEffect(() => {
	        const fetchProjectDetails = async () => {
	            try {
	                const response = await axios.get(`https://api.contentenhance.com/api/project_details/${projectId}`);
	                if (response.data) {
	                    setProjectName(response.data.project_name);
	                    setOriginalProjectName(response.data.project_name); // Save the original name
	                }
	            } catch (error) {
	                console.error('Failed to fetch project details:', error);
	            }
	        };
	        fetchProjectDetails();
	    }, [projectId]);
	
    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
                if (!response.data.isAuthenticated) {
                    navigate('/login'); // Redirect to login if not authenticated
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                navigate('/login'); // Redirect to login on error
            }
        };

        checkAuthentication();
        fetchProjectSettings();
    }, [navigate, projectId]);
	
	useEffect(() => {
	    const fetchUserRoleForProject = async () => {
	        try {
	            const response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/check_role`, { withCredentials: true });
	            if (response.status === 200) {
	                setUserRole(response.data.role); // Handle successful response
	            } else {
	                console.error('Unexpected status code:', response.status);
	                // Handle other statuses explicitly if needed
	            }
	        } catch (error) {
	            if (axios.isAxiosError(error)) {
	                // Check if the error is due to a 401 Unauthorized response
	                if (error.response && error.response.status === 401) {
	                    navigate('/login'); // Redirect to login page
	                } else if (error.response && error.response.status === 403) {
	                    console.error('Error:', error.message);
						setErrorMessage('You do not have permission to access this resource.');
	                } else {
	                    console.error('Error fetching user role for project:', error.message);
	                }
	            } else {
	                // Handle non-Axios errors
	                console.error('An unexpected error occurred:', error);
	            }
	        }
	    };

	    fetchUserRoleForProject();
	}, [projectId, navigate]); // Depend on projectId and navigate
	
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('modal') === 'open') {
            setShowModal(true);
        }
    }, [location]);

	
	useEffect(() => {
	    // Fetches both GA4 accounts and GSC properties on component mount
	    fetchGA4Accounts();
	    fetchGscProperties();
	}, []);

    const fetchProjectSettings = async () => {
        try {
            const response = await axios.get(`https://api.contentenhance.com/api/project/settings/${projectId}`, { withCredentials: true });
            setProjectSettings(response.data);
        } catch (error) {
            console.error('Failed to fetch project settings:', error);
        }
    };
	
	
	const handleDeleteProject = async () => {
	    if (userRole !== 'admin') {
	        alert('You do not have permission to delete this project.');
	        return;
	    }
	    const isConfirmed = window.confirm("Are you sure you want to delete this project? This action cannot be undone.");

	    if (isConfirmed) {
	        try {
	            const response = await axios.delete(`https://api.contentenhance.com/delete_project/${projectId}`);
	            if (response.status === 200) {
	                // Redirect to a safe page after deletion, such as the projects list
	                window.location.href = "/projects";
	            } else {
	                throw new Error('Failed to delete the project.');
	            }
	        } catch (error) {
	            console.error('Error:', error);
	            alert('An error occurred while deleting the project.');
	        }
	    }
	};
	
	const handleDisconnect = async () => {
	    if (userRole !== 'admin') {
	        alert('You do not have permission to disconnect data sources.');
	        return;
	    }
	    const isConfirmed = window.confirm("Are you sure you want to disconnect data sources?");
	    if (isConfirmed) {
	        try {
	            const response = await axios.post(`https://api.contentenhance.com/api/disconnect_project/${projectId}`, {}, { withCredentials: true });
	            console.log(response.data.message);
	            setProjectSettings({}); // Optionally clear settings from the state
	            // Additional UI feedback can be added here
	        } catch (error) {
	            console.error('Failed to disconnect:', error);
	        }
	    }
	};
		const fetchGA4Accounts = async () => {
		    try {
		        // No need to get the access token from localStorage
		        const response = await axios.get('https://api.contentenhance.com/api/ga4/accounts', {
		            withCredentials: true // Ensure credentials (cookies) are sent with the request
		        });
		        console.log("GA4 Accounts Response:", response.data);

		        // Set the accounts directly from the response
		        setGa4Accounts(response.data || []);
		    } catch (error) {
		        console.error('Error fetching GA4 accounts:', error);
		    }
		};
	


		const fetchGscProperties = async () => {
		    try {
		        // No need to get the access token from localStorage
		        const response = await axios.get('https://api.contentenhance.com/api/gsc/properties', {
		            withCredentials: true // Ensure credentials (cookies) are sent with the request
		        });
		        setGscProperties(response.data.siteEntry || []);
		    } catch (error) {
		        console.error('Error fetching GSC properties:', error);
		    }
		};
		
		const handleGscPropertySelection = (gscProperty) => {
		    // Reset validation message
		    setValidationMessage('');

		    setSelectedGscProperty(gscProperty);
		};
	
	
	
		const handleAccountSelection = (accountName) => {
			setValidationMessage('');
		    // Find the account by the selected account name
		    const account = ga4Accounts.find(acc => acc.name === accountName);
		    setSelectedAccount(account);

		    // Set the properties of the selected account to the ga4Properties state
		    if (account && Array.isArray(account.properties)) {
		        setGa4Properties(account.properties);
		    } else {
		        setGa4Properties([]);
		    }
		};
	
		const handlePropertySelection = (propertyString) => {
			setValidationMessage('');
		    const selectedProp = ga4Properties.find(p => p.property === propertyString);
		    console.log("Selected GA4 property object:", selectedProp);
		    setSelectedProperty(selectedProp);
		};
	

		
		const fetchGA4Data = async (propertyId, projectId, siteUrl) => {
		    try {

		        const response = await axios.post('https://api.contentenhance.com/api/fetch_ga4_data', {
		            propertyId,
		            projectId,
		            siteUrl,
		        }, {
		            withCredentials: true // Ensure credentials (cookies) are sent with the request
		        });

		        // Handle the response as needed, such as updating state or UI
		    } catch (error) {
		        console.error('Error fetching GA4 data:', error);
		        // Handle the error, such as displaying an error message to the user
		    }
		};
		
		const handleConnectData = async () => {
			if (!validateSelections()) {
			        return;
			    }
		    // Ensure all required fields are selected before sending the request
		    if (!selectedProperty || !selectedAccount || !selectedGscProperty) {
		        setErrorMessage('All fields must be selected.');
		        return;
		    }

		    // Prepare the data to be sent
		    const updates = {
		        propertyID: selectedProperty.property.split('/')[1], // Assuming this is how you extract the ID
		        site_url: selectedGscProperty,
		        ga4_account: selectedAccount.name,
		        ga4_property: selectedProperty.displayName,
		        gsc_property: selectedGscProperty
		    };

		    // Make the API call to update the project settings
		    try {
		        const response = await axios.post(`https://api.contentenhance.com/api/update_project_settings/${projectId}`, updates, { withCredentials: true });
		        console.log(response.data.message); // Log the success message

		        // Fetch GA4 data using the updated settings
		        if (selectedProperty && selectedProperty.property) {
		            const propertyId = selectedProperty.property.split('/')[1];
		            await fetchGA4Data(propertyId, projectId, selectedGscProperty);
		        }

		        // Update the local state to reflect the changes
		        fetchProjectSettings(); // Optionally re-fetch project settings or update state directly
		        toggleModal(); // Close the modal after updating
		        navigate(`/projects/${projectId}`); // Optionally navigate to a confirmation or detail page
		    } catch (error) {
		        console.error('Error updating project details:', error);
		        setErrorMessage('Failed to update project details.'); // Display error message
		    }
		};
		
		const toggleModal = async () => {
			if (userRole !== 'admin') {
						alert('You do not have permission to connect data sources.');
						return;
					}
					            setErrorMessage('');
					            setShowModal(!showModal);
					    };
		const handleNameChange = (event) => {
			setProjectName(event.target.value);
				};
					    
				const handleSubmit = async () => {
				    if (userRole !== 'admin') {
				        alert('You do not have permission to change the project name.');
				        return;
				    }
				    if (projectName !== originalProjectName) {
				        try {
				            const response = await axios.post(`https://api.contentenhance.com/api/update_project/${projectId}`, {
				                project_name: projectName
				            });
				            console.log('Project name updated:', response.data);
				            alert('Project name has been changed.');
				            setOriginalProjectName(projectName); // Update the original project name
				        } catch (error) {
				            console.error('Error updating project name:', error);
				        }
				    }
				};
			
			const validateSelections = () => {
			    if (selectedGscProperty && (!selectedAccount || !selectedProperty)) {
			        setValidationMessage('Please select both a GA4 Account and Property when a GSC Property is selected - data extraction does not work properly without both.');
			        return false;
			    }
			    setValidationMessage('');
			    return true;
			};
		
	


    return (
        <div className="flex flex-col h-screen bg-darkBlue">
            <TopBar />
            <div className="flex h-screen bg-lighterDarkBlue text-white">
                <Sidebar />
                <div className="flex-1 bg-gray-850 p-10 overflow-auto">
		
                    <h1 className="text-white text-base mb-8">Project settings</h1>
		<div className="bg-gray-900 mb-8 p-4">
		<h2 className="text-white text-base mb-2">Rename project</h2>
<input
                            type="text"
                            value={projectName}
                            onChange={handleNameChange}
                            className="text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900 h-[35px]"
                        />
                        <button onClick={handleSubmit} className="p-2 rounded text-sm h-[35px] text-white bg-blue-500 hover:bg-blue-900 ml-2">
                            Save changes
                        </button>
		</div>
								
		<div className="bg-gray-900 mb-8 p-4">
		<h2 className="text-white text-base mb-2">Manage data sources</h2>
                    <p className="text-sm"><strong>GA4 Account:</strong> {projectSettings.ga4_account}</p>
                    <p className="text-sm"><strong>GA4 Property:</strong> {projectSettings.ga4_property}</p>
                    <p className="text-sm"><strong>GSC Property:</strong> {projectSettings.gsc_property}</p>
<button className="h-[35px] bg-red-600 text-white text-sm p-2 rounded mt-4 mr-2 hover:bg-red-700" onClick={handleDisconnect}>
                        Disconnect
                    </button>
	  <button onClick={toggleModal}
	    className="p-2 rounded text-sm h-[35px] text-white bg-blue-500 hover:bg-blue-900 mt-4"
	  >
	    Change data sources
	  </button>
		</div>
	  
		<div className="bg-gray-900 mb-8 p-4">
		<h2 className="text-white text-base mb-2">Delete project</h2>
                 <p className="text-white text-sm mb-2">Only delete this project if you are sure you will not require any of the data in future.</p>
                    <button className="mt-4 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded text-sm" onClick={handleDeleteProject}>
                        Delete
                    </button>
	  
	  </div>

		{showModal && (
		  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
		    <div className="w-full max-w-md p-4 space-y-2 rounded-lg bg-gray-900 shadow-2xl" style={{ maxWidth: '600px' }}>
		      <button onClick={toggleModal} className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800 text-3xl p-2">
		        <span aria-hidden="true">&times;</span>
		      </button>
		      <h2 className="text-base text-gray-200 text-center">Connect data sources</h2>
		      <div className="relative flex py-4 items-center">
		        <div className="flex-grow border-t border-gray-700"></div>
		      </div>

			<h3 className="text-base text-gray-200 mb-2">Step 1: Connect your Google account</h3>
		      <GoogleOAuthButtonManage projectId={projectId} />

		      <div className="relative flex py-4 items-center">
		        <div className="flex-grow border-t border-gray-700"></div>
		      </div>

			<h3 className="text-base text-gray-200 mb-2">Step 2: Connect data sources</h3>
		      {/* GA4 Account Selection */}
		      <select 
		        value={selectedAccount ? selectedAccount.name : ''}
		        onChange={(e) => handleAccountSelection(e.target.value)}
		        className="text-sm appearance-none w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 mb-4 bg-gray-700"
		      >
		        <option value="">Select a GA4 Account</option>
		        {ga4Accounts.map(account => (
		          <option key={account.id} value={account.name}>{account.name}</option>
		        ))}
		      </select>

		      {/* GA4 Property Selection */}
		      <select
		        value={selectedProperty ? selectedProperty.property : ''}
		        onChange={(e) => handlePropertySelection(e.target.value)}
		        className="text-sm appearance-none w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 mb-4 bg-gray-700"
		      >
		        <option value="">Select a GA4 Property</option>
		        {ga4Properties.map((prop, index) => (
		          <option key={index} value={prop.property}>{prop.displayName}</option>
		        ))}
		      </select>

		      {/* GSC Property Selection */}
		  <select 
		      value={selectedGscProperty} 
		      onChange={(e) => handleGscPropertySelection(e.target.value)}
		      className="text-sm appearance-none w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 mb-2 bg-gray-700"
		  >
		      <option value="">Select a GSC Property</option>
		      {gscProperties.map(property => (
		          <option key={property.siteUrl} value={property.siteUrl}>{property.siteUrl}</option>
		      ))}
		  </select>
		
				{/* Display validation message */}
			{validationMessage && <p className="text-sm text-red-200 text-center">{validationMessage}</p>}

		      <div className="relative flex py-4 items-center">
		        <div className="flex-grow border-t border-gray-700"></div>
		      </div>

		      <div className="mt-10">
		        <button onClick={handleConnectData} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:ring-4 focus:ring-blue-300">
		          Connect data sources
		        </button>
		      </div>
		    </div>
		  </div>
		)}
                </div>
            </div>
        </div>
    );
}

export default ProjectDetails;